@import 'styles/colors';
@import 'styles/_mixin';





.main-body{
    background: $main-color;
    position: relative;
    z-index: 400;
    min-height: calc( 100vh - 45px * 2 );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include desktop {
            min-height:calc(100vh - 55px)
    }
}

// .main-body-wrap{
//     -ms-overflow-style: none; /* for Internet Explorer, Edge */
//     scrollbar-width: none; /* for Firefox */
//     min-height: 100%;
//     min-width: 1200px;
//     background-color: $main-color;
// }
.main-body-wrap{
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 0;
    &:focus {
        outline: none
    }a{
    color: $accent-color;
    text-decoration: none;
    transition:opacity .3s;
    &:hover{
        opacity:.7
    }
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace,monospace;
    font-size: 1em
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,strong {
    font-weight: bolder
}

code,kbd,samp {
    font-family: monospace,monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}


fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none
}



hr {
    clear: both;
    width: 100%;
    margin: 40px 0;
    border: 0;
    border-bottom: solid 1px $border-color;

    @include tablet {
            margin:60px 0
    }
}
input[type=text],input[type=email],input[type=password] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none
}
@media print {
    a {
        color: inherit
    }
}
img {
    border-style: none
}
main {
    display: block
}a {
    background-color: rgba(0,0,0,0)
}
h1 {
    font-size: 2em;
    margin: .67em 0
}
button,input,optgroup,select,textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,input {
    overflow: visible
}

button,select {
    text-transform: none
}

button,[type=button],[type=reset],[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}
}
.main-content-wrap{
    width: 100%;
    background-color: $main-color;

    display: flex;
    flex-direction: column;
    align-items: center;
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    @media screen and (max-width: 900px) {
        left: unset; 
        transform: none;
    }
}



.main-investee-wrap{
    background-color:$main-color;
    overflow:auto;
    &:nth-child(even){
        background-color:$serve-color;
    }
    .main-investee{
        margin-right:20px;
        margin-left:20px;
        max-width:1600px;
        @include tablet{
            margin-right:40px;
            margin-left:40px
        }
        @include xl {
            margin-right:90px;
            margin-left:90px
        }
        @media(min-width: 1780px){
            
            width:100%;
            margin-right:auto;
            margin-left:auto
            
        }  
    }
    .header-box{
        font-size:20px;
        font-weight:800;
        line-height:1.05;
        @include tablet{
            font-size: 32px;
        }
    }
    .header-title{
        margin:20px 0;
        @include tablet{
            margin:28px 0;
        }
    }
    
}

.main-investee-flex-container-wrap{
	margin-top:40px;
    &:nth-last-child(2){
        padding-bottom:32px;
    }
    @include tablet{
        margin-top:48px;
        &:nth-last-child(2){
            padding-bottom:36px;
        }
    }
    @include xl{
        margin-top:60px
    }
    &:nth-of-type(1){
        margin-top:0
    }
    ul{
        font-size:inherit;
        font-family:inherit;
        margin:0;
        padding:0;
        border:0;
        line-height:inherit;
        list-style:none;
        box-sizing:border-box;
        overflow-x:scroll;
        overflow-y:hidden;
        -webkit-overflow-scrolling:touch;
        display: flex;
        flex-wrap:nowrap;
        margin:0 -20px;
        padding:0 20px;
        align-items:flex-start;
        justify-content:space-between;
        margin-top:0;
        @include tablet{
            margin:0 -40px;
            padding:0 40px;
            &>*:last-of-type{
                padding-right:40px
            }
        }
        @include desktop{
            overflow:visible;
            margin:0;
            padding:0;
            &>*:last-of-type{
                padding-right:0
            }
        }
        &>*{
            flex-grow: 1;
        }
        &>*:last-of-type{
            padding-right:20px
        }
    }
    li{
        width:80vw;
        max-width:285px;
        flex-grow:0;
        flex-shrink:0;
        margin-left:20px;
        @include tablet{
            margin-left:40px
        }
        @include desktop{
            max-width:none;
            width: 33.33%;
            flex-shrink:1;
            transition:opacity .3s;
            &:nth-of-type(3)~li{
                display:none
            }
        }
        @include xl{
            max-width: 30%;
            margin-left:60px
        }
        &:first-child{
            margin-left:0
        }
            &:hover{
                opacity:.7;
            }
        .img-wrap{
            max-width: 666px;
            position:relative;
            border-radius:8px;
            margin-bottom:12px;
            overflow:hidden
        }
        .issue-img{
            display:block;
            width:100%;
            border-style:none;
        }
        .issue-text {
            font-size: 11px;
            font-weight: 700;
            line-height: 1.05;
            text-transform: uppercase;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            @include tablet{
                font-size:13px;
            }
            div {
                color: $accent-color;
                @include desktop{
                    margin-bottom:8px;
                }
            }
        }
        .issue-title{
            color:$font-color-basic;
            margin:0;
            font-size:16px;
            font-weight:800;
            line-height:1.05;
            @include tablet{
                font-size:20px;
            }
        }
        .issue-dates{
            color:$font-color-type1;
            font-size:11px;
            font-weight:500;
            line-height:1.3;
            margin-top:4px;
            @include tablet{
                font-size:13px;
            }
            @include desktop{
                margin-top:8px;
            }
        }
        
    }
        // li:first-child:nth-last-child(1),
        // li:first-child:nth-last-child(2)~li{
        //     margin-right:550px;
        // }
}

.main-banner-nav{
    text-align: center;
    white-space: nowrap;
    position: relative;
    width: 100%;
    div{
        overflow: auto;
    }
    ul{
        font-size: inherit;
        font-family: inherit;
        margin: 0 !important;
        padding: 0;
        border: 0;
        line-height: inherit;
        list-style: none;
        display: inline-flex;
    }
    li{
        font-size:14px;
        font-weight:700;
        line-height:1.05;
        text-transform:uppercase;
        -webkit-hyphens:auto;
        -ms-hyphens:auto;
        hyphens:auto;
        margin:0 12px;
        padding:16px 0 14px;
        color:$font-color-type1;
        @include tablet{
            font-size:16px;
            &:first-child{
                margin-left:40px
            }
            &:last-child{
                margin-right:40px
            }
        }
        @include xl{
            &:first-child{
                margin-left:90px
            }
            &:last-child{
                margin-right:90px
            }
        }
        &:hover{
                opacity:0.7;
            }
        &:first-child{
            margin-left:0
        }
        &[aria-current=true]{
            border-bottom:2px solid $accent-color;
            color:$accent-color;
        }
        &[aria-current=true] a{
            color:$accent-color
        }
        & a{
            color:inherit;
            cursor:pointer;
        }
        &:first-child{margin-left:20px}
        &:last-child{margin-right:20px}
        }
}
.main-banner-nav::after{
    content:"";
    position:absolute;
    right:0;
    top:0;
    width:15%;
    height:100%;
    background:linear-gradient(to right, $transparent-color), $main-color;
    pointer-events:none
}

.tail-box{
    display:flex;
    align-items:center;
    justify-content:space-between;
    border-top:solid 1px $border-color;
    color:$font-color-type1;
    font-size:14px;
    font-weight:800;
    line-height:1.05;
    cursor: pointer;
    @include tablet{
        font-size:16px
    }
    p{
        margin:16px 0;
        @include xl{
            margin:20px 0
        }
    }

}

.main-investee-section-topic{
    margin:20px 0;
    display:block;
    @include tablet{
        margin:28px 0
    }
    .topic-header{
        align-items: stretch;
        display: flex;
        flex-direction: row;
        .topic-header-text{
            align-self: center;
            flex-grow: 1
        }
        .topic-header-icon{
            background: $icon-background-color;
            border-radius: 50%;
            overflow: hidden;
            width:50px;
            height:50px;
            img{
                display: block;
                height: auto;
                width: 100%;
            }
        }
        .font-style-topic-title{
            font-size: 16px;
            font-weight: 800;
            line-height: 1.05;
            color: $font-color-basic;
            margin: 0;
            @include tablet {
                font-size:20px
            }
        }
        .font-style-topic-desc{
            font-size: 11px;
            font-weight: 500;
            line-height: 1.3;
            @include tablet {
                font-size:13px
            }
        }
        .svg{
            align-self: center;
            flex-shrink: 0;
            margin-left: 16px;
            width:16px;
            height:24px;
            &>path{
                fill:$font-color-type1
            }
        }
    }
}
.main-body-above-topics {
    margin-right: 20px;
    margin-left: 20px;
    max-width: 1600px;
    margin-bottom: 52px;
    margin-top: 25px;
    @include tablet {
        margin-right:40px;
        margin-left: 40px;
        margin-bottom:68px;
        margin-top: 45px
    }
    @include xl {
            margin-right:90px;
            margin-left: 90px;
            margin-top:80px;
    }
    @media(min-width: 1780px) {
            width:100%;
            margin-right: auto;
            margin-left: auto
    }
}


    .contents-container {
        margin-right: 20px;
        margin-left: 20px;
        max-width: 1600px;
        @include tablet {
            margin-right:40px;
            margin-left: 40px
        }
        @include xl {
            margin-right:90px;
            margin-left: 90px
        }
        @media(min-width: 1780px) {
            width:100%;
            margin-right: auto;
            margin-left: auto
        }
        ul {
            font-size: inherit;
            font-family: inherit;
            margin: 0;
            padding: 0;
            border: 0;
            line-height: inherit;
            list-style: none;
            margin: 0 auto;
            li {
                transform: translate3d(0, 75px, 0);
                transition-delay: 0s;
                transition-duration: .3s;
                transition-property: opacity,transform;
                transition-timing-function: cubic-bezier(0.01, 0.11, 0.24, 1.13);
                margin: 20px 0;
                transform: none;
                @include tablet {
                    margin:48px 0
                }
                @include desktop {
                        margin:48px 0
                }
            }
        }
    }
    .below-contents-container{
        max-width: 170px;
        margin: 40px auto 60px;
        .btn-box {
            font-size: inherit;
            font-family: inherit;
            margin: 0;
            padding: 0;
            border: 0;
            line-height: inherit;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: rgba(0,0,0,0);
            background-image: none;
            text-align: center;
            color: inherit;
            cursor: pointer;
            position: relative;
            display: block;
            width: 100%;
            transition:opacity .3s;
            &:disabled{
                opacity: .5;
                cursor: default
            }
                &:hover {
                    opacity:.7
                }
                &:disabled{
                    transition:opacity .3s
                }
                &:disabled:hover{
                    opacity:.7
                }
            
            .btn {
                font-size: 16px;
                opacity: inherit;
                white-space: nowrap;
                line-height: 1;
                font-weight: 800;
                display: block;
                box-sizing: border-box;
                text-align: center;
                border-radius: 8px;
                padding: 14px;
                color: var(--color-background-2, #ffffff);
                background-color: var(--color-accent, #168dd9)
            }
        }
        // .intent-mouse .btn:focus,.intent-mouse .btn:active {
        //     outline: none
        // }
    }


.main-body-contents-box
 {
    display: flex;
    align-items: flex-start;
    @include tablet {
        align-items:center
    }
    .img-box {
        flex-shrink: 0;
        width: 80px;
        margin-right: 12px;
        border-radius: 8px;
        overflow: hidden;
        @include tablet {
            width:175px;
            margin-right: 24px
        }
        @include xl {
            width:220px;
            margin-right: 40px
        }
        img{
            display:block;
            width:100%;
            border-style:none;
        }
    }
    .desc-box {
        font-size: 11px;
        font-weight: 700;
        line-height: 1.05;
        text-transform: uppercase;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
        @include tablet {
            font-size:13px
        }
        .desc {
            color: $accent-color;
            margin-bottom: 4px;
            @include desktop {
                margin-bottom:8px
            }
        }
        
    }
    .title {
        font-size: 16px;
        font-weight: 800;
        line-height: 1.05;
        color: $font-color-basic;
        margin: 0;
        @include tablet {
            font-size:28px
        }
        @include desktop {
            font-size:48px
        }
    }
    .dates {
        color: $font-color-type1;
        font-size: 11px;
        font-weight: 500;
        line-height: 1.3;
        @include tablet {
            font-size:13px
        }
        div {
            margin-top: 4px;
            @include desktop {
                margin-top:8px
            }
        }
        
    }
    
    
    
    
}
