@import 'styles/colors';
@import 'styles/_mixin';




.detail-main-container{
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 0;

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace,monospace;
    font-size: 1em
}


abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,strong {
    font-weight: bolder
}

code,kbd,samp {
    font-family: monospace,monospace;
    font-size: 1em
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}


fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type=checkbox],[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none
}



input[type=text],input[type=email],input[type=password] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none
}
@media print {
    a {
        color: inherit
    }
}
a{
    color: $accent-color;
    text-decoration: none;
    transition:opacity .3s;
    &:hover{
        opacity:.7
    }
}
main {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}
a {
    background-color: rgba(0,0,0,0)
}
img {
    border-style: none
}

button,input,optgroup,select,textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,input {
    overflow: visible
}

button,select {
    text-transform: none
}

button,[type=button],[type=reset],[type=submit] {
    -webkit-appearance: button
}

button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring {
    outline: 1px dotted ButtonText
}
}

.detail-main {
    background: $main-color;
    position: relative;
    z-index: 400;
    min-height: calc( 100vh - 45px * 2 );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include desktop {
            min-height:calc(100vh - 55px)
    }
}

.detail-main-header{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 24px 0;
    @media print {
            text-align: center
    }
    @include tablet {
            max-width:none
    }
    @include desktop {
        padding:40px 0    
    }
    .header-textbox {
        margin-right: 20px;
        margin-left: 20px;
        max-width: 1150px;
        @include tablet {
            margin-right:40px;
            margin-left: 40px
        }
        @include xl {
            margin-right:90px;
            margin-left: 90px
        }
        @media(min-width: 1330px) {
                width:100%;
                margin-right: auto;
                margin-left: auto
        }
        .text-wrapper {
            font-size: 11px;
            font-weight: 700;
            line-height: 1.05;
            text-transform: uppercase;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
            @include tablet {
                    font-size:13px
            }
            .text{
                margin: 4px 0;
                color: $accent-color
            }
        }
        .h1-style {
            font-size: 24px;
            font-weight: 800;
            line-height: 1.05;
            color: $font-color-basic;
            margin-bottom: 0;
            margin-top: 10px;
            @include tablet {
                font-size:48px
            }
            @include desktop {
                            font-size:64px
                    }
        }
    }
    .header-img-container {
        margin-right: 20px;
        margin-left: 20px;
        max-width: 1600px;
        margin-top: 24px;
        margin-bottom: 0;
        max-width: 1200px;
        max-width: 1150px;
        @include tablet {
            margin-right:40px;
            margin-left: 40px
        }
        @include desktop {
                align-self:stretch;
                margin-top: 40px
        }
        @include xl {
                margin-right:90px;
                margin-left: 90px
        }
        @media(min-width: 1330px) {
            width:100%;
            margin-right: auto;
            margin-left: auto
        }
        @media(min-width: 1780px) {
            width:100%;
            margin-right: auto;
            margin-left: auto
        }
        .img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            transition: opacity .3s;
            opacity: 1;
            border-radius:2px
        }
        .img-comment{
            position: absolute;
            transform: rotate(-90deg) translate(100%);
            transform-origin: bottom right;
            font-size: 8px;
            text-transform: uppercase;
            color: $font-color-type1;
            right: -15px;
            bottom: 0;
            @include tablet {
                    font-size:10px
            }
        }
        
    }
    .img-empty{
        height: 300px;
        border: 2px dashed #e7e7e7;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
    }
}

.detail-main-body-container{
    margin-right: 20px;
    margin-left: 20px;
    max-width: 1600px;
    padding-bottom: 200px;
    @include tablet {
            margin-right:40px;
            margin-left: 40px
        }
    @include xl {
            margin-right:90px;
            margin-left: 90px
        }
    @media(min-width: 1780px) {
            width:100%;
            margin-right: auto;
            margin-left: auto
    }
    .detail-main-body-wrapper{
        @include tablet {
                position:relative;
                z-index: 100;
                max-width: 620px;
                margin: 0 auto
        }
        
        @include desktop {
                max-width:940px;
                margin-top: 0
        }
        
    }
}

.detail-main-body{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $font-color-basic;
    margin: auto;
    hr {
        clear: both;
        width: 100%;
        margin: 30px 0;
        border: 0;
        border-bottom: solid 1px $border-color;
        @include tablet {
            margin:30px 0
        }
    }
    @include tablet {
            font-size:20px
        }
    @include desktop {
            width:620px
        }
    @media print {
            width: auto
    }
    .main-body-header{
        margin-bottom: 16px;
        @include desktop {
                margin-bottom:24px
        }
        .text-box{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.3;
            @include tablet{
                    font-size:16px
            }
            .title{
                display:flex;
                align-items: center;
                .by{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.3;
                    overflow: hidden;
                    @include tablet {
                            font-size:16px
                    }
                    .span {
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 1.05;
                        @include tablet {
                                font-size:16px
                        }
                    }
                    
                }
                
            }
            .date{
                font-size: 11px;
                font-weight: 500;
                line-height: 1.3;
                color: $font-color-type1;
                margin-top: 4px;
                @include tablet {
                        font-size:13px
                }
                @include desktop {
                    margin-top:8px
            }
            }
            
            
            
        }
        
    }
    p{
        margin: 20px 0;
        line-height: 1.5;
        @include tablet {
                margin:28px 0
        }
    }
    h2{
        margin-top:60px;
        font-family:'MaisonNeue';
        font-weight: 600;
        line-height:1.05;
        margin-bottom:16px;
        @include tablet{
            font-size:32px;
        }
    }
}

.detail-main-bottom-container{
    margin-right: 20px;
    margin-left: 20px;
    max-width: 620px;
    margin-top: 28px;
    margin-bottom: 60px;
@include tablet {
        margin-right:40px;
        margin-left: 40px;
    .detail-main-bottom{
        display:flex;
        justify-content: space-between;
        
        
        
        
    }
}
@media(min-width: 700px) {
        margin-right:90px;
        margin-left: 90px
}
@media(min-width: 800px) {
        width:100%;
        margin-right: auto;
        margin-left: auto
}
@include xl {
        width:100%;
        margin-right: auto;
        margin-left: auto
}
}
.detail-bottom-copy-box{
    margin-left: 12px;
    min-width: 120px;
    flex-shrink: 0;
    .button {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0;
        border: 0;
        line-height: inherit;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: rgba(0,0,0,0);
        background-image: none;
        text-align: center;
        color: inherit;
        cursor: pointer;
        position: relative;
        transition:opacity .3s;
        display: block;
        width: 100%;
        &:active {
            outline: none
        }
        &:hover {
            opacity:.7
        }
        .span{
            font-size: 16px;
            opacity: inherit;
            white-space: nowrap;
            line-height: 1;
            font-weight: 800;
            display: block;
            box-sizing: border-box;
            text-align: center;
            border-radius: 8px;
            padding: 14px;
            color: $font-color-basic;
            background-color: $icon-background-color;
        }
    }
}
.icon-box{
            color: $font-color-basic;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            height: 45px;
            width: 45px;
            border-radius: 8px;
            background-color: $icon-background-color;
            padding: 0;
            margin: 0 6px;
        &>* {
            width: 30px;
            height: 30px;
            fill: $font-color-basic;
        }
        &:first-child {
            margin-left: 0
        }
        &:last-child {
            margin-right: 0
        }
    }
.link-box{
    margin-top: 12px;
    @include tablet {
            width:50%;
            margin-top: 0
    }
    .link-wrapper{
        flex-grow:1;
        position:relative;
        display:flex;
        width:100%;
    }
}
.detail-input-style{
    font-size: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: inherit;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 2;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    background: rgba(0,0,0,0);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    position: relative;
    display: inline-block;
    height: 45px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px $border-color-type1;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 300;
    color: $font-color-basic;
    text-indent: 15px;
    background-color: white;
    padding: 0;
    transition: border-color,.25s;
    &[type=search] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border-radius: 0;
        box-shadow: none
    }
    &:focus {
    outline: none
}
&::placeholder {
    color: $font-color-type1;
}&:hover,&:focus {
    border-color: var(--color-accent, #168dd9)
}
&[aria-invalid=true]{
    border-color: #c25250
}
}
.news-view-img-container {
    margin-right: 20px;
    margin-left: 20px;
    max-width: 1600px;
    margin-top: 24px;
    margin-bottom: 0;
    max-width: 1200px;
    max-width: 1150px;
    @include tablet {
        margin-right:40px;
        margin-left: 40px
}
    @include desktop {
        align-self:stretch;
        margin-top: 40px
    }
    @include xl {
            margin-right:90px;
            margin-left: 90px
    }
    @media(min-width: 1330px) {
        width:100%;
        margin-right: auto;
        margin-left: auto
    }
    @media(min-width: 1780px) {
        width:100%;
        margin-right: auto;
        margin-left: auto
    }
    .desc{
        margin-top: .5rem;
        color: #919191;
        padding-left: 0.5rem;
        font-size: 0.8rem;
        @media (min-width: 768px) {
            font-size: 1rem;
            margin-top: 1rem;

        }
    }
    .img{
        position: absolute;
        // width: 100%;
        height: 100%;
        // top: 0;
        // right: 0;
        bottom: 0;
        // left: 0;
        opacity: 0;
        transition: opacity .3s;
        opacity: 1;
        border-radius:2px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .img-comment{
        position: absolute;
        transform: rotate(-90deg) translate(100%);
        transform-origin: bottom right;
        font-size: 8px;
        text-transform: uppercase;
        color: $font-color-type1;
        right: -15px;
        bottom: 0;
        @include tablet {
                font-size:10px
        }
    }
    
}

















