@import 'styles/colors';
@import 'styles/_mixin';
.profile-change-root {
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 0;
    &:focus{
        outline:none
    }
    main {
        display: block
    }
    
    h1 {
        font-size: 2em;
        margin: .67em 0
    }
    
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        clear: both;
        width: 100%;
        margin: 40px 0;
        border: 0;
        border-bottom: solid 1px $border-color;
    @include tablet {
            margin:60px 0
    }
    }
    
    pre {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        text-decoration: underline dotted
    }
    
    b,strong {
        font-weight: bolder
    }
    
    code,kbd,samp {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    small {
        font-size: 80%
    }
    
    sub,sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline
    }
    
    sub {
        bottom: -0.25em
    }
    
    sup {
        top: -0.5em
    }
    
    img {
        border-style: none
    }
    
    button,input,optgroup,select,textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0
    }
    
    button,input {
        overflow: visible
    }
    
    button,select {
        text-transform: none
    }
    
    button,[type=button],[type=reset],[type=submit] {
        -webkit-appearance: button
    }
    
    button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner {
        border-style: none;
        padding: 0
    }
    
    button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring {
        outline: 1px dotted ButtonText
    }
    
    fieldset {
        padding: .35em .75em .625em
    }
    
    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal
    }
    
    progress {
        vertical-align: baseline
    }
    
    textarea {
        overflow: auto
    }
    
    [type=checkbox],[type=radio] {
        box-sizing: border-box;
        padding: 0
    }
    
    [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
        height: auto
    }
    
    [type=search] {
        -webkit-appearance: textfield;
        outline-offset: -2px
    }
    
    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }
    
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit
    }
    
    details {
        display: block
    }
    
    summary {
        display: list-item
    }
    
    template {
        display: none
    }
    
    [hidden] {
        display: none
    }
    
    a {
        color: $accent-color;
        text-decoration: none;
        background-color: rgba(0,0,0,0);
    @media print {
            color: inherit
    }
    @media(hover: hover) {
            transition:opacity .3s;
        &:hover {
            opacity:.7
        }
    }
    }
    input[type=text],input[type=email],input[type=password] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none
    }
    .profile-change-main-wrap{
        width: 100%;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        position:relative;
        .x-btn{
            font-size: inherit;
            font-family: inherit;
            margin: 0;
            padding: 0;
            border: 0;
            line-height: inherit;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: rgba(0,0,0,0);
            background-image: none;
            text-align: center;
            color: inherit;
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
            cursor: pointer;
            z-index: 100;
            margin-left: auto;
            @media(hover: hover) {
                    transition:opacity .3s;
                &:hover {
                    opacity:.7
                }
            }
            span {
                display: none
            }
            @include tablet{
                    padding:30px
            }
            .svg{
                --color: #000;
                width: 15px;
                height: 15px;
                @include tablet{
                        width:20px;
                        height: 20px
                }
            }
            
        }
        
        
        .profile-change-main {
            margin: 68px auto;
            padding: 0 20px;
        @include tablet {
                margin:100px auto
        }
                    .header{
                        width: 100%;
                        text-align: left;
                        margin: 0 auto 40px;
                        color: $font-color-basic;
                        max-width: 620px;
                        .h1{
                            margin: 0 0 12px;
                            max-width: 620px;
                            text-align: left;
                            font-size: 24px;
                            font-weight: 800;
                            line-height: 1.05;
                            @include tablet {
                                    font-size:40px
                            }
                        }
                        
                    }
                    .form{
                            margin: auto;
                            max-width: 620px;
                            width: 100%;
                            .desc{
                                font-size: 11px;
                                font-weight: 500;
                                line-height: 1.3;
                                color: $font-color-type1;
                                margin-top: 5px;
                                @include tablet {
                                        font-size:13px
                                }
                            }
                            
                            .div{
                                position:relative;
                                display:flex;
                                margin-top:24px;
                                .label{
                                    font-size: 11px;
                                    font-weight: 700;
                                    line-height: 1.05;
                                    text-transform: uppercase;
                                    -webkit-hyphens: auto;
                                    -ms-hyphens: auto;
                                    hyphens: auto;
                                    text-transform: uppercase;
                                    margin: 0 0 10px;
                                    color: $font-color-basic;
                                    display: block;
                                    margin-bottom: 10px;
                                @include tablet {
                                        font-size:13px
                                }
                                }
                                .sup{
                                    font-size: 14px;
                                    font-weight: 800;
                                    line-height: 1.05;
                                    color: $accent-color;
                                    padding: 7px 0 0 2px;
                                @include tablet {
                                        font-size:16px
                                }
                                }
                                
                            }
                            .input-container{
                                width:100%;
                                position:relative;
                                display:flex;
                                .input{
                                    font-size: inherit;
                                    font-family: inherit;
                                    margin: 0;
                                    padding: 0;
                                    border: 0;
                                    line-height: inherit;
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    line-height: 2;
                                    font-family: inherit;
                                    font-weight: inherit;
                                    color: inherit;
                                    background: rgba(0,0,0,0);
                                    border: 0;
                                    border-radius: 0;
                                    box-shadow: none;
                                    position: relative;
                                    display: inline-block;
                                    height: 45px;
                                    width: 100%;
                                    border-radius: 8px;
                                    border: solid 1px $border-color-type1;
                                    box-sizing: border-box;
                                    font-size: 16px;
                                    font-weight: 300;
                                    color: $font-color-basic;
                                    text-indent: 15px;
                                    background-color: #fff;
                                    padding: 0;
                                    transition: border-color,.25s;
                                    &[type=search] {
                                        appearance: none;
                                        -webkit-appearance: none;
                                        -moz-appearance: none;
                                        border-radius: 0;
                                        box-shadow: none
                                        }
                                    &:focus {
                                        outline: none
                                        }
                                    &::placeholder {
                                        color: $font-color-type1
                                        }
                                    &:hover,&:focus {
                                        border-color: $accent-color
                                        }
                                    &[aria-invalid=true] {
                                        border-color: #c25250
                                    }
                                }
                            }
                            .margin-btn-two{
                                margin:15px 0 20px;
                                
                            }
                            .margin-btn-one{
                                margin-top:24px;
                            }
                            .btn-box{
                                .btn{
                                    display:block;
                                    width:100%;
                                    font-size: inherit;
                                    font-family: inherit;
                                    margin: 0;
                                    padding: 0;
                                    border: 0;
                                    line-height: inherit;
                                    appearance: none;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    background-color: rgba(0,0,0,0);
                                    background-image: none;
                                    text-align: center;
                                    color: inherit;
                                    cursor: pointer;
                                    position: relative;
                                @media(hover: hover) {
                                        transition:opacity .3s;
                                    &:hover {
                                        opacity:.7
                                    }
                                }
                                &:disabled{
                                    opacity: .5;
                                    cursor: default;
                                }
                                @media(hover: hover) {
                                        &:disabled{transition:opacity .3s}
                                    &:disabled:hover,.lDNd5.pHwZX:hover {
                                        opacity:.7
                                    }
                                }
                                &:active {
                                    outline: none
                                }
                                .span{
                                    font-size: 16px;
                                    opacity: inherit;
                                    white-space: nowrap;
                                    line-height: 1;
                                    color:$accent-color
                                    
                                }
                                .span-btn{
                                    font-size: 16px;
                                    opacity: inherit;
                                    white-space: nowrap;
                                    line-height: 1;
                                    font-weight: 800;
                                    display: block;
                                    box-sizing: border-box;
                                    text-align: center;
                                    border-radius: 8px;
                                    padding: 14px;
                                }
                                .one{
                                    background-color: $accent-color;
                                    color: #fff;
                                }
                                .two{
                                    background-color: $border-color;
                                    color:#000
                                }
                                }
                            }
                            .p {
                                font-size: 11px;
                                font-weight: 500;
                                line-height: 1.3;
                                margin: 8px 0 0;
                                color: $font-color-type1;
                            @include tablet {
                                    font-size:13px
                            }
                            }
                            
                    }
                    .help-p{
                        font-size: 14px;
                        font-weight: 800;
                        line-height: 1.05;
                        margin: 14px 0;
                    @include tablet {
                            font-size:20px;
                            margin: 20px 0;
                    }
                    @include desktop {
                            text-align:center
                    }
                    }
                    
                    
                
            
        }
        
        
        
    }
}