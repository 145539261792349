@import 'styles/colors';
@import 'styles/_mixin';

.news-view-top-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .top-btn-box{
        width:  100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;
        @include tablet {
            margin-right:40px;
            margin-left: 40px
        }
        @include xl {
            margin-right:90px;
            margin-left: 90px
        }
        @media(min-width: 1330px) {
                width:100%;
                margin-right: auto;
                margin-left: auto
        }
    }
    .top-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        background-color: #fff;
        border: 1px solid #ced2db;
        color: #686868;
        transition: all .3s;
        font-size: .7rem;
        cursor: pointer;
        padding: 0.55rem 1.063rem;
        svg{
            font-size: .75rem;
            margin-right: 3px;
        }
        &:not(&:first-child){
            margin-left: 1px;
        }
        &:hover{
            opacity: 0.7;
        }
        &.edit{
            color: #fff;
            background-color: #5684ff;
        }
    }
    &.member{
        padding-top: 15px;
        .top-btn-box{
            max-width: 1150px;
        }
    }
    &.admin{
        padding-bottom: 15px;
    }
}
.news-view-status{
    padding: 12px 15px;
    background-color: #9b59b6;
    color: #fff;
    border-radius: 2rem;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 0px;
    &.admin{
        margin-left: 15px;

    }
    // margin-left: -20px;
}