@import 'styles/colors';
.company-find-wrap{
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    background-color: #f9f9f9;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    padding-top: 30px;
}
.company-find-body-wrap{
    width: 800px;
    background-color: #fff;
    border: 1px solid $border-color;
    
}
.company-find-content-container{
    width: 100%;
    text-align: center;
    padding: 30px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .company-find-title{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        color: rgb(51,51,51);
        margin-bottom: 30px;
    }
    .company-find-desc{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgb(51,51,51);
        text-align: left;
        margin-bottom: 30px;
    }
    .company-find-search-box{
        width: 80%;
    }
    .company-find-search{
        background-color: #fff;
        height: 40px !important;
        font-size: 14px !important;
    }
    .company-find-trend-add{
        color: #182FFF;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-top: 20px;
        cursor: pointer;
    }
    .company-find-recent-box{
        width: 80%;
        padding: 5px 0;
        border-bottom: 1px solid #AAAAAA;
        margin-bottom: 20px;
    }
    .company-find-recent-text{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgb(51,51,51);
        margin-left: 15px;
    }
    .company-find-recent-btn{
        width: 100px;
        height: 30px;
        border-radius: 10px;
        border: 1px solid $border-color-type1;
        font-weight: 600;
        font-size: 12px;
        color: rgb(51,51,51);
        cursor: pointer;

    }

}
.company-find-search-logo{
    display: flex;
    width: 25px !important;
    height: 25px !important;
    border-radius: 70%;
    overflow: hidden;
    margin: 5px 0px 5px 5px;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25), 0px -1px 1px rgba(0, 0, 0, 0.25);
    margin-right: 15px;
}    
.company-find-search-left-box{
    display: flex;
    align-items: center;
}
.company-find-search-btn{
    width: 70px;
    height: 20px;
    border-radius: 10px;
    background: white;
    font-weight: 400;
    font-size: 11px;
    color: #000000;
    cursor: pointer;
    border: 1px solid #cdced1;

}
.company-find-trendy-btn{
    width: 20%;
    height: 40px;
    border:1px solid $border-color-type1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(51,51,51);
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    margin-top: 20px;
    cursor: pointer;

}
.company-find-tooltip{
    width: 200px;
    height: 40px;
    background-color: #FFCBCB !important;
    color: black !important;
    font-size: 17px !important;
    margin-right: 200px;
    
}
.company-find-tooltip-arrow{
    color: #FFCBCB !important;

}
