@import 'styles/colors';
@import 'styles/_mixin';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


.mynews-main-wrap{
    min-height:100%;
    background-color: #f7f7f7;
    html {
        font-family: sans-serif;
        line-height: 1.15;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        box-sizing: border-box;
        font-size: 100%;
        &[type=button] {
            -webkit-appearance: button;
        }
    }

    body{
        margin:0;
        padding: 0;
        background: #fff;
        font-family: Helvetica Neue;
        font-weight: 400;
        line-height: 1.5;
        color: #0a0a0a;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }

button,input,optgroup,select {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button {
    overflow: visible
}

button,select {
    text-transform: none
}

[type=reset],[type=submit],button,html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring {
    outline: 1px dotted ButtonText
}
button {
    padding: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 0;
    background: transparent;
    line-height: 1
}

[data-whatinput=mouse] button {
    outline: 0
}
    a,a:link,a:visited,a[rel=menu],body,button,dl,input,ol,select,td,th,ul {
        color: #222
    }

a:visited {
    text-decoration: none
}

a:active,a:hover,a[rel=menu]:hover {
    text-decoration: underline;
}
@media print {
    * {
        -webkit-print-color-adjust: exact!important
    }

    a[href] {
        text-decoration: none
    }

    a[href]:after {
        content: none!important
    }

    .print-show {
        display: block!important
    }

    .print-hide {
        display: none!important
    }
}

font[style] {
    padding: 0!important;
    margin: 0!important;
    font: 0!important;
    color: inherit!important
}

.mynews-main{
    min-height:100vh;
    overflow: hidden;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    @media screen and(max-width:63.9375em){
        overflow-y:auto;
        max-width:100%;
    }
    &::before{
        display:table;
        content:'';
    }
    .mynews-body{
        display:flex;
        position:relative;
        min-height: calc(100vh - 72px);
        width:100%;
        transition: all .25s ease;
        @media screen and (max-width: 63.9375em){
            display:block;
        }
        
    }
    .leftaside{
        background-color: #4d5370;
        position: relative;
        z-index:1001;
        width:72px;
        min-height:calc(100vh - 114px);
        border-right:1px solid rgba(0,0,0,.08);
        transition:width .25s ease, transform .3s ease-in-out;
        display:block;
        @media screen and (max-width: 63.9375em){
            position: absolute;
            top:0;
            left:0;
            min-height:100%;
            transform: translatex(-100%);
            transition: transform .3s ease-in-out
        }
        .nav{
            padding:38px 0;
            margin-bottom:0;
            width:71px;
            display:block;
            ul{
                margin:0;
                list-style-type: none;
                color:#222;
                list-style-position: outside;
                line-height:1.6;
                padding:0;
                li{
                    font-size:0;
                    display:block;
                    outline:0;
                    vertical-align: middle;
                    a{
                        color:#fff;
                        
                        &:hover{
                            .svg{color: #ffca00;}
                            .text{background-color: #ffca00;
                                color:rgba(0,0,0,.95)}
                            }
                    
                        font-size:0;
                        padding: 0.75rem 0.625rem;
                        text-decoration: none;
                        display:block;
                        position:relative;
                        text-align:center;
                        line-height:1;
                        &::before{
                            content:'';
                            overflow:hidden;
                            position:absolute;
                            z-index:1;
                            left:0;
                            top:0;
                            bottom:0;
                            width:0;
                            border-left:3px solid transparent;
                        }
                        .svg{
                            margin-right:0;
                            font-size:1.25rem;
                            line-height:1;
                            display:inline-block;
                            vertical-align: middle;
                            width:21px;
                            height:20px;
                            &::before{
                                content:'\e813';
                                display:inline-block;
                                font:14px/1 fontello;
                                font-size:inherit;
                                line-height:1;
                                text-rendering:auto;
                                -webkit-font-smoothing:antialiased;
                            }
                            
                        }
                        .text{
                            margin-top:0.375rem;
                            display:inline-block;
                            font-family:Arial, Helvetica, sans-serif;
                            max-width:100%;
                            padding:0 2px;
                            font-size:0.688rem;
                            font-weight:400;
                            line-height:1.25;
                            text-align: center;
                            transition:color .25s ease-in-out, background-color .25s ease-in-out;
                            overflow:hidden;
                            letter-spacing:-.075em;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                        }
                    }
                    .active {
                            color:#ffca00 !important;
                            &::before{
                                border-left-color:#ffca00;
                            }
                    }
                }
            }
        }
    }
    .rightaside{
        width: 420px;
        padding: 50px 40px;
        background-color: #fff;
        border-left: 1px solid rgba(0,0,0,.15);
        transition: width .25s ease,padding .25s ease;
        display:block;
        @media screen and (max-width:99.9375em){
            width:400px;
        }
        @media screen and (max-width:89.9375em){
            display:none;
            width:300px;
            padding:25px;
        }
        .today-date{
            display: block;
            padding-bottom: 1rem;
            margin-bottom: 1.438rem;
            font-size: 0;
            border-bottom: 1px solid #eee;
            text-align: center;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 300;
            letter-spacing: -.025em;
            color: #838383;
            line-height: 1.125;
            @media screen and (max-width:63.9375em){
                padding-bottom:0;
                margin-bottom:1.375rem;
                border-bottom:0
            }
            .strong{
                font-weight:700;
                letter-spacing:-.05em;
                color:#212121;
            }
            .small{
                font-size:.625em;
                line-height:inherit;
            }
        }
        .current-num{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            margin-bottom: 2.875rem;
            @media screen and (max-width:63.9375em){
                margin-bottom:1.25rem;
                padding:1.25rem 0;
                background-color:#fff;
                border-radius:5px;
            }
            .content{
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor:pointer;
                .svg-wrap{
                    width:38px;
                    height:38px;
                    display:flex;
                    align-items: center;
                    border-radius: 50%;
                .svg{
                    display: block;
                    overflow: hidden;
                    width: 20px;
                    height: 20px;
                    margin: 0 auto;
                    font-size: 1rem;
                    line-height: 38px;
                    color: #fff;
                    border-radius: 50%;
                }}
                .apply{
                    background-color:#ffca00;
                }
                .re{
                    background-color:#f66874;
                }
                .ok{
                    background-color:#5684ff
                }
                .span-title{
                    display:block;
                    margin-top:0.375rem;
                    font-size:0;
                    .cnt{
                        margin-right: 0.25rem;
                        font-size: 1.25rem;
                        font-weight: bolder;
                        font-style: normal;
                        line-height: 26px;
                        letter-spacing: -.025em;
                        color: #212121;
                        display:inline-block;
                        vertical-align:middle;
                    }
                    .txt{
                        font-size: .813rem;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: -.075em;
                        color: #838383;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
        .dash-note{
            display:block;
            font-size:0;
            text-align:left;
            margin-bottom:2.5rem;
            .dash-note-header{
                text-decoration: none;
                overflow:hidden;
                margin-bottom:1.375rem;
                display:block;
                .header-title{
                    display: inline-block;
                    font-size: 1.125rem;
                    vertical-align: middle;
                    font-weight: bolder;
                    line-height: 1.125;
                    letter-spacing: -.125em;
                    @media screen and (max-width:39.9375em){
                        font-size:1rem;
                    }
                    @media screen and (max-width:63.9375em){
                        font-size:1.063rem;
                    }
                }
                .header-cnt{
                    width: auto;
                    min-width: 18px;
                    margin-left: 0.375rem;
                    padding: 0;
                    font-size: .625rem;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 18px;
                    letter-spacing: -.0125em;
                    vertical-align: middle;
                    color:#fff;
                    background:#ffc600;
                    border-radius:2rem;
                    display:inline-block;
                    text-align: center;
                }
                .svg{
                    float: right;
                    font-size: .875rem;
                    line-height: 20px;
                }
            }
            .content-box{
                background-color: #fafbfc;
                border-radius: 5px;
                overflow: hidden;
                padding: 25px;
                display:block;
                .latest-note{
                    display:block;
                    font-size:0;
                    text-align:left;
                    .note-href{
                        display: block;
                        padding-top: 0.875rem;
                        margin-top: 0.875rem;
                        border-top: 1px solid rgba(0,0,0,.05);
                        text-decoration: none;
                        &:first-child{
                            margin-top:0;
                            padding-top:0;
                            border-top:0;
                        }
                        .note-txt{
                            margin-bottom: 0.375rem;
                            font-size: .813rem;
                            line-height: 1.375;
                            letter-spacing: -.075em;
                            color: #838383;
                            transition: color .3s ease-in-out;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &:hover{
                                color: #212121;
                                text-decoration: underline;
                            }
                        }
                        .note-btm{
                            font-size: .75rem;
                            font-weight: 300;
                            font-style: normal;
                            line-height: 1.125;
                            letter-spacing: -.025em;
                            color: #b4b4b4;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
        
    }
    .write-right-aside{
        background-color: #fff;
        border-left: 1px solid rgba(0,0,0,.08);
        width:420px;
        @media screen and (max-width: 99.9375em){
            width: 400px;
        }
        @media screen and (max-width: 89.9375em) {
            width: 300px;
            display:block;
            -ms-transform: translate(0);
            transform: translate(0);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
        z-index: 1000;
        right: 0;
        bottom:0;
        top:72px;
        position:fixed;
        padding: 0;
        transition:transform .3s ease-in-out,-ms-transform .3s ease-in-out,width .25s ease,padding .25s ease;
        .aside-btn{
            display: none;
            position: absolute;
            z-index: 9;
            right: 100%;
            top: 50px;
            width: 28px;
            height: 28px;
            font-size: .875rem;
            color: #fff;
            background-color: #596689;
            border-radius: 3px 0 0 3px;
            text-align: center;
            cursor: pointer;
            opacity: .6;
            transition: opacity .25s ease;
            @media screen and (max-width: 89.9375em){
                display: block;
            }
            .svg1{
                margin-left:6px;
                margin-top:3px;
                width:15px;
                height:15px;
            }
            .svg2{
                margin-left:2px;
                margin-top:3px;
                width:15px;
                height:15px;
            }
        }
        .aside-btn-originver{
            display:block;
        }
        .aside-contents-container{
            height: calc(100% - 45px);
            @include desktop{
                height: 100%;
            }
            padding: 25px 22px 25px 40px;
            touch-action:pinch-zoom;
            position: relative;
            overflow-y:scroll;
            @media screen and (max-width: 89.9375em) {
                padding: 25px 7px 25px 25px;
            }
            &::-webkit-scrollbar{
                width:16px;
                background-color:#fff;
            }
            &::-webkit-scrollbar-thumb{
                background-color:rgba(0,0,0,.2);
                height:134px;
                border-radius: 16px;
                background-clip: padding-box;
                border: 6px solid transparent;
            }
            .contents-wrap{
                position: relative;
                overflow: visible;
                height: 100%;
                max-width: 100%;
                outline: 0;
                direction: ltr;
                max-height: none;
                .contents{
                    width: auto;
                    overflow: hidden;
                    height: auto;
                    position: relative;
                    top: 0px;
                    left: 0px;
                }
            }
        }
    }
    .origin{
        width:420px;
        -ms-transform: translate(420px);
        transform: translate(420px);

        @media screen and (max-width: 99.9375em){
            width: 400px;
            -ms-transform: translate(400px);
            transform: translate(400px);
        }
        @media screen and (max-width: 89.9375em) {
            width: 300px;
            display:block;
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
    }
.bar-active-origin{

    -ms-transform: translate(0);
    transform: translate(0);
        @media screen and (max-width:99.9375em){
            -ms-transform: translate(0);
            transform: translate(0);
        }
        @media screen and (max-width: 89.9375em){
            -ms-transform: translate(0);
            transform: translate(0);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(0);
            transform: translate(0);
        }
}
.bar-active{

    -ms-transform: translate(420px);
    transform: translate(420px);
        @media screen and (max-width:99.9375em){
            -ms-transform: translate(400px);
            transform: translate(400px);
        }
        @media screen and (max-width: 89.9375em){
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(0);
            transform: translate(0);
        }
}
.bar-in{
        padding: 1.25rem;
        background-color: #fff;
        border: 1px solid rgba(0,0,0, .12);
        border-bottom: 1px solid rgba(0,0,0, .25);
        border-radius:5px;
        .tools{
            display:block;
            margin-top: 1.375rem;
            padding-top: 1.375rem;
            border-top: 1px solid rgba(0,0,0,.08);
            &:first-child{
                margin-top:0;
                padding-top:0;
                border-top:0;
            }
            .str{
                display: block;
                overflow: hidden;
                margin-bottom: 1rem;
                font-size:1.125rem;
                font-weight: bolder;
                line-height: 1.375;
                letter-spacing: -.075em;
                text-overflow: ellipsis;
                white-space: nowrap;
                @media screen and (min-width:100em){
                    font-size: .9375rem;
                }
            }
            .column-align{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .kit{
                @media screen and (max-width: 63.9375em){
                    margin-bottom: 1,875rem
                }
                &::after{
                    content:'';
                    display:table;
                    clear:both
                }
                .icon-btn{
                    float: left;
                    margin-left: 2%;
                    margin-top: 2%;
                    padding: 0.375rem 0;
                    color: #222;
                    background-color: #fff;
                    text-align: center;
                    cursor: pointer;

                    &:hover{
                        .icon{
                        fill:#13c0cb}
                        &{
                            text-decoration-color: #13c0cb;
                        }
                        .btn-txt{
                            color:#13c0cb;
                        }
                    }
                    @media screen and (min-width:100em){
                        width: 32%;
                        &:nth-child(3n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+3){
                            margin-top:0
                        }
                    }
                    @media screen and (max-width: 89.9375em){
                        width: 23.5%;
                        &:nth-child(4n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+4){
                            margin-top:0;
                        }
                    }
                    @media screen and (min-width: 90em) and (max-width: 99.9375em){
                        width:18.4%;
                        &:nth-child(5n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+5){
                            margin-top:0;
                        }
                    }
                    .icon{
                        text-align:center;
                        line-height:inherit;
                    }
                    .btn-txt{
                        display: block;
                        overflow: hidden;
                        margin-top: 0.5rem;
                        font-size: .7rem;
                        line-height: 1.25em;
                        letter-spacing: -.08em;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        @media screen and (min-width:100em){
            .disabled{
            display:none;
            }
        }
    }
    .bar-in-right{
        padding: 0;
        background: 0 0;
        border: 0;
        border-radius: 0;
    }
    .drag-container{
        display:block;
        margin-top:1.625rem;

            @media screen and (min-width:100em){
                margin-top:0;
            }
        .drag-contents{
            margin-top:0.625rem;
        }
        .textbox-title{
            overflow: hidden;
            font-size: .9375rem;
            font-weight: bolder;
            line-height: 1.375;
            letter-spacing: -.075em;
            text-overflow: ellipsis;
            white-space: nowrap;
            display:block;
            margin-bottom:0.625rem;
        }
        .textbox-txt{
            overflow: hidden;
            margin-top:0.625rem;
            padding-bottom:1rem;
            border-bottom:1px solid rgba(0,0,0,.08);
            font-size: 1.125rem;
            line-height: 1.375;
            letter-spacing: -.1em;
            color: #222;
            text-overflow: ellipsis;
            white-space: nowrap;
            display:block;
        }
        .textbox{
            display:flex;
            width: 100%;
            min-height: 65px;
            align-items: center;
            justify-content: center;
            font-size: 1.125rem;
            margin-bottom: 1rem;
            line-height: 1.25;
            letter-spacing: -.05em;
            color: #222;
            border: 1px dashed rgba(0,0,0,.75);
            border-radius: 3px;
            opacity: .4;
            cursor: pointer;
            transition: opacity .3s ease-in-out,border .3s ease-in-out;
            &:hover{
                opacity:1
            }
        }
        .제목{
            font-size:1.688rem
        }
        .부제목{
            font-size:1.25rem
        }
    }
}

