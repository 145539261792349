.profile-edit-wrap{
    width: 100%;
    min-height:100%;
    padding-bottom: 500px;
    display: flex;
    justify-content: center;
    .MuiBox-root {
        padding: 0px !important;
    }
}
// .investee_register_root .form_box .input_form .description {
//     color: #7E7E7E !important;
//     width: 100% !important;
//     font-size: 12px !important;
//     font-weight: 500 !important;
//     margin-bottom: 7px !important;
// }
.kgTYFh{
    width:100% !important;
}
.jReruR {
    width:100% !important;
}
.css-ahj2mt-MuiTypography-root{margin:0;font-family:"Roboto","Helvetica","Arial",sans-serif;font-weight:400;font-size:1rem;line-height:1.5;letter-spacing:0.00938em;}
.profile-edit-container{
    width: 1490px;
    .container-title{
        font-size: 24px;
        font-weight: 600;
        color: #000;
        margin: 25px 0 25px 0;
    }
    .profile-edit-btn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 156px;
        height: 40px;
        background: #808080;
        border-radius: 100px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
    }
    .profile-edit-content-container{
        width: 650px;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .content-title{
        font-weight: 600;
        color: #444;
        font-size: 20px;
    }
    .content-subtitle{
        color: #444;
        font-size: 16px;
    }
    .item-box{
        width: 650px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .item-title{
            font-weight: 600;
            color: #444;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .item-text{
            color: #444;
            font-size: 14px;
        }
    }
    .check_boxes{
    }
    .check_boxes div {
        display: flex;
        flex-direction: row;
        text-align: left;
        flex-wrap: wrap;
        align-items: center;
        // justify-content: space-between;

        input {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
        label {
            width: 200px;
            margin-right: 23px;
        }
        span{
            font-size: 14px;

        }
    }
    .certification-box{
        width: 100%;
        margin-bottom: 10px;
        .category{
            font-size: 14px;
            font-weight: 600;
            color: #444;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            padding: 5px 10px;
            cursor: pointer;
            &.active{
                background-color: rgb(14, 81, 255);
                color: white;
                padding: 5px 10px;
                border-radius: 50px;
            }
        }
    }
    fieldset{
        border-radius: 0px !important;
        border:0px;
    }
    .text_input{
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        border-radius: 0px !important;
        background-color: white;
        padding-left: 10px;
        font-size: 12px;
        color: #444444;
    }
}
.profile-edit-password{

}