@import 'styles/colors';
@import 'styles/_mixin';
.navbar-btm{
  color:$font-color-basic;
  display:block;
  &::before{
    display:block;
    content:'';
    height: 45px;
    width: 100%;
  }
  @include desktop{
    display:none;
  }
  .nav-ul{
    font-size: inherit;
    font-family: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: inherit;
    list-style: none;
    align-items: center;
    background-color: $main-color;
    border-top: solid 1px $border-color;
    bottom: 0;
    display: flex;
    flex-wrap: nowrap;
    font-size: 10px;
    height: 45px;
    justify-content: space-around;
    padding-bottom: env(safe-area-inset-bottom, 0);
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 700;
    .nav-li{
      flex-basis: 100%;
      .nav-a {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0;
        border: 0;
        line-height: inherit;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: rgba(0,0,0,0);
        background-image: none;
        text-align: center;
        color: inherit;
        display: block;
        width: 100%;
        color: inherit;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255,255,255,0);
        @media(hover: hover) {
                transition:opacity .3s;
            &:hover {
                opacity:.7
            }
        }
        &[aria-current=true] {
            color: $accent-color
        }
        .svg-icon{
          margin: auto;
          width: auto;
          height: 19px;
          display: block;
          margin-bottom: 4px;
        }
    }
    }
  }
}
.rank-item-container {
  width: 30%;
  box-sizing: border-box;
  // box-shadow: 0px 2px 0px #E7E7E7;
  button {
    font-size: 12px;
    color: rgb(83,83,83)!important;
  }

  .css-9tj150-MuiButton-endIcon {
    margin-left: 0 !important;
    border-radius: 10px;
  }
}
// .MuiPaper-root{
//   border-radius: 10px !important;
// }
.input-box {
  width: 100%;
  font-size: 5px;
}
.MuiMenuItem-root {
  font-size: 12px !important;
  font-weight: 500 !important;
  // color: #4f4f4f38 !important;
  // justify-content: center !important;
}
// .Mui-selected {
//   background: linear-gradient(
//     93.12deg,
//     rgba(199, 199, 199, 0) -92.07%,
//     rgba(199, 199, 199, 0.15) 100%
//   ) !important;
//   border-radius: 5px !important;
//   margin: 0 5px !important;
//   color: #444444c4 !important;
//   justify-content: center;
// }
.rank-header-img {
  width: 25px;
  height: 100%;
  margin-right: 10px;
  image-rendering: -webkit-optimize-contrast;
}
.rank-header {
  width: 100%;
  background: #eeeeee;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 29px;
  color: #444444;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 19px;
  p {
    margin-top: 3px;
  }
}

.rank-item {
  padding: 8px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rank-body {
  margin: 10px 0;
}
.rank-left-box {
  display: flex;
  align-items: center;
  width: 90%;
}
.rank-number {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-right: 15px;
}
.rank-content {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  width: 90%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0px;
}
.rank-change {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
}
.nv-nav-link.active {
  color: #F18900 !important;
}
.investor-table {
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}
.investor-table-tr-even {
  background: rgba(231, 231, 231, 0.33) !important;
}
.investor-table-first-cell {
  padding-left: 24px;
  align-items: center;
}
.investor-table th:last-child {
  padding-right: 20px;
}
.investor-table tbody tr td:last-child {
  padding-right: 24px !important;
}

.investor-table-th {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
}
.investor-table-td {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #232323;
  height: 35px !important;
}
.info_content_guest {
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
}
.info_content_left_box {
  width: 76%;
  height: 100%;
}
.info_content_right_box {
  width: 25%;
  height: 100%;
}
.info_content_card {
  width: 100%;
  background: white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  // margin-bottom: 15px;
  // border-bottom: 1px solid #f8f8f8;
}

.info_wrap_guest_item {
  width: 1200px;
}

.info_content_left_card {
  @extend .info_content_card;
  padding: 50px 34px 30px 24px;
}

.info_table_box:last-child {
  margin-bottom: 0px !important;
}
.info_content_right_container {
  @extend .info_content_card;
  display: flex;
  flex-direction: column;
}
.info_content_right_card {
  @extend .info_content_card;
  padding: 15px 24px 0px 24px;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // justify-content: space-between;
}
.info_content_right_card:after {
  content: "";
  margin-top: 15px;
  border-bottom: 1px solid #f8f8f8;
}
.info_content_right_card:first-child {
  padding-top: 24px !important;
  // justify-content: space-between;
}

.info_content_title {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.info_content_title2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info_content_text2 {
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  font-size: 13px;
  color: #808080;
}

.overflow_hidden {
  width: 96%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.info_tbl_invest_header {
  background-color: rgba(243, 242, 240, 0.55);
  border-top-left-radius: 1px;
  border-bottom: 1px solid #adadb8 !important;
  position: sticky;
  top: 0;
}
.info_table_box {
  // max-height: 130px;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
}

.info_content_text3 {
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  font-size: 13px;
  color: #4f4f4f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0.7;
  text-align: right;
}
.info_content_text_box {
  width: 100%;
  height: 79%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.info_content_text_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 4px 0;
  .title {
    @extend .info_content_text2;
  }
  .content {
    @extend .info_content_text3;
  }
}

.nv-autocomplate {
  width: 100%;
  height: 100%;
}
.nv-autocomplate-label {
  font-size: 12px !important;
}
.nv-autocomplate div {
  padding: 0px !important;
  height: 100%;
  // height: 35px;
}
.nv-autocomplate div div {
  border-radius: 5px;
}
.nv-autocomplate div div fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}

.nv-logo {
  height: 35px;
  // margin-top: 11px;
  
  image-rendering: -webkit-optimize-contrast;
}
.nv-header {
  position: sticky;
  top: 0;
  z-index: 20000 !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 55px;
  line-height: 55px;
  padding: 0px;
  // box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
}
.nv-line{
  position:sticky;
  border-bottom:solid 1px $border-color;
  top:55px;
  z-index:500
  }
@media(min-width: 1024px){
  .nv-line{top:55px}
}
.nv-header-wrapper {
  width: 100%;
  height: 55px;
  display: flex;
  position:relative;
  justify-content: space-between;
  align-items: center;
}

.nv-header-wrapper ul {
  margin-right: 0px !important;
  height:100%
}
.nv-brand-name{
  text-decoration: none;
  color: black !important;
  font-weight: 500;
  position:relative;
  font-size: 23px;
  cursor: pointer;
  letter-spacing: 4px;
}
.main-nav-box .nv-brand-name {
  color: white;
  font-size: 23px;
}
.main-nav-box .nv-brand-name:hover {
  opacity:0.7
}
.nv-brand-name:hover {
  opacity:0.7;
}
.nv-nav-item-brand {
  list-style: none;
}
.nv-nav-item {
  list-style: none;
  display:flex;
  align-items: center;
  cursor: pointer;
  // line-height: 42px;
  @media screen and (max-width:1023px){
    display:none;
  }
}
.nv-nav-item-menu {
  list-style: none;
  display:flex;
  align-items: center;
  // line-height: 42px;
}
.news-nav{
  flex:0 0 72px;
  justify-content: center;
  .nv-nav-btn{
    padding:0;
    @media screen and (max-width: 1023px){
    display: unset;
    }
  }
  .nv-toggle{
    top:72px;
    max-height:calc(100vh);
  }
  .nv-after{
    top:0px;
    height:calc(100vh);
  }
}
.nv-nav-item-detail-box {
  width: 560px;
  position: absolute;
  top: 55px;
  background-color: white;
  border:1px solid $border-color;
  display: none;
  .nv-nav-item-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .nv-nav-item-detail {
      width: 50%;
      display: flex;
      flex-direction: column;
      color: $font-color-basic;
      padding: 10px 20px;
      a {
        font-weight: 800;
        font-size: 15px;
        line-height: 15px;
        margin: 8px 0;
      }
      p {
        font-size: 11px;
        line-height: 16px;
        margin: 8px 0;
        color:$font-color-type1;
      }
    }

    .nv-nav-item-detail:hover {
      a {
        opacity:0.7
      }
      p{
        opacity:0.7
      }
    }
  }
}
.nv-nav-header-center{
  position:absolute;
  transition:all .3s;
  width:400px;
  transform:tranSlateY(-100%);
  height:100%;
  display:flex;
  align-items:center;
  @include tablet{
    width:500px
  }
  @include xl{
    width:600px
  }
  @media screen and (min-width:1780px){
    width:800px
  }
}
.nv-nav-search{
  box-shadow:none !important;
  border-radius: unset !important;
  border:0;
  border-bottom:1px solid $border-color;
  height:45px !important;
  padding-top:10px !important;
}
.nv-nav-item:hover {
  .nv-nav-item-detail-box {
    display: flex;
    flex-direction: column;
  }
}
.pitchdeck-animator{
  display:flex;
  flex-direction: column;
  align-items: center;
  transition: transform .3s ease-in-out;
  width:600px;
}
.nav-item-pitchdeck{
  justify-content: center;
  flex-direction: row;
  width:200px;
  position:relative;
  display:flex;
  transition: all .3s ease-in-out;
  transform:translateY(0);
  &.hidden{
    transform:translateY(100%)
  }
  // animation: fadein 3s;
  // -moz-animation: fadein 1s; /* Firefox */
  // -webkit-animation: fadein 1s; /* Safari and Chrome */
.searchicon{
  cursor:pointer;
  position:absolute!important;
  top:7px;
  color:black !important;
  &:hover{opacity:0.7}
}
 }
  @keyframes fadein{
    0%{
      opacity:1
    }
    to{
      opacity:0
    }
  }

.nv-nav-btn{
  padding-right:20px;
  z-index:101;
  cursor: pointer;
  @media screen and (max-width:1023px){
    display:none;
  }
  &:hover{
    opacity:0.7;
  }
  .bar1, .bar2, .bar3 {
    width: 16px;
    height:2px;
    background-color:black;
    margin: 2px 0 3px 0;
    transition: all .4s;
  }
  .bar1-change{
    -webkit-transform: rotate(45deg) translate(3px,4px);
    transform: rotate(45deg) translate(3px, 4px);
    background-color:black;
  }
  .bar2-change{
    opacity: 0;
  }
  .bar3-change {
    -webkit-transform: rotate(-45deg) translate(3px,-4px);
    transform: rotate(-45deg) translate(3px,-4px);
    background-color:black;
  }
  
}
.combination-nv-nav-btn{
  @media screen and (max-width:1023px){
    display:unset;
  }
}
.nv-toggle{
  position: fixed;
  overflow: auto;
  width: 100vw;
  max-height: calc( 100vh - 99px );
  top: 55px;
  bottom: 45px;
  left:0;
  transform: translateX(-100%);
  z-index: 200;
  box-sizing: border-box;
  background-color: $main-color;
  -webkit-overflow-scrolling: touch;
  border-top: solid 1px $border-color;
  border-bottom: solid 1px $border-color;
  padding: 20px;
  text-align: left;
  visibility: hidden;
  @include tablet{
    bottom: auto;
    width: 440px;
    border-radius: 0px 8px 8px 0;
    transition: visibility 0s linear .3s,transform .3s ease-out;
  }
  @include desktop {
      max-height: calc(100vh - 55px);
      top: 55px;
      bottom: 0;
      padding: 35px;
      border-top: 0;
      margin-bottom: 0;
  }
  .toggle{
    margin-bottom: 36px;
    .p{
      font-size: 11px;
      font-weight: 700;
      line-height: 1.05;
      text-transform: uppercase;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      padding-bottom: 6px;
      color: $font-color-type1;
      border-bottom: solid 1px $border-color;
      margin-bottom: 16px;
      @include tablet{
        font-size:13px;
      }
    }
    .icon-list{
      font-size: inherit;
      font-family: inherit;
      margin: 0;
      padding: 0;
      border: 0;
      line-height: inherit;
      list-style: none;
      --color: #000;
      display: flex;
      justify-content: center;
      margin: 25px 0 0;
      .list{
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0;
        border: 0;
        line-height: inherit;
        max-width: 100px;
        text-align: center;
        flex: 1;
        border-right: solid 1px $border-color;
        &:last-child{
          border-right:0
        }
        a{
          display:block;
          .svg-style{
            margin: auto;
            width: 35px;
            height: 35px;
            background-size: 25px;
            background-repeat: no-repeat;
            fill:black;
          }
          .icon-name{
            font-size: 11px;
            font-weight: 500;
            line-height: 1.3;
            display: block;
            text-align: center;
            margin: 4px 0 0;
            color: $font-color-basic;
            @include tablet{
              font-size:13px
            }
        }
        }
    }
    }
    .ul{
      font-size: inherit;
      font-family: inherit;
      margin: 0;
      padding: 0;
      border: 0;
      line-height: inherit;
      list-style: none;
      .li{
        margin-bottom: 16px;
        &:hover{
          transition: opacity .3s;
        }
        .txt-box{
          align-items:stretch;
          display:flex;
          flex-direction: row;
          .txt-title-box{
            align-self:center;
            flex-grow:1;
            .txt-title{
              font-weight: 800;
              line-height: 1.05;
              color: #000;
              margin:0;
              font-size:14px;
              @include tablet{
                font-size:16px;
              }
            }
          }
          .svg{
            align-self:center;
            flex-shrink:0;
            margin-left:16px;
            width:32px;
            height:24px;
            fill: rgb(74,74,74)
          }
        }
      }
    }
  }
}
.nv-after{
  position: fixed;
  width: 100vw;
  height: calc(100vh - 100px);
  left: 0;
  top: 55px;
  z-index: 100;
  pointer-events: none;
  background-color: #000;
  opacity: 0;
  @include tablet {
    transition: opacity .25s;
  }
  @include desktop {
    height: 100vh;
    top:0
  }
}
.nv-toggle-changed{
  visibility:visible;
  transform: translateX(0);
  transition-delay:0s;
}
.nv-after-changed{
  @include tablet{
    opacity: 0.7;
    pointer-events:auto;
  }
}
.nv-nav-link {
  font-weight: 700;
  text-transform: uppercase;
  padding-right: 20px;
  transition:all .3s;
  font-size: 12px;
}
.nv-nav-link-right {
  text-decoration: none;
  color: white;
  padding-left: 20px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}
.nv-nav-link:hover {
  opacity:0.7;
}
.investor-table-tr-even {
  background: rgba(231, 231, 231, 0.33) !important;
}
.nv-search-input {
  width: 300px;
  height: 100%;
  border-radius: 5px;
  .MuiOutlinedInput-root {
    padding: 5px !important;
  }
  input {
    padding: 0px 0 0 11px !important;
    font-size: 12px !important;
  }
}
.nv-search-input .Mui-focused {
  -webkit-box-shadow: 0 0 3px 2px #8bb1ed;
  -moz-box-shadow: 0 0 3px 2px #8bb1ed;
  box-shadow: 0 0 3px 2px #8bb1ed;
  border: 0px !important;
}

.nv-textarea-autosize {
  width: 100%;
  height: 44px;
  font-size: 14px !important;
  border-radius: 5px !important;
  resize: none;
  padding: 12px !important;
  border-color: rgba(0, 0, 0, 0.23);
  // transition-duration: 0.3s;
  &:hover {
    border-color: #32b8ff;
  }
}
.nv-bar-avatar-paper {
  margin-top: 0px !important;
  padding: 0px 0;
  background-color: white !important;
  border:1px solid $border-color;
  color: $font-color-basic !important;
}
.nv-tooltip-button-tooltip {
  margin-top: 5px !important;
}
.main-footer{
  padding: 40px 0;
  width: 100%;
  overflow: hidden;
  &::before{
    content: '';
    display: block;
  }
  &::after{
    content: '';
    display: block;
    height: 100%;
    background: #efefef;
    opacity: .04;
  }
  .pitchdeck-name{
    font-size: 25px;
    letter-spacing: 4px;
    margin-bottom: 15px;
  }
  .become-member-btn{
    font-weight: 800;
    display: block;
    box-sizing: border-box;
    text-align: center;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    background-color: #168dd9;
    color: #fff;
    width: 175px;
    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }
  .main-footer-middle{
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
  }
  .main-footer-middle-list{
    width: 180px;
    padding: 0 20px 20px 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.15);
    p{
      color: rgba(0, 0, 0, 0.7);
      font-size: 13px;
      margin-bottom: 12px;
    }
    li{
      list-style: none;
      text-decoration: none;
      margin-bottom: 5px;
      a{
        color: #444;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .bottom-ul{
    display: flex;
    margin: 25px 0;
    li{
      list-style: none;
      text-decoration: none;
      a{
        color: #444;
        font-size: 12px;
        font-weight: 600;
      }
      &:not(:last-child){
        margin-right: 15px;
      }
    }
  }
  .main-footer-copyright{
    color: #444;
    font-size: 12px;
    a{
      color: #444;
      &:hover{
        opacity: 1
      }
      
    }
  }
}


.member-admin-topbar{
  background-color: #fff;
  display:flex;
  z-index:2000;
  height:72px;
  font-size:0;
  transition: all .25s ease;
  position: sticky;
  top: 0;
  &.border{
    &::before{
        content:'';
        overflow:hidden;
        position:absolute;
        z-index:1;
        left:0;
        bottom:0;
        width:100%;
        height:0;
        // border-top:1px solid rgba(0,0,0,.15)
    }
  }
  &::before{
      content:'';
      overflow:hidden;
      position:absolute;
      z-index:1;
      left:0;
      bottom:0;
      width:100%;
      height:0;
      // border-top:1px solid rgba(0,0,0,.08)
  }
  &::after{
      content:'';
      display:table;
      clear:both;
  }
  h1{
      background-color:#4d5370;
      flex: 0 0 72px;
      margin-bottom: 0;
      line-height: 1.4;
      margin-top:0;
      font-style:normal;
      font-weight:400;
      color:inherit;
      text-rendering:optimizeLegibility;
      font-size:1rem;
      @media print, screen and (min-width:40em) {
      
          font-size:1rem;
      }
      a{
          position:relative;
          height:72px;
          line-height:1;
          color:#222;
          background-color:rgba(0,0,0,.12);
          display: block;
          overflow:hidden;
          cursor:'pointer';
          &:visited{
              text-decoration: none;
          }
          div{
              position:absolute;
              z-index:10;
              left:50%;
              top:50%;
              max-width:70%;
              transform:translate(-50%,-50%);
              display:inline-block;
              vertical-align:middle;
              height:auto;
          }
      }
  
  }
  .newsroom{
      flex-grow:1;
      height:100%;
      padding: 0 50px 0 0;
      transition: width .25s ease;
      display:block;
      position:relative;
      overflow:hidden;
      &::after{
          content:'';
          display:table;
          clear:both;
      }
      @media screen and (max-width: 63.9375em){
          padding-left:0px;
          padding-right:20px
      }
      @media screen and (max-width:39.9375em) {
          padding:0
      }
      .nav-btn
      {
          float: left;
          max-width: 45px;
          position:absolute;
          height: 100%;
          margin-right: 1.125rem;
          text-align: left;
          outline-width: 0;
          border:0;
          background-color: #fff;
          cursor: pointer;
          &[aria-selected=true]{
              .svg{
                  &::before, &::after{
                      width:100%;
                  }
                  .show{
                      position: static!important;
                      width: auto;
                      height: auto;
                      overflow: visible;
                      clip: auto
                  }
              }
          }
          .svg{
              display: block;
              position: relative;
              width: 25px;
              height: 2px;
              background-color: rgba(0,0,0,.95);
              transition: width .3s ease-in-out;
              &::after, &::before{
                  content: '';
                  overflow: hidden;
                  position: absolute;
                  z-index: 1;
                  left: 0;
                  height: 0;
                  border-top-width: 2px;
                  border-top-style: solid;
                  border-top-color: rgba(0,0,0,.95);
                  transition: width .3s ease-in-out

              }
              &::before{
                  top:-8px;
                  width:90%;
              }
              &::after{
                  bottom:-8px;
                  width:72%;
              }
              .show{
                  position: absolute!important;
                  width: 1px;
                  height: 1px;
                  overflow: hidden;
                  clip: rect(0,0,0,0);
              }
          }
      }
      
      span{
          color:#4d5370;
          display:block;
          overflow:hidden;
          float:left;
          height:100%;
          position:absolute;
          left:36px;
          font-family: Arial, sans-serif;
          font-size:1.688rem;
          font-weight: bolder;
          line-height:72px;
          letter-spacing:-0.05em;
          @media screen and (max-width: 63.9375em){
              max-width:132px;
              text-overflow: ellipsis;
              white-space:nowrap;
          }
          @media screen and (max-width: 39.9375em){
              
          }
      }
      .search-box-wrap{
          border: 0;
          overflow:hidden;
          position:relative;
          height:100%;
          padding:15px 0 ;
          margin-right:15px;
          transition: all .25s ease;
          form{
              border-bottom: 1px solid #c6cbd3;;
              box-shadow:none;
              padding-left:10px;
              border-radius:unset !important;
              :nth-child(2){
                  margin-right:38px;
              }
          }
          .btn{
              fill: black;
              display:none;
              width:20px;
              height:20px;
              margin: 9px 8px;
              font-size:1.25rem;
              outline-width:0;
              border:0;
              line-height:inherit;
              font-style:italic;
              cursor:pointer;
              @media screen and(max-width: 39.9375em){
                  display:block;
              }
          }
          .form{
              @media screen and (max-width: 39.9375em){
                  background-color: rgba(255,255,255,.95);
                  border-bottom: 1px solid rgba(0,0,0,0.5);
                  display:none;
                  position: absolute;
                  left:0;
                  top:10px;
                  width:100%;
                  opacity:.95;
                  height:auto;
              }
          }
          .active{
              @media screen and (max-width: 39.9375em){
                  padding: 5px 0 0 10px;
                  box-shadow: none;
                  border-radius: unset;
                  height: 50px;
                  display: flex;
                  div{font-size:.75rem;}
              }
          }
      }
  }
  .user-section{
      background-color: #13c0cb ;
      display:flex;
      align-items:center;
      width:420px;
      padding: 0 40px;
      transition: width .25s ease;
      @media screen and (max-width: 99.9375em){
          width: 400px;
      }
      @media screen and(max-width: 89.9375em){
          width:300px;
          padding-left:20px;
          padding-right:20px;
      }
      @media screen and (max-width: 63.9375em){
          width: 165px;
      }
      @media screen and (max-width: 39.9375em){
          justify-content: flex-end;
          width:auto !important ;
          max-width: 50%;
          height: 100%;
          background: 0 0;
      }
      &::after{
          content:'';
          display:table;
          clear:both;
      }
      .main-btn{
          flex-grow:1;
          position:relative;
          text-align:center;
          @media screen and (max-width: 39.9375em){
              &:first-child{
                  margin-left:0;
              }
              flex-grow:inherit;
              margin-left:1rem;
          }
          .menu-btn{
              height: 36px;
              margin-top: 12px;
              position:relative;
              .btn{
                  overflow:hidden;
                  max-width:142px;
                  font-size:0;
                  background: 0 0;
                  border: 0;
                  cursor:pointer;
                  outline:0;
                  line-height:1;
                  text-transform:none;
                  span{
                      font-size: .813rem;
                      font-weight: 400;
                      letter-spacing: -.075em;
                      color: #fff;
                      display:inline-block;
                      vertical-align:middle;
                      @media screen and (max-width: 89.9375em){
                          display:none;
                      }
                  }
                  .img{
                      overflow:hidden;
                      width:24px;
                      height:24px;
                      margin-right:0.375rem;
                      background-repeat:no-repeat;
                      background-position:50%;
                      background-size:cover;
                      background-color:#fff;
                      border-radius:50%;
                      display:inline-block;
                      vertical-align:middle;
                      line-height:inherit;
                      font-style:italic;
                      @media screen and (max-width: 89.9375em){
                          width: 32px;
                          height: 32px;
                          margin: 0;
                      }
                      @media screen and (max-width: 63.9375em){
                          width: 28px;
                          height: 28px;
                      }
                  }
                  .dotlist{
                      display:inline-block;
                      margin-left:0.625rem;
                      font-size:1rem;
                      color:#fff;
                      vertical-align:middle;
                      line-height:inherit;
                      @media screen and (max-width: 63.9375em){
                          display:none;
                      }
                  }
              }
              .drop-menu{
                  position:absolute;
                  top:36px;
                  left:59.5469px;
                  max-width:135px;
                  padding:0.625rem;
                  background-color:#fff;
                  z-index:1500;
                  display:block;
                  width:300px;
                  border:1px solid #13c0cb;
                  border-radius:0;
                  font-size:1rem;
                  text-align:center;
                  @media screen and (max-width: 89.9375em){
                      left: auto !important;
                      right:0;
                      width:120px;
                  }
                  @media screen and (max-width: 39.9375em){
                      width: 135px !important;
                  }
                  a{
                      cursor: pointer;
                      position:relative;
                      display:block;
                      padding: 0 0.625rem;
                      font-size:.75rem;
                      line-height:30px;
                      letter-spacing:-.075em;
                      border-top:1px dotted rgba(0,0,0,.15);
                      color:rgba(0,0,0,.5);
                      text-align:left;
                      &:hover{
                          color:#353d47;
                          background-color:rgba(0,0,0,.05);
                          text-decoration: underline;
                      }
                      &:first-child{
                          border-top:none;
                      }
                      &:visited{
                          text-decoration:none;
                      }
                      .svg{
                          margin-right:0.375rem;
                          line-height:inherit;
                          display:inline-block;
                          font-size:inherit;
                          line-height:1;
                          text-rendering:auto;
                          -webkit-font-smoothing:antialiased;
                          
                      }
                  }
                  &::before {
                      content:'';
                      overflow:hidden;
                      position:absolute;
                      z-index:10;
                      right:18px;
                      bottom:100%;
                      border-width:3px;
                      border-style:solid;
                      border-color:transparent #fff #fff transparent;
                  }
              }
          }
          .a{
              position:relative;
              font-size: .75rem;
              line-height:1;
              letter-spacing:-0.075em;
              color:#fff;
              cursor:pointer;
              text-decoration:none;
                  display:flex;
                  align-items: center;
                  flex-direction: column;
              
              .svg{
                  display:block;
                  margin-bottom:0.375rem;
                  font-size:1.375em;
                  line-height:inherit;
                  font-style:italic;
                  @media screen and (max-width: 63.9375em){
                      margin-bottom:0;
                      font-size:1.563em;
                  }
              }
              .text{
                  display:block;
                  @media screen and (max-width: 89.9375em){
                      font-size: .9em
                  }
                  @media screen and (max-width: 63.9375em){
                      display:none;
                  }
              }
          }
          
      }
  }
  .topbar-pitchdeck-btn{
    width: 72px;
    height: 72px;
    background-color: #168dd9;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.news-write-sidebar{
  display:block;
  background-color: #fff;
  width:72px;
  left: -82px;
  top: 50px;
  position:absolute;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius:5px;
  transition: all .25s ease, -ms-transform .25s ease;
  @media screen and (max-width: 99.9375em){
      display:none;
      float:none;
      width:auto;
      margin: 0 auto;
  }
  .stickybar-wrap{
    height: 391.938px;
    padding:38px 0;
    position: relative;
    .stickybar{
      margin-top:0;
      bottom:auto;
      top:0;
      max-width:250px;
      width:100%;
      position: relative;
      right:auto;
      left:auto;
      z-index:0;
      transform: translateZ(0);
      .bar-in{
        background-color:unset;
        border:unset;
        padding:unset;
        .icon-btn{
            background-color: #fff;
            color:#444;
        }
        .str{
            text-align:center;
            white-space:unset;
            text-overflow:unset;
            overflow:unset;
            padding:10px;
            margin:0;
        }
      }
    }
  }
}

.custom-checkbox{
  -webkit-appearance: none;
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  outline: none !important;
  border: 1px solid #00000090;
  border-radius: 2px;
  margin-right: .8rem;
  cursor: pointer;
  &:checked{
    border-color: rgb(102, 149, 206);
    background-color: rgb(102, 149, 206);
    &::after{
      display: block;
      content: "";
      position: absolute;
      left: 4px;
      top: 1px;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }
  }
}