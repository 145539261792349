@import "./_variables.scss";

@mixin mobile{
    @media (max-width: $mobile){
      @content;
    }
}
  
/*반응형, 브라우저 크기가 768이상, 1023px 이하일때*/
@mixin tablet{
    @media (min-width: ($mobile + 1)){
        @content;
    }
}
  
/*반응형, 브라우저 크기가 1024px 이상일때*/
@mixin desktop{
    @media (min-width: $desktop){
        @content;
    }
}

@mixin xl{
    @media (min-width: $xl) {
        @content;
    }
}