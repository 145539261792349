@import './nSightColor.scss';

.company-register-button{
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    padding: 4px 10px;
    border: 2px solid #fff;
    border-radius: 7px;   
    font-weight: 600;
    margin-right: 15px;
    &:hover {
        color: var(--navbar_main-color);
        border-color: var(--navbar_main-color) !important;
    }
}
.company-register-modal{
    // width: 1000px;
    width: 960px;
    height: 560px;
    // background: linear-gradient(180deg, var(--main_logo-color) 40%, var(--white_gray-color) 40%);
    display: flex;
    overflow: hidden;
}
.company-register-wrapper{
    width: 2880px;
    transition: margin-left .5s;
    display: flex;
}
.company-register-container{
    // width: 1000px;
    width: 960px;

}
.company-register-header{
    width: 100%;
    height: 115px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    justify-content: space-between;
    background-color: #1B2B54;
    padding: 30px 30px;
    padding-bottom: 5px;
    &__top{
        display: flex;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 5px;
    }

}
.company-register-body{
    width: 100%;
    flex: 1;
    position: relative;
}
.company-register-footer{
    width: 100%;
    height: 8%;
    padding: 0 30px;
    position: relative;
    background-color: #EEF4FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.company-register-apply-btn, .company-register-prev-btn{
    min-width: 100px;
    padding: 0 7px;
    height: 35px;
    background-color: var(--blue_ribbon-color);
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    font-weight: 600;
    position: absolute;
    cursor: pointer;
    top: 15px;
    &:hover{
        opacity: .7;
    }
    &.disabled{
        background-color: #dddddd;
        &:hover{
            opacity: 1 !important;
        }
    }
}
.company-register-apply-btn{
    right: 30px;
}
.company-register-prev-btn{
    left: 30px;
}
.company-register-title-box{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.company-register-search-box{
    width: 50%;
    height: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
   
}
.company-register-filter-check{
    position: absolute;
    bottom: 4px;
    right: 0;
    color: rgba(255,255,255,.8);
    display: flex;
    align-items: center;
    .nv-checkinput{
        border-color: rgba(255,255,255,.6);

    }
}
.company-register-search-input{
    width: 100%;
    height: 30px;
    background-color: #fff;
    border-radius: 3px;
    display: flex;
    align-items: center;
    .search-input{
        flex: 1
    }
    .nv-textinput{
        height: 100%;
    }
    fieldset{
        border: none !important;
    }
    svg{
        fill: #95979A;
        width: 21px;
        margin-right: 8px;
        cursor: pointer;
    }
}
.company-register-filter-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;

    &>span{
        padding: 1px 4px 1px 10px;
        background-color:#fff;
        color: #444;
        font-size: 10px;
        font-weight: 400;
        border-radius: 16px;
        border: 1px solid #BDBDBD;
        display: flex;
        align-items: center;
        svg{
            font-size: 12px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.26);
            margin-left: 5px;
            &:hover{
                color: rgba(0, 0, 0, 0.46);
            }
        }
        &:not(:last-child){
            margin-right: 3px;
        }

    }
}
.company-register-check-box{
    display: flex;
    color: #444;
    font-size: 12px;
    align-items: flex-start;
    justify-content: center;
    font-weight: 500;
    padding-left: 13px;
    .nv-checkinput{
        width: 12px;
        height: 12px;
        margin-top: 1px;
    }
    .nv-check{
        margin-right: 10px !important;
    }
   

}
.company-register-table{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    .table .tr:nth-child(2n) .td{
        background-color: unset;
   }
    .table .thead .tr{
        background-color: unset !important;
    }
    .table{
        min-width: unset !important;
        width: 100% !important;
        display: flex !important;
        height: 400px;
        flex-direction: column;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
    }
    .thead{
        background-color: #EEF4FF !important;
    }
    .selected{
        .td{
            background-color: #f8fafe !important;
        }
    }
    .th{
        background-color: inherit !important;
        color: #646367 !important;
    }
    .th-divider{
        right: 0 !important;
    }
    .td{
        padding-right: 0px !important;
        padding-left: 4px !important;
        background-color: white !important;
        &.register-box{
            .MuiCheckbox-root{
                padding: 0 !important;
            }
            .Mui-checked{
                svg{
                    color: #fff;
                }
            }
            svg{
                font-size: 19px;
                color: #fff;
            }
        }
    }
    .company_name{
        padding-left: 0 !important;
    }
    #tbody, .tr{
        width: unset !important;
        min-width: unset;
        border-bottom: 1px solid #dddddd;
        justify-content: center;
    }
    .tr_animation{
        position: relative;
        opacity: 0;
        animation-name: fadeIn;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        
        @keyframes fadeIn {
            from {
              opacity: 0;
            }
            
            to {
              opacity: 1;
            }
        }
    }
    .register-btn{
        color: var(--main_logo-color);
        border: 1px solid var(--main_logo-color);
        border-radius: 3px;
        border-radius: 3px;
        font-size: 10px;
        margin-left: 8px;
        height: 20px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s;
        &:hover{
            background-color: var(--main_logo-color);
            color: #fff;

        }
        &.disabled{
            background-color: #fff;
            border-color: var(--main_logo-color);
            &:hover{
                opacity: 1;
                background-color: var(--main_logo-color);
                path{
                    fill: #fff; 
                }
            }
        }
    }
    overflow: overlay; 
    // &::-webkit-scrollbar{
    //     background-color: transparent !important;
    //     width: 10px;
    // }
    // &::-webkit-scrollbar-thumb{
    //         background-color: var(--gray-color) !important;
    //         border-radius: 10px;
    // }
    // &::-webkit-scrollbar-track {

    // }
}
.company-register-selected{
    position: fixed;
    width: 200px;
    height: 100%;
    right: -200px;
    top: 0px;
    background-color: #fff;
}
.company-regitser-selected-header{
    background-color:#fff;
    font-size: 14px;
    color: #444;
    font-weight: 600;
    padding: 30px 0 0 20px;
}
.company-regitser-selected-desc{
    color: #808285;
    font-size: 12px;
    font-weight: 400;
    margin-top: 10px;
    line-height: 20px;
}
.company-regitser-selected-thead{
    border-bottom: 1px solid #e7e7e7;
    font-size: 12px;
    color: #646367;
    font-weight: 400;
    margin: 0 20px;
    margin-top: 20px;
    padding-bottom: 10px;
    padding-left: 20px;

}
.company-regitser-selected-table{
    height: 450px;
}
.company-register-selected-body{
    overflow-y: scroll;
    padding: 0 20px;
    height: 386px;
}
.nv-checkinput{
  -webkit-appearance: none;
  position: relative;
  width: 14px;
  height: 14px;
  cursor: pointer;
  outline: none !important;
  border: 1px solid #00000090;
  border-radius: 2px;
  margin-right: 8px;
  cursor: pointer;
  &:checked{
      border-color: var(--main_logo-color);
      background-color: var(--main_logo-color);
      &::after{
          display: block;
          content: "";
          position: absolute;
          left: 4px;
          top: 1px;
          width: 3px;
          height: 7px;
          border: solid white;
          border-width: 0 1px 1px 0;
          transform: rotate(45deg);
      }
  }
  &.fix{
    background-color: gray;
    border-color: gray;
    opacity: .6;


  }
}
.company-register-status{
    list-style: none;
    font-weight: 500;
    font-size: 11px;
    color: #444;
    &::before{
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 14px;
        background-color: rgba(0,0,0,0.8);
        // border: 1px solid rgba(255,255,255,0.6);
    }
    &:not(:last-child){
        margin-right: 15px;
    }
    &.c-on, &[data-status = 정상]{
        &::before{

            background-color: #00FF55;
            border: 0;
        }
    }
    &.c-off, &[data-status = 폐업]{
        &::before{
            background-color: #FF0077;
            border: 0;

        }
    }
    &.c-close, &[data-status = 휴업]{
        &::before{
            background-color: #bbbbbb;
            border: 0;
        }
    }
    &.c-undnt, &[data-status = 알수없음]{
        &::before{
            background-color: #000000;
            border: 0;
        }
    }
}
.company-register-selected-item{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    .nv-company-item-logo{
        width: 25px !important;
        height: 25px !important;
        margin-right: 15px;
    }
    .selected-name{
        font-size: 12px;
        color: #646367;
    }
    .selected-remove{
        width: 15px;
        height: 15px;
        border-radius: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main_logo-color);
        margin-right: 7px;
        cursor: pointer;
        
        svg{
            color: #fff;
            font-size: 11px;
            font-weight: 600;
        }
    }
    
}
.react-table-wrap{
    .table {
      display: inline-block;
      border-spacing: 0;
      min-width: 100%;
      .table-scroller{
        display: flex;
        position: relative;
        &>div:last-child{
          flex:1
        }
      }
      .table-columns-header-fixed, .table-list-columns-fixed{
        position: sticky;
        z-index: 2;
        left: 0;
        display: flex;
        align-items: center;
        background-color: #fff;
      }
      #tbody{
          cursor: pointer;
          &:hover{
              &.tr{
                  // background-color: #dde1e8 !important;
                  // background-color: rgba(0,0,0,0.03);
                  // background-color: rgba(238, 241, 246, .7);
                  // background-color: rgb(248, 250, 254);
                  // background-color: #f2f5fa;
                  // background-color: rgb(248, 250, 254);
                  background-color: #f2f5fa;
              }
              .td{
                  // background-color: #dde1e8 !important;
                  background-color:transparent;
              }
          }
      }
      .th-divider{
        height: 50%;
        width: 1px;
        position: absolute;
        right: 3px;
        background-color: #e7e7e7;
        top: 50%;
        transform: translateY(-50%);
      }
      .th-first-divider{
        .th-divider{
            right: 3px;
            height: 100%;
        }
      }
      .th-full-divider{
        &::after{
          display: block;
          content: '';
          height: 100%;
          width: 1px;
          position: absolute;
          right: 3px;
          background-color: #e7e7e7;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .thead{
        background-color: white;
        border-bottom: 1px solid #e7e7e7
      }
      .company-register-status{
        font-size: 12px;
      }
      .tr {
        background-color: #fff;
        border-bottom: 1px solid #e6e6e6;
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
        &:nth-child(2n){
          // background-color: rgb(248, 250, 254);
          .td{
            // background-color: rgb(248, 250, 254);
          }
        }
      .th{
        font-size:12px;
        font-weight: 600;
        // color: #444;
        color: rgb(145, 152, 167);
      }
      .td{
        font-size: 12px;
        color: #444;
        font-weight: 500;
        padding: 3px;
        padding-right: 15px;
        &.last{
            padding-right: 5px;
            &:after{
                content: '';
                border-right: 1px solid #e7e7e7;
                padding: 0px;
            }
        }
  
      }
      .th,
      .td {
        margin: 0;
          display: -webkit-box !important; 
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1 ;
          overflow: hidden;
          font-size: 12px;
          word-break: break-all;
          overflow: hidden;
          line-height: 38px;
          padding-left: 4px;
          position: relative;
          background-color: white;
  
        :last-child {
          // border-right: 0;
        }
  
        .resizer {
          display: inline-block;
          border-right: 1px solid #e7e7e7;
          width: 5px;
          cursor: col-resize; 
          height: 70%;
          position: absolute;
          right: 0;
          top: 5px;
          transform: translateX(50%);
          z-index: 1;
          margin-right: 5px;
          &:hover{
              border-color: var(--main_logo-color);
          }
          touch-action:none;
          &.isResizing {
              border-color: var(--main_logo-color);
          }
        }
      }
      .th-br{
        height: 100%;
        width: 1px;
        background-color: #e7e7e7;
        position: absolute;
        right: 3px;
        top: 0;
      }
      .td-br{
        height: 100%;
        width: 1px;
        background-color: #e7e7e7;
        position: absolute;
        right: 3px;
        top: 0;
      }
      .icon-td{
          display:flex !important;
          align-items:center;
          justify-content: center;
      }
      .add-btn{
        padding: 3px 10px;
        background-color: var(--main_logo-color);
        color: white;
        font-size: 10px;
        font-weight: 600;
        &:hover{
            opacity: 0.7;
        }
      }
      }
      .checkbox{
        text-align: center;
        padding-right: 0px !important;
        .MuiCheckbox-root{
          padding: 0;
        }
        svg{
          font-size: 15px;
          vertical-align:middle; 
          color: #cacfdb;
        }
        .Mui-checked svg{
          color: #747a8c;
        }
      }
      .th.checkbox{
        svg{
          color: #ccdaff;
        }
        .Mui-checked svg{
          color: #5684ff;
        }
      }
    }
  }
.company-register-navigate-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s;
    border: 1px solid #e7e7e7;
    svg{
        width: 16px;
        height: auto;
    }
    &:hover{
        opacity: .7;
    }
}

.company-register-empty{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 15px;
}
.company-register-close-btn{
    position: absolute;
    right:0px;
    right: 15px;
    top: 15px;
    z-index: 100;
    cursor: pointer;
    &:hover{
        svg{
            color: #fff;
        }
    }
    svg{
        font-size: 20px;
        color: #dddddd
    }
}
.company-register-search-reset-btn{
    // padding: 5px 10px;
    position: absolute;
    right: -100px;
    top: 0;
    margin-right: 5px;
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 500;
    transition: all .3s;
    // position: absolute;
    // left: 2*10px;
    // bottom: 3px;
    // top: 50%;
    // transform: translateY(-50%);
    height: 35px;
    svg{
        transform: rotateY(180deg);
        font-size: 15px;
        margin-right: 7px;
    }
    &:hover{
        color: var(--main_logo-color)
    }
}
.company-register-list-cnt{
    // width: 880px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 3px;
    // margin-top: 3px;
    padding-left: 20px;
    min-height: 15px;
    display: flex;
}
.company-register-desc-box{
    width: 50%;
    height: 35px;
    display: flex;
    align-items: center;
    color: #fff;
}
.company-register-status-box{
    padding: 0px 35px;
    padding-right: 0;
    font-size: 12px;
    color: #444;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: absolute;
    // right: .5*10px;
    // bottom: 3px;
    // top: 50%;
    // transform: translateY(-50%);
    color: #222127 !important;
    border-radius: 3px;
}
.company-register-status{
    list-style: none;
    font-weight: 500;
    font-size: 11px;
    color: #444;
    &::before{
        content: '';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 14px;
        background-color: rgba(0,0,0,0.8);
        // border: 1px solid rgba(255,255,255,0.6);
    }
    &:not(:last-child){
        margin-right: 15px;
    }
    &.c-on, &[data-status = 정상]{
        &::before{

            background-color: #00FF55;
            border: 0;
        }
    }
    &.c-off, &[data-status = 폐업]{
        &::before{
            background-color: #FF0077;
            border: 0;

        }
    }
    &.c-close, &[data-status = 휴업]{
        &::before{
            background-color: #bbbbbb;
            border: 0;
        }
    }
    &.c-undnt, &[data-status = 알수없음]{
        &::before{
            background-color: #000000;
            border: 0;
        }
    }
}
