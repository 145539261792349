@import url(./font.css);
@import "./colors";
@import "./_mixin";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding-right: 0 ;
}.ReactModal__Body--open {
  overflow: hidden ;
  padding-right:15px;
}
// body.modal-open {
//   overflow: scroll !important;
// }
.mg{
  font-family:'Malgun Gothic' !important;
}
.mn{
  font-family: 'MaisonNeue';
}
.mn-extended{
  font-family:'MaisonNeueExtended'
}
.mn-mono{
  font-family:'MaisonNeueMono'
}
.pt{
  font-family:'PT Serif';
  font-style:normal;
}
.pt-i{
  font-family:'PT Serif';
  font-style: italic;
}
a {
  text-decoration: none;
  color: unset;
}

.d-md-flex {
  display: flex !important;
}

.nv-navitem {
  box-sizing: border-box;
  padding: 13px 16px;
  cursor: pointer;
}
.nv-navitem span {
  // display: flex;
  flex: 1 1;
  align-items: center;
  padding: 0.8445rem 1rem;
}
.nv-navitem svg {
  margin-left: 0px !important;
}

.nv-sidebar-brand {
  justify-content: flex-start;
  padding-left: 16px;
}

// .nv-header {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     justify-content: center;
//     height: 40px;
//     line-height: 40px;
//     padding: 0px;
//     background: white;
//     box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
// }
// .nv-header-wrapper {
//     width: 1440px;
//     display: flex;
//     justify-content: space-between;
// }

// .nv-header-wrapper ul {
//     margin-right: 0px!important;
// }
// .nv-brand-name {
//     text-decoration: none;
//     color: black;
//     padding-right: 30px;
//     font-weight: 900;
//     font-size: 18px;
//     letter-spacing: 0.5px;
// }
// .main-nav-box .nv-brand-name{
//   color: white;
//   font-size: 23px;
// }
// .main-nav-box .nv-brand-name:hover{
//   color: white
// }
// .nv-brand-name:hover {
//     color: black;
// }
// .nv-nav-item-brand {
//     list-style: none;
// }
// .nv-nav-item {
//     list-style: none;
//     // line-height: 42px;
// }

// .nv-nav-item-detail-box {
//   width: 500px;
//   position: absolute;
//   top: 40px;
//   background-color: white;
//   display: none;
//   z-index: 10000;
//   .nv-nav-item-wrap {
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     .nv-nav-item-detail {
//       width: 50%;
//       display: flex;
//       flex-direction: column;
//       color: black;
//       padding: 10px 20px;
//       a{
//         font-weight: 600;
//         font-size: 12px;
//         line-height: 15px;
//         margin: 8px 0;
//       }
//       p {
//         font-size: 12px;
//         line-height: 16px;
//         margin: 8px 0;
//       }
//     }

//     .nv-nav-item-detail:hover {
//       a {
//         color: #32B8FF;
//       }
//     }

//   }
// }

// .nv-nav-item:hover {
//   .nv-nav-item-detail-box {
//     display: flex;
//     flex-direction: column;
//   }
// }

// .nv-nav-link {
//     text-decoration: none;
//     color: rgb(51,51,51);
//     padding-right: 20px;
//     font-weight: 500 !important;
//     font-size: 12px;
// }
// .nv-nav-link-right {
//     text-decoration: none;
//     color: black;
//     padding-left: 20px;
//     font-weight: 500;
//     font-size: 12px;
// }
// .nv-nav-link:hover {
//     color: black;
//     font-weight: 600;
// }

.nv-company-header {
  position: sticky;
  top: 0;
  z-index: 1029;
  display: flex;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;
  height: 110px;
  // line-height: 40px;
  padding: 0px;
  // background: linear-gradient(180deg, #8198BD -50%, #32435D 100%);
  background: url("../assets/images/banner2.png");
  // background: linear-gradient(360deg, #C0B2A0 -67.72%, #EADDCD 151.18%);
}
.nv-company-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  height: 100%;
}
.nv-company-nav-item {
  list-style: none;
  margin: 0;
  padding: 5px 12px;
  cursor: pointer;
}
.nv-company-nav-link {
  text-decoration: none;
  font-weight: 600;
  color: #393936;
  font-size: 13px;
  opacity: 0.3;
}
.nv-company-nav-link.active {
  opacity: 1;
}
.nv-company-nav-item.active {
  border-bottom: 3px solid #402d15;
  // margin: 0px 3px;
}
.nv-company-nav-item:hover {
  background-color: #402d15;
  a {
    color: #fff !important;
  }
}

.nv-searchinput {
  background-color: white;
  .Mui-focused {
    -webkit-box-shadow: 0 0 5px 2px #0664ff;
    -moz-box-shadow: 0 0 5px 2px #0664ff;
    box-shadow: 0 0 5px 2px #0664ff;
  }
}

.nv-company-brand {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.nv-company-brand-logo {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 70%;
  overflow: hidden;
  // margin-right: 10px;
  // border: 0.8px solid #FFFFFF;
  box-sizing: border-box;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

.nv-company-brand-name {
  padding-left: 10px;
  display: flex;
  font-size: 21px;
  font-weight: 600;
  color: #ffffff;
}

.nv-company-brand-name-desc {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}
.nv-company-brand-name-desc span.name {
  font-size: 19px;
  font-weight: 600;
  color: #1a1919;
  // : 0px 1px 1px rgba(0, 0, 0, 0.50);
}
.nv-company-brand-name-desc span.desc {
  font-size: 13px;
  font-weight: 400;
  color: #1a1919;
}

.nv-company-toolbar {
  width: 32px;
  height: 32px;
  margin-left: 15px;
  cursor: pointer;
}
.nv-company-toolbar:hover {
  opacity: 70%;
}
.nv-company-nav-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  height: 30px;
}
.nv-company-nav-wrapper ul {
  height: 100%;
  margin: 0px;
}
.nv-company-nav-wrapper .nv-nav-link {
  // padding: 13px;
  padding: 13px 13px 6px 13px;
}
.nv-company-nav-wrapper a:hover {
  cursor: pointer;
}

.nv-company-item {
  cursor: pointer;
}
.nv-list-even {
  background-color: rgba(243, 242, 240, 0.55) !important;
}
.nv-company-item:hover {
  background-color: #dde1e8 !important;
}
.nv-company-item-logo {
  display: flex;
  width: 18px !important;
  height: 18px !important;
  border-radius: 70%;
  overflow: hidden;
  margin: 5px 0px 5px 5px;
  // margin-right: 10px;
  // border: 0.8px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25), 0px -1px 1px rgba(0, 0, 0, 0.25);
}

.nv-company-body {
  // margin-top: 15px;
  padding: 15px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  // height: 96px;
  // line-height: 40px;
  // padding: 0px;
  background: #f8f8f8;
}

.nv-section {
  margin-bottom: 15px;
}

.nv-grid {
  margin: 0px;
  // margin-bottom: 15px;
  display: flex;
  // width: 1440px;
  // border: 1px solid #bdbdc2;
  border-top: 1px solid #bdbdc2;
  border-left: 1px solid #bdbdc2;
  flex-direction: row;
}

.nv-col {
  // display: grid;
  display: flex;
  flex-direction: column;
}

.nv-row {
  display: flex;
  flex-direction: row;
}

.nv-cell {
  // border: 0.5px solid #bdbdc2;
  border-bottom: 1px solid #bdbdc2;
  border-right: 1px solid #bdbdc2;
  display: flex;
  height: 100%;
  align-items: center;
  // box-shadow: 0 0 0 0.5px #bdbdc2 inset;
}

.nv-tn {
  font-size: 12px;
  font-weight: 900;
  background-color: #faf3eb;
  background-color: inherit;
  // color: #444444;
}

.nv-th {
  font-size: 12px;
  font-weight: 900;
  // background-color: #adb1bf;
  // background: linear-gradient(180deg, #bdc2cf -57.29%, #a5a9b8);
  background-color: #fff;
  color: #000;
  // background: linear-gradient(179deg, #8198BD -50%, #32435D 100%);
  box-shadow: 0px 0.5px 0px #d4cec6;
}

.nv-tb {
  display: flex;
  flex-direction: column;
  background-color: inherit;
}
.nv-tb button {
  padding: 5px;
}

.nv-t1 {
  font-size: 16px;
  font-weight: 900;
  // background-color: #adb1bf;
  // background: linear-gradient(180deg, #bdc2cf -57.29%, #a5a9b8);
  background-color: #c7ccd7;
}

.nv-t1-5 {
  font-size: 14px;
  font-weight: 800;
  // background-color: #adb1bf;
}

.nv-t2 {
  font-size: 14px;
  font-weight: 800;
  // background-color: #c7ccd7;
  background-color: #dde1e8;
}

.nv-t3 {
  font-size: 13px;
  font-weight: 700;
  // background-color: #dde1e8;
  background-color: #eceff5;
}

.nv-t4 {
  font-size: 12px;
  font-weight: 600;
  background-color: #eceff566;
  // background-color: #eceff5;
}

.nv-t5 {
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  overflow: hidden;
}

.nv-t10 {
  font-size: 12px;
  font-weight: 500;
  background-color: #fff;
  color: #888;
  overflow: hidden;
}

// NvLabel
.nv-label {
  width: 100%;
  text-align: center;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// NvAutoComplete
.nv-autocomplate {
  width: 100%;
  height: 100%;
}
.nv-autocomplete-popper {
  z-index: 30000 !important;
}
.nv-autocomplate-label {
  font-size: 12px;
}
.nv-autocomplate div {
  padding: 0px !important;
  height: 100%;
  // height: 35px;
}
.nv-autocomplate div div {
  border-radius: 5px;
}
.nv-autocomplate div div fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}

.nv-numberinput {
  width: 100%;
  height: 100%;
  font-size: 12px !important;
  padding-right: 4px !important;
  border-radius: 5px;
}
.nv-numberinput input {
  padding: 2.5px 4px 2.5px 10px;
}
.nv-numberinput fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}
.nv-numberinput div p {
  font-size: 12px;
}

// NvTextInput
.nv-textinput {
  width: 100%;
  height: 100%;
  padding: 0px !important;
  font-size: 14px !important;
  border-radius: 5px;
}
.nv-textinput input {
  padding: 2.5px 4px 2.5px 10px;
}
.nv-textinput fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}

// NvTextArea
.nv-textarea {
  width: 100%;
  line-height:1.15;
  height: 100%;
  overflow: hidden;
  padding: 10px ;
  border-radius: 5px;
  border-width:1;
  // line-height: inherit;
}
.nv-textarea textarea {
  padding: 2.5px 4px 2.5px 10px;
}
.nv-textarea fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}

// NvTextEditor
.nv-texteditor {
  width: 100%;
  // padding: 1px;
}
.nv-texteditor .ql-toolbar {
  line-height: 23px !important;
  padding: 3px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: 0px !important;
  border-bottom: 1px solid #ccc !important;
}
.nv-texteditor .ql-container {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  height: unset;
  border: 0px !important;
}
.nv-texteditor .ql-container .ql-editor {
  padding: 8px;
  resize: none;
  overflow-y: auto;
}
.nv-texteditor.readonly .ql-container .ql-editor {
  resize: none;
}

.nv-texteditor .ql-container .ql-tooltip.ql-hidden {
  display: none;
}
.nv-texteditor .ql-container .ql-editor ul,
ol {
  padding-left: 16px !important;
}
.nv-texteditor .ql-container .ql-editor li {
  padding-left: 0px !important;
}
.nv-texteditor .ql-container .ql-editor li.ql-indent-1 {
  padding-left: 16px !important;
}
.nv-texteditor .ql-container .ql-editor li.ql-indent-2 {
  padding-left: 32px !important;
}
.nv-texteditor .ql-container .ql-editor li.ql-indent-3 {
  padding-left: 48px !important;
}
.nv-texteditor .ql-container .ql-editor li.ql-indent-4 {
  padding-left: 64px !important;
}
.nv-texteditor .ql-container .ql-editor li.ql-indent-5 {
  padding-left: 80px !important;
}
.nv-texteditor .ql-container .ql-editor p {
  padding-left: 0px !important;
}
.nv-texteditor .ql-container .ql-editor p.ql-indent-1 {
  padding-left: 16px !important;
}
.nv-texteditor .ql-container .ql-editor p.ql-indent-2 {
  padding-left: 32px !important;
}
.nv-texteditor .ql-container .ql-editor p.ql-indent-3 {
  padding-left: 48px !important;
}
.nv-texteditor .ql-container .ql-editor p.ql-indent-4 {
  padding-left: 64px !important;
}
.nv-texteditor .ql-container .ql-editor p.ql-indent-5 {
  padding-left: 80px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

// NvDatePicker
.nv-datepicker {
  width: 100%;
  height: 100%;
  // height: 25px;
  border-radius: 5px;
}
.nv-datepicker div {
  height: 100%;
  border-radius: 0px;
}
.nv-datepicker div input {
  font-size: 12px !important;
  padding: 2.5px 4px 2.5px 6px;
}
.nv-datepicker div fieldset {
  bottom: -1px;
  right: -1px;
  top: -6px;
  left: -1px;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1976d2 !important;
}

.nv-datepicker div div button {
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 2px;
}
.nv-datepicker div div button svg {
  font-size: 20px;
}

// NvCheckbox
.nv-checkbox {
  height: 100%;
  margin-left: -3px !important;
}
.nv-checkbox span {
  font-size: 13px;
}

.nv-button {
  background-color: #445773 !important;
  width: 100%;
  margin: 2px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 1px 10px !important;
  // box-shadow: 0px 1px 1px -2px rgb(0 0 0 / 20%), 0px 1px 2px 0px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 12%);
}
.nv-button-light {
  background-color: #eceff5 !important;
  color: black !important;
}
// NvBadge
.nv-badge {
  align-items: center;
  background-color: #f4f5f7;
  border: 0.5px solid #bdbdc2;
  // border-radius: 100px;
  display: inline-flex;
  flex-direction: row;
  float: left;
  justify-content: center;
  margin-top: 3px;
  margin-left: 3px;
  margin-bottom: 3px;
  margin-right: 0px;
  overflow: hidden;
  padding: 4px 8px;
  // width: -webkit-fit-content;
  // width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  line-height: 28px;
}

.nv-badge p {
  font-size: 10px;
  margin: 0px;
}

.nv-badge img {
  width: 17px;
  padding-left: 3px;
  cursor: pointer;
}

.nv-modal:focus-visible {
  outline: none;
}
.nv-modal .nv-checkbox {
  height: 35px;
}


////////// DRAGDROP
.DragDrop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download_buttons {
  padding-bottom: 12px;
  padding-top: 10px;
  width: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
}

.file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DragDrop-File {
  width: 100%;
  height: 140px;
  /* margin-top: 20px; */
  background-color: white;
  border: 2px dashed #bdbdc2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.12s ease-in;
}

.DragDrop-File-Dragging {
  width: 100%;
  height: 150px;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.12s ease-in;
  border: 2px dashed coral;
  background-color: #f4f5f7;
}
.file_type_col {
  display: flex;
  flex-direction: column;
}
.DragDrop_files {
  min-height: 300px;
  background-color: white;
  border: 1px solid #bdbdc2;
  border-collapse: collapse;
}

.DragDrop_files_filter {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.DragDrop_header_box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.DragDrop_header {
  border: 1px solid #bdbdc2;
  align-items: center;
  font-weight: 600;
  background: #dadfe8;
  padding: 8px 12px;
  font-size: 14px;
}

.calculator_result {
  border: 1px solid #bdbdc2;
  align-items: center;
  font-weight: 600;
  background: #dadfe8;
  padding: 8px 12px;
  margin: 8px 12px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file_elem {
  margin-top: 10px;
  width: 100%;
}
.upload-box {
  width: 100%;
  box-sizing: border-box;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-box .drag-file {
  position: relative;
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dashed #dbdbdb;
}
.file_contents {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  padding: 1%;
  display: flex;
  flex-direction: row;
}
.file-table {
  display: flex;
  flex-direction: column;
}
.file-label {
  background-color: #dadfe8;
  color: #444444;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1.5px solid #bdbdc2;
  align-items: center;
  height: 30px;
  width: 100%;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 10px;
}

.file {
  display: none;
}

.lock_img {
  width: 22px;
  height: 22px;
  margin-right: 3px;
  align-self: center;
  cursor: pointer;
}
.file-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-self: center;
  margin-bottom: 10px;
}

.upload-text {
  justify-self: start;
  color: #2e445f !important;
  font-weight: 700;
  font-size: 20px !important;
  align-self: center;
}

.del-icon {
  width: 20px;
  height: 20px;
}

.file_img {
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px;
}

.file_type {
  border: 1px solid #bdbdc2;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 13px;
  align-items: center;
  padding: 1px;
}
.file_type_long {
  border: 1px solid #bdbdc2;
  height: 60px;
  display: flex;
  font-size: 13px;
  flex-direction: row;
  align-items: center;
  padding: 1px;
}
.file_title {
  border: 1px solid #bdbdc2;
  font-size: 13px;
  height: 30px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 3px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DragDrop__header {
  width: 100%;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
///////////////DRAGDROP END

/////// LOGIN

.wrap {
  margin-top: -70px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: #f4f5f7;
}

.login-container {
  margin-top: 100px;
  width: 27%;
  height: 500px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #bdbdc2;
}

.login_id {
  margin-top: 20px;
  width: 80%;
}

label h4 {
  margin-left: 5px;
}

.input_box {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  margin-top: 5px;
  padding-left: 15px;
  border: 0.5px solid #bdbdc2;
  outline: none;
  font-size: 1.1em;
}
.input_box::placeholder {
  color: black;
}
.login_pw {
  margin-top: 20px;
  width: 80%;
}

.login_etc {
  margin: 10px;
  width: 80%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.checkbox {
  margin-left: 5px;
}

.checkbox label {
  margin-right: 5px;
}

.forgot_pw {
  margin-right: 2px;
}

.submit {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 80%;
}
.submit button {
  cursor: pointer;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  border: 0;
  outline: none;
  border-radius: 10px;
  background: linear-gradient(to left, #384b60, #4f7296);
  color: white;
  font-size: 1.2em;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;
  font-size: 1.3em;
}

.forgot_pw {
  cursor: pointer;
}
////////// LOGIN END

.market-calculator-title {
  font-size: 18px;
  // background: linear-gradient(97.93deg, #B1A28F 50%, rgba(147, 114, 74, 0.33) 291.21%) !important;
  background-color: #2e445f !important;
  color: white;
}
.market-calculator-sub-title {
  background-color: #dadfe8 !important;
  color: black;
  font-size: 13px;
}
.market-calculator-button {
  background-color: #2e445f !important;
}
.brief-text-box {
  background-color: #ffffff;
  margin-left: 0 !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  height: 100%;
  display: flex;
  align-items: center;
  margin-top: 1px;
}
.brief-text-box-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.brief-text-box-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  justify-content: flex-end;
}
.table-triangle-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 5px solid #c4c4c4;
}
.table-triangle-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 5px solid #c4c4c4;
}
.table-triangle-margin {
  margin-left: 5px;
}
.drag-strong {
  cursor: move;
}
.modal-list-row span {
  font-size: 12px !important;
  color: #444444;
}
.modal-file-container {
  height: 100%;
  max-height: 438px;
  .nv-t5 {
    // overflow: unset !important;
  }
}
.modal-file-content-container {
  overflow-y: scroll;
}
.modal-file-cancelIcon {
  background-color: white;
}
.main-banner-wrap-topic{
  align-items: center;
  display: flex;
  flex-direction: column;
  position:relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px $border-color;
  @include tablet{
    padding-bottom:48px;
  }
  .main-banner {
    padding: 0 25px;
    text-align: center;
    max-width: 620px;
    z-index:100;
    h1 {
      font-size: 28px;
      font-weight: 800;
      line-height: 1.05;
      margin: 0;
      @include tablet {
        font-size: 64px;
      }
      @include desktop {
        font-size: 80px;
      }
    }
  }
}


.main-banner-wrap {
  border-bottom: solid 1px $border-color;
  margin-top: 20px;
  @include desktop {
        margin-top:48px
  }
  p{
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      color: $font-color-type1;
      margin: 16px auto;
      max-width: 500px;
      @include tablet {
        font-size:20px;
        margin:24px auto;
        max-width: 600px
      }
      @include desktop {
        max-width:760px
      }
      .blind-span{
        display:flex;
        justify-content:center;
        margin-top:10px
      }
      .blind-span-lg{
        color:$accent-color;
        transition:all .3s ease-in-out;
        font-size:15px;
        display:flex;
        align-items:center;
        width:100%;
        justify-content:center;
        font-weight:500;
        &:hover{
          color:#f9f9f9;
          background-color:$accent-color;
        }
      }
      .blind-span-sm{
        transition:'all .3s ease-in-out';
        z-index:10;
        width:50%;
        transition:all .3s ease-in-out;
        font-size:15px;
        display:flex;
        align-items:center;
        width:100%;
        justify-content:center;
        font-weight:500
      }
      .my-blind-slider-sm{
        display:none;
        width:180px;
        overflow:hidden;
        position:relative;
        height:38px;
        border:1px solid $accent-color;
        border-radius:8px;
        @include tablet{
          display:flex;
        }
      }
      .my-blind-slider-lg{
        display:flex;
        width:90px;
        overflow:hidden;
        position:relative;
        height:38px;
        border:1px solid $accent-color;
        border-radius:8px;
        @include tablet{
          display:none;
        }
      }
        .span-btn{
            font-size: 16px;
            opacity: inherit;
            white-space: nowrap;
            line-height: 1;
            font-weight: 800;
            display: block;
            box-sizing: border-box;
            text-align: center;
            border-radius: 8px;
            padding: 14px;
            color: #fff;
            background-color: $accent-color;
        }
        .ver2{
          background-color:#f9f9f9;
          border: 1px solid $accent-color;
          color:$accent-color;
          padding: 12px 7px 9px 7px;
        }
      .btn{
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 0;
        border: 0;
        line-height: inherit;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: rgba(0,0,0,0);
        background-image: none;
        text-align: center;
        color: inherit;
        cursor: pointer;
        position: relative;
        @media(hover: hover) {
                transition:opacity .3s;
            &:hover {
                opacity:.7
            }
        }
        &:disabled{
            opacity: .5;
            cursor: default;
        }
        @media(hover: hover) {
                &:disabled{transition:opacity .3s}
            &:disabled:hover,.lDNd5.pHwZX:hover {
                opacity:.7
            }
        }
        &:active {
            outline: none
        }
        .span{
            font-size: 16px;
            opacity: inherit;
            white-space: nowrap;
            line-height: 1;
            color:$accent-color
            
        }
      }
  }
  .main-banner {
    text-align: center;
    padding:0 20px;
    @include tablet{
      padding: 0 40px;
    }
    @include desktop{
      padding: 0 90px;
    }
    h1 {
      font-size: 28px;
      font-weight: 800;
      line-height: 1.05;
      margin: 0;
      text-transform: uppercase;
      @include tablet {
        font-size: 64px;
      }
      @include desktop {
        font-size: 80px;
      }
    }
    .h1front{
      backface-visibility: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: transform .3s ease-in-out;
    }
    .pback{
      font-size:12px;
      @include tablet{
        font-size:15px
      }

    }
  }
  .blind-banner{
    position:relative;
    /* margin: 0 auto; */
    position: relative;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spanStyle {
    color: black;
    width: 820px;
    font-size: 20px;
    display: flex;
    align-items: center;
    margin-right: 140px;
  }
  .main-banner-category {
    margin-top: 24px;
    width: 100%;
    overflow-x:auto;
  }
  .mbc-blind{
    max-width:1140px;
    margin-left:auto;
    margin-right:auto;
    @include desktop{
      padding: 0 20px;
    }
  }
}
.blind-border-top{
  border-top:1px solid $border-color;
  @include desktop{
    border-top:0;
  }
}

.table-scroll-bar {
}
.table-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}
.table-scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0 !important;
  border-radius: 10px;
  background-clip: padding-box;
}
.table-scrollbar::-webkit-scrollbar-track {
  background-color: white !important;
  border-radius: 10px;
}

.nv-file-upload-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-color: red;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
}
.nv-delete-confirm-conteinr {
}
.nv-delete-confirm-icon {
  margin-top: 20px !important;
}
.nv-delete-confirm-title {
  font-size: 20px !important;
}
.info_tbl {
  width: 100%;
  text-align: center;
  display: block;
  border-collapse: collapse;
  table-layout: fixed;
  overflow: hidden;
  // border-top-right-radius: 10px;
  //   border-top-left-radius: 10px;
  thead {
    box-shadow: 2px 2px 2px rgba(173, 173, 173, 0.25);
    tr {
      background-color: white;
      height: 25px !important;
    }
  }
  tbody {
    display: block;
    overflow: auto;
    overflow-x: hidden;

    color: #4f4f4f;
  }
}
.content_address {
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  font-size: 13px;
  color: #808080;
  flex: 0.7;
  text-align: left;
}
.info-basic-date {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #4f4f4f;
}
.info_table_box {
  width: 100%;
  height: 27%;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  margin-top: 25px;
  // border: 1px solid #bdbdc2;
}
.info_tbl tr {
  border-bottom: 0.5px solid #d0d0d7;
  height: 35px;
  font-size: 12px;
  font-weight: 300px;
}
.info_tbl td {
  height: 32px;
}
.info_tbl_header p {
  font-size: 12px;
  color: #4f4f4f;
}
.current-page-title {
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.intro_root {
  background-color: #f9f9f9;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top_description_box {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }
  .top_description_box p {
    color: rgb(51, 51, 51);
    font-size: 16px;
  }
  h2 {
    color: rgb(51, 51, 51);
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .btn_box {
    display: flex;
    flex-direction: row;
    div {
      cursor: pointer;
      background-color: rgb(51, 51, 51);
      width: 200px;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      color: white;
      font-weight: 700;
      font-size: 15px;
      align-items: center;
      margin: 20px;
    }
  }
  .col_btn_box {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      width: 100%;
      height: 50px;
      background-color: #7e7e7e;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      color: #000000;
    }
    input {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;
      padding-left: 10px;
      font-size: 16px !important;
      font-weight: 500;
    }
  }
}
.nv-wrap-bg {
  background-color: #eceff5;
  min-height: 3300px;
}
.rc {
  display: flex;
}
.rc-sb {
  display: flex;
  justify-content: space-between;
}
.nv-list-button-img {
  width: 12px;
  height: 12px;
  margin-right: 10px;
}
.rc {
  display: flex;
}
.rc-sb {
  display: flex;
  justify-content: space-between;
}
.content_wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nv-badge {
  align-items: center;
  background-color: #f4f5f7;
  border: 0.5px solid #bdbdc2;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: row;
  float: left;
  justify-content: center;
  margin-top: 3px;
  margin-left: 3px;
  margin-bottom: 3px;
  margin-right: 0px;
  overflow: hidden;
  padding: 4px 8px;
  // width: -webkit-fit-content;
  // width: -moz-fit-content;
  width: fit-content;
  height: 28px;
  line-height: 28px;
}

.nv-badge p {
  margin: 0px;
}

.nv-badge img {
  width: 17px;
  padding-left: 3px;
  cursor: pointer;
}

.swal-confirm-title {
  font-size: 16px !important;
  color: #888888 !important;
}
.swal-confirm-btn {
  background-color: #1976d2 !important;
  font-size: 12px !important;
  font-weight: 600;
  padding: 4px 18px !important;
}
.swal-confirm-cancel {
  background-color: #888 !important;
  font-size: 12px !important;
  font-weight: 600;
  padding: 4px 18px !important;
}
.swal-confirm-container {
  width: 350px !important;
  display: flex;
  padding: 10px 0 10px 0 !important;
}
.swal-confirm-icon {
  font-size: 10px !important;
}
.autosearchinput-input{
  border-radius:5px;
  input{
    border-radius:5px;
  }
}
.responsive-margin{
  margin-right:20px;
  margin-left:20px;
  max-width:1600px;
  @include tablet{
      margin-right:40px;
      margin-left:40px
  }
  @include xl {
      margin-right:90px;
      margin-left:90px
  }
  @media(min-width: 1780px){
    width:100%;
    margin-right:auto;
    margin-left:auto
  }  
}
.nv-select{
  margin: 0;
  height: 100%;
  min-width: 100%;
  .MuiSelect-select{
    padding: 0;
    padding-right: 32px;
    padding-left: 10px;
    text-align: left;
  }
}
.member-admin-news-list{
  margin-bottom:1rem;
  background-color:#fff;
  border: 1px solid rgba(0,0,0,.12);
  border-bottom: 1px solid rgba(0,0,0,.25);
  border-radius: 5px;
  .header{
      position:relative;
      height:70px;
      padding:0;
      color:#222;
      .title{
          overflow: hidden;
          font-weight: bolder;
          padding: 0 20px;
          font-size: 1.25rem;
          line-height: 70px;
          letter-spacing: -.1em;
          white-space: nowrap;
          text-overflow: ellipsis;
          .strong{
              float:left
          }
          .small-display{
              @media screen and (max-width:39.9375em){
                  display:none;
              }
          }
          .small{
              float:right;
              font-size: .75rem;
              font-weight: 400;
              font-style: normal;
              line-height: inherit;
              letter-spacing: -.05em;
              color:#848484;
              .beware{
                  color:#848484;
                  font-size: .75rem;
                  font-weight: 400;
                  font-style: normal;
                  line-height: inherit;
                  letter-spacing: -.05em;
                  &::before{
                  content: '*';
                  display: inline-block;
                  margin-right: 5px;
                  font-style: normal;
                  color: #e32f66;
                  vertical-align: middle;
                  }
              }
          }
          &::after{
              content:'';
              display:table;
              clear:both;
          }
      }
      &::after{
          content:'';
          display: block;
          position: absolute;
          height: 0;
          left: 0;
          right: 0;
          border-bottom: 1px solid rgba(0,0,0,.07);
          clear:both;
      }
  }
  .article{
      padding: 1.25rem;
      .part{
          display:block;
          .top{
              padding-bottom:0;
              border-bottom:0;
              margin-bottom:1.875rem;
              display:block;
              font-size:0;
              .h2{
                  display: inline-block;
                  width: 50%;
                  vertical-align: middle;
                  margin-bottom: 0;
                  font-size: 1.25rem;
                  font-style: normal;
                  line-height: 1.375;
                  letter-spacing: -.1em;
                  .span{
                      position: absolute!important;
                      width: 1px;
                      height: 1px;
                      overflow: hidden;
                      clip: rect(0,0,0,0);
                  }
              }
              .type{
                  text-align: right;
                  display:flex;
                  justify-content: flex-end;
                  vertical-align: middle;
                  .btn-group{
                      margin-bottom:0;
                      font-size:0;
                      display:flex;
                      .unclicked{
                          transition: background-color .25s ease-out,color .25s ease-out;
                          background-color: #747a8c;
                          color:#fff;
                          border: 1px solid transparent;
                          &:hover{
                              background-color: #3b4356;
                          }
                      }
                      .clicked{
                          color: #686868;
                          background-color: #fff;
                          border: 1px solid #ced2db;
                          transition: color .3s,border-color .3s;
                          &:hover{
                              color:#4d4d4d;
                              border-color:#9da0a7
                          }
                      }
                      .btn{
                          display: flex;
                          align-items: center;
                          vertical-align: middle;
                          -webkit-appearance: none;
                          height:30px;
                          line-height: 1;
                          text-align: center;
                          cursor: pointer;
                          letter-spacing: -.075em;
                          border-radius: 3px;
                          margin:0;
                          margin-right:1px;
                          margin-bottom:1px;
                          padding:0rem 0.75rem;
                          font-size:.688rem;
                          .i{

                              width:15px;
                              height:15px;
                          }
                          .btn-span{
                              @media screen and (max-width:39.9375em){
                                  display:none
                              }
                          }
                      }
                      &::before{
                          display:table;
                          content:''
                      }
                      &::after{
                          display:table;
                          content:'';
                          clear:both
                      }
                  }
              }
          }
          .list{
              display:block;
              .items{
                  display:block;
                  padding: 0.75rem 0.9375rem;
                  font-size: 0;
                  background-color: #fff;
                  text-align: left;
                  .h4{
                      margin-bottom: 0.375rem;
                      font-size:0;
                      display:block;
                      @media screen and (min-width:80em){
                          width:65%;
                          margin-bottom:0;
                          display:inline-block;
                          vertical-align: middle;
                      }
                      .a{
                          font-size: 1rem;
                          font-weight: bolder;
                          line-height: 1.375;
                          letter-spacing: -.075em;
                          color: rgba(0,0,0,.95);
                          display: inline-block;
                          margin-right: 0.5rem;
                          vertical-align: middle;
                      }
                      .small{
                          font-size: .75rem;
                          font-weight: 400;
                          line-height: 1;
                          letter-spacing: -.025em;
                          color: rgba(0,0,0,.5);
                          display: inline-block;
                          margin-right: 0.5rem;
                          vertical-align: middle;
                          .i{
                              display: inline-block;
                              font-size: inherit;
                              line-height: 1;
                              text-rendering: auto;
                              -webkit-font-smoothing: antialiased;
                          }
                      }
                      .image-i{
                          font-size: .875rem;
                          color: rgba(0,0,0,.375); 
                          display: inline-block;
                          margin-right: 0.5rem;
                          vertical-align: middle;
                          &::before{
                              content:'\ea4e';
                              display: inline-block;
                              font: 14px/1 fontello;
                              font-size: inherit;
                              line-height: 1;
                              text-rendering: auto;
                              -webkit-font-smoothing: antialiased;
                              -moz-osx-font-smoothing: grayscale;
                          }
                          .image-i-span{
                              position: absolute;
                              width: 1px;
                              height: 1px;
                              overflow: hidden;
                              clip: rect(0,0,0,0);
                          }
                      }
                      .current-i{
                          padding: 0.25rem;
                          font-size: .563rem;
                          border-radius: 0;
                          display:inline-block;
                          vertical-align: middle;
                          letter-spacing: -.05rem;
                          color:#fff;
                          background:#9b59b6;
                          line-height:1;
                          white-space:nowrap;
                          cursor:default;
                          &:last-child{
                              margin-right:0;
                          }
                      }
                  }
                  .infos{
                      font-size:inherit;
                      display:block;
                      @media screen and (min-width:80em){
                          overflow: hidden;
                          width: 35%;
                          padding-left: 1.375rem;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          display: inline-block;
                          vertical-align: middle;
                          font-size:inherit;
                      }
                      .em{
                          font-size: .75rem;
                          font-weight: 400;
                          font-style: normal;
                          line-height: 1.25;
                          letter-spacing: -.025em;
                          color: #a7a7a7;
                          display: inline-block;
                          vertical-align: middle;
                          &:first-child::before{
                              display:none;
                          }
                          &::before{
                              content: '';
                              overflow: hidden;
                              width: 0;
                              height: 0.625rem;
                              margin: 0 0.625rem;
                              border-left: 1px solid rgba(0,0,0,.1);
                              display: inline-block;
                              vertical-align: middle;  
                          }
                          @media screen and (min-width:80em){
                              &:first-child{
                                  width:65px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                              }
                              &:nth-child(2){
                                  width:110px;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                  white-space: nowrap;
                              }
                              &:last-child{
                                  width: 130px;
                              }

                          }
                      }
                  }
              }
              .ul{
                  padding:0;
                  margin:0;
                  list-style: none;
                  display:block;
                  &::after{
                      content:'';
                      display:table;
                      clear:both;
                  }
                  .li{
                      text-align:left;
                      margin-top: 1.125rem;
                      padding-top: 1.125rem;
                      border-top: 1px solid rgba(0,0,0,.05);
                      display:block;
                      font-size:0;
                      &::after{
                          content:'';
                          display:table;
                          clear:both;
                      }
                      &:first-child{
                          margin-top:0;
                          padding-top: 0;
                          border-top: 0;
                      }
                      .img-in-li{
                          font-size:inherit;
                          color:inherit;
                          height:114px;
                          overflow: hidden;
                          float: left;
                          position: relative;
                          width: 162px;
                          margin-right: 1.25rem;
                          text-align: center;
                          border: 1px solid rgba(0,0,0,.08);
                          &::before{
                              content: '';
                              position: absolute;
                              z-index: 2;
                              left: 0;
                              top: 0;
                              width: 100%;
                              height: 100%;
                              background: 0 0;
                          }
                          @media screen and (max-width:39.9375em){
                              display: block;
                              float: none;
                              width: auto;
                              height: 0;
                              padding-bottom: 56.25%;
                              margin: 0 auto 0.75rem;
                          }
                          img{
                              position: absolute;
                              z-index: 1;
                              left: 50%;
                              top: 50%;
                              max-width: 100%;
                              max-height: 100%;
                              -ms-transform: translate(-50%,-50%);
                              transform: translate(-50%,-50%);
                              display: inline-block;
                              vertical-align: middle;
                              height: auto;
                          }
                          
                      }
                      .h4-titles{
                          font-size: 1.063rem;
                          font-weight: bolder;
                          font-style: normal;
                          line-height: 1.375;
                          letter-spacing: -.075em;
                          color: #222;
                          margin-top:0;
                          margin-bottom:0.5rem;
                          
                      }
                      .atag{
                              font-size:inherit;
                              color:inherit;
                          }
                      .p-text{
                          margin: 0 0 1rem;
                          padding: 0;
                          font-size: .813rem;
                          color: #737475;
                          min-height: 3.126em;
                          max-height:3.126em;
                          line-height:1.563;
                          -webkit-line-clamp:2;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          -webkit-box-orient: vertical;
                      }
                      .div-line{
                          display: block;
                          margin-top: 1.125rem;
                          font-size: 0;
                          .emtag{
                              display: inline-block;
                              margin-left: 0.625rem;
                              padding-left: 0.625rem;
                              font-size: .75rem;
                              font-weight: 400;
                              font-style: normal;
                              line-height: 1.125;
                              letter-spacing: -.025em;
                              color: #b7b7b7;
                              border-left: 1px solid rgba(0,0,0,.1);
                              vertical-align: middle;
                              &:first-child{
                                  margin-left:0;
                                  padding-left:0;
                                  border-left:0;
                              }
                          }
                          .div-label{
                              display: inline-block;
                              margin: 0 0 0 0.625rem;
                              vertical-align: middle;
                              font-size: 0;
                              text-align: left;
                              @media screen and (max-width:39.9375em){
                                  display:block;
                                  margin: 0.75rem 0 0;
                              }
                          }
                      }
                  }
              }
          }
          &::after{
              content:'';
              display:table;
              clear:both
          }
      }
      &::after{
          content:'';
          display:table;
          clear:both;
      }
  }
  .footer{
      padding:1.25rem;
      border-top:1px solid rgba(0,0,0,.05);
      border-radius: 0 0 5px 5px;
      background-color: #fafbfc;
      &::before{
          content:'';
          display:table;
          clear:both;
      }
      .pagin-group{
          display:block;
          font-size:0;
          text-align:left;
          .pagin{
              display:block;
              @media screen and (min-width:40em){
                  &:first-child:last-child{
                      text-align: center;
                  }
              }
              .ul{
                  margin-left:0;
                  margin-bottom:0;
                  @media screen and (max-width:39.9375em){
                      margin-bottom:1rem;
                      text-align:center
                  }
                  &::before{
                      display:table;
                      content:''
                  }
                  .li{
                      margin-right: 0.25rem;
                      border-radius:0;
                      font-size:.75rem;
                      line-height:1;
                      vertical-align: middle;
                      display:none;
                      @media screen and (max-width:39.9375em){
                          display:none;
                      }
                      @media screen and (min-width:40em){
                          display:inline-block
                      }
                      &:first-child{
                          display :inline-block
                      }
                      &:last-child{
                          display:inline-block;
                          margin-right: 0;
                      }
                      .a{
                          font-size:inherit;
                          color:#838383;
                          transition:background-color .3s ease-in-out;
                          padding: 0.438rem 0.625rem;
                          line-height:1;
                          letter-spacing: -.025em;
                          background:#fff;
                          border:1px solid rgba(0,0,0,.08);
                          border-radius: 3px;
                          text-align: center;
                          cursor:pointer;
                          &:hover{
                              background-color: #f8f8fa;
                              text-decoration:none;
                          }
                          .i{
                              width:10px;
                          }
                      }
                  .current{
                      background-color:#13c0cb;
                      color:#fff;
                      &:hover{
                      background-color:#13c0cb;
                      color:#fff;
                      }
                  }

                  }
                  .onenext{
                      @media screen and (max-width:39.9375em){
                      display:inline-block;}
                      @media screen and (min-width:40em){
                          display:none;
                      }
                  }
              }
          }
      }
  }
}
.nv-scroll-bar {
  overflow: overlay; 
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    // height: 8px;
    // background-color: #edf0f5 !important;
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: #babac0 !important;
    // background-color: hsla(0, 0%, 42%, 0.49);
    // background-color: var(--gray-color);
    border-radius: 10px;
    background-color: var(--deep_navy-color) !important;
    background-clip: padding-box;
    transition: all .3s;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 10px;
  }
  &.scroll-gray{
    &::-webkit-scrollbar-thumb {
      // background-color: #babac0 !important;
      // background-color: hsla(0, 0%, 42%, 0.49);
      // background-color: var(--gray-color);
      background-color: #D8D8D8 !important;
    }
  }
}
.nv-scroll-gray{
  &::-webkit-scrollbar-thumb {
    // background-color: #babac0 !important;
    // background-color: hsla(0, 0%, 42%, 0.49);
    // background-color: var(--gray-color);
    background-color: #D8D8D8 !important;
  }
}
.line-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.line2-truncate{
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.Toastify__toast-container{
  // bottom: 0px !important;
  // width: 100% !important;
  // padding: 0 !important;
  z-index: 36000 !important;
  &.Toastify__toast-container--top-center{
    top: 6em;
    width: fit-content !important;
  }
  .Toastify__toast{
    margin-bottom: 0px !important;
    padding: 10px !important;
    border-radius: 0 !important;
    min-height: unset !important;
    width: fit-content !important;
    background-color: #74736C !important;
    border-radius: 3px !important;
  }
  .toast-success{
    background-color: #458B00 !important;
  }
  .toast-warn{
    background-color: rgb(220, 74, 67) !important;
  }
  .Toastify__toast-body{
    justify-content: center;
    margin: unset !important;
    padding: 0 6px !important;
    &>div:last-child{
      flex:none;
      font-size: 1.4px;
      color: #fff;
      font-weight: 500;
    }
    svg{
      color: #fff;
      font-size: 20px;
    }
  }
}
.nv-daterangepicker{
  .ant-picker{
    width: 95px;
    border: none;
    &.open{
      input{
        color: var(--main_logo-color) !important;
      }
    }
  }
}
.swal-confirm-wrap {
  z-index: 35000!important
}

.swal-confirm-title {
  color: #202020!important;
  font-size: 16px!important;
  font-weight: 700;
  padding: 20px 20px 0
}

.swal-confirm-container {
  border-radius: 0!important;
  display: flex!important;
  flex-direction: column!important;
  min-width: 30%!important;
  padding: 0!important;
  width: auto!important;
  z-index: 30000!important
}

.swal-confirm-container.single .swal-confirm-title {
  color: #202020!important;
  font-size: 16px!important;
  font-weight: 500!important;
  opacity: .9
}

.swal-confirm-container.single .swal2-actions {
  padding-bottom: 30px!important
}

.swal-confirm-container.single .swal2-html-container {
  font-weight: 500!important;
  line-height: 25px
}

.swal-confirm-container.single .swal-confirm-btn {
  border-radius: 1px!important;
  flex: none!important;
  height: 38px!important;
  width: 70%!important
}

.swal2-html-container {
  color: #202020!important;
  padding: 20px 20px 25px!important;
  margin:0 !important;
  font-size:14px !important;
  p{
    margin:0 !important;
  }
}

.swal2-actions {
  margin: 0!important;
  width: 100%!important
}

.swal2-actions .swal2-cancel,.swal2-actions .swal2-confirm {
  border-radius: 0!important;
  cursor: pointer!important;
  border:none !important;
  flex: 1 1!important;
  font-size: 14px!important;
  height: 50px!important;
  margin: 0!important
}

.swal2-actions .swal2-confirm {
  background-color: #4084fc !important;
  background-color: $nSight-main_logo-color !important;
  color: #fff!important
}

.swal2-actions .swal2-cancel {
  background-color: #edf0f5 !important;
  background-color: $nSight-shallow_gray-color !important;
  color: #202020 !important
}

.swal-confirm-icon {
  font-size: 10px!important
}