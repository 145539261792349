/* @font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: local('Spoqa Han Sans Neo Bold'),
    url('../assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    src: local('Spoqa Han Sans Neo Medium'),
    url('../assets/fonts/SpoqaHanSansNeo-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    src: local('Spoqa Han Sans Neo Regular'),
    url('../assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    src: local('Spoqa Han Sans Neo Light'),
    url('../assets/fonts/SpoqaHanSansNeo-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 100;
    src: local('Spoqa Han Sans Neo Thin'),
    url('../assets/fonts/SpoqaHanSansNeo-Thin.ttf') format('truetype');
} */
@font-face {
    font-family:'Malgun Gothic';
    src: url('../assets/fonts/malgun.ttf') format('truetype');
}
@font-face {
    font-family: 'PT Serif';
    src: url("../assets/fonts/pt-serif-v17-latin-regular.woff2") format("woff2"),url("../assets/fonts/pt-serif-v17-latin-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'PT Serif';
    src: url("../assets/fonts/pt-serif-v17-latin-700.woff2") format("woff2"),url("../assets/fonts/pt-serif-v17-latin-700.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'PT Serif';
    src: url("../assets/fonts/pt-serif-v17-latin-italic.woff2") format("woff2"),url("../assets/fonts/pt-serif-v17-latin-italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'PT Serif';
    src: url("../assets/fonts/pt-serif-v17-latin-700italic.woff2") format("woff2"),url("../assets/fonts/pt-serif-v17-latin-700italic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
  font-family: "MaisonNeue";
  src: url("../assets/fonts/MaisonNeue-Medium.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeue";
  src: url("../assets/fonts/MaisonNeue-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeue";
  src: url("../assets/fonts/MaisonNeue-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeueExtended";
  src: url("../assets/fonts/MaisonNeueExtended-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MaisonNeueMono";
  src: url("../assets/fonts/MaisonNeueMono-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
/* 
@font-face {
    font-family: "Publico Headline";
    src: url("/public/fonts/PublicoHeadline/PublicoHeadline-Bold-Web.woff2") format("woff2"),url("/public/fonts/PublicoHeadline/PublicoHeadline-Bold-Web.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap
} */
