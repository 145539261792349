
.investor-wrap{
    background-color: #F5F5F5;
}
.investor-content-wrap{
    width: 1240px;
    height: 100%;
    padding-top: 0 !important;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.investor-left-card{
    padding:27px 34px 0px 24px;
    
    // box-shadow: 0px 0.5px 0px #d4cec6;
    background-color: white;
}

.investor-left-card:after{
    content: '';
    display: block;
    border-bottom: 1px solid rgba(231, 231, 231, 0.73);

}
.investor-left-content-box{
    margin-bottom: 30px;
}


.investor-header{
    background: linear-gradient(180deg, rgba(218, 223, 232, 1) -57.29%, #C4C4C4 100%) !important;
}
.investor-header-logo{
    width: 30px;
    height: 30px;
    border: 1px solid rgba(199, 199, 199, 0.22);
    box-sizing: border-box;
    filter: drop-shadow(0px 1px 0px rgba(255, 255, 255, 0.55));
}
.investor-header-name{
    font-weight: 800;
    font-size: 19px;
    color: #393936;
    margin-top: 4px;
}
.investor-header-desc{
        font-style: normal;
    font-weight: 400;
    font-size: 13px;

    color: #393936;
}
.investor-nav-link{
    // color: white
}
.investor-nav-item{
}
.investor-nav-item:hover{
    // background-color: transparent !important;
}
.investor-nav-item.active{
    // border-bottom-color: white !important;
}
.investor-rank-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 2px 0px #E7E7E7;
    background-color: white;
}
.investor-rank-card{
    width: 27%;
    background-color: #FFFFFF;
    padding: 0 24px 12px 24px;
    margin: 0px 0;
}
.invstor-rank-card-title{
        font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: #393936;
    padding: 16px 0 21px 0;
}
.investor-rank-item{
    width: 100%;
    height: 30px;
    // border: 1px solid rgba(45, 1, 101, 0.25);
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .order{
                font-style: normal;
        font-weight: 400;
        font-size: 11px;
        color: #4F4F4F;
        margin-right: 13px;
    }
    .number{
                font-style: normal;
        font-weight: 700;
        font-size: 12px;
        color: #000000;
    }
    .name{
                font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #000000;
        width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .sub-name{
                font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #929292;

    }
    .left-box{
        display: flex;
        align-items: center;
    }
    .oval{
        width: 25px;
        height: 18px;
        overflow: hidden;
        border-radius: 50%;
        background-size: auto;
        border: 1px solid rgba(224, 224, 224, 0.55);
        filter: drop-shadow(0px 1px 0px #E7E7E7);
        display: flex;
        align-items: center;
        margin-right: 9px;
    }
    .logo{
        width: 100%;
    }
}
.info_content_right_box .investor-rank-card{
    width: 100% !important;
}
.investor-brief-box{
    display: flex;     
    padding: 0px 14px 37px 24px;
    margin: 0px 0 0px 0px;
    background: white !important;
    font-size: 15px;
    font-weight: bold !important;
    .text-box:first-child{
        margin-right: 10px;
        margin-left: 0 !important;
    }
    .text-box{
        margin-left: 10px;
    }
    .text-title{
        font-weight: bold;
        color: #393936
    }
}
.investor-news-container{
    width: 100%;
    background: #FFFFFF;
    padding: 16px 16px;

}
.investor-news-content-container{
    display: flex;
    flex-wrap: wrap;
}
.investor-news-title{
        font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #444444;
    padding: 11px 14px 27px 24px;

}
.investor-news-item{
    width: 31%;
    height: 163px;
    background: rgba(231, 231, 231, 0.33);
    border-radius: 10px;
    padding:18px 16px;
    margin: 10px 10px;
    .news-date{
                font-style: normal;
        font-weight: 400;
        font-size: 10px;
        color: #929292;
        margin-bottom: 7px;
    }
    .news-title{
                font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #000000;
        margin-bottom: 7px;
    }
    .news-content{
                font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #4F4F4F;
        overflow: hidden;
    }
}
.investor-introduce-box{
    width: 100%;
    background: rgba(231, 231, 231, 0.33);
    border-radius: 10px;
    padding: 16px;
    color: #000000;
    font-size: 12px;
        font-style: normal;
    font-weight: 400;
    margin-bottom: 12px;

}
.investor-label-GP{
    background: linear-gradient(266.42deg, rgba(26, 25, 25, 0.77) 0%, #1A1919 100%);
    box-shadow: 0px 1px 0px #1A1919;
    color: #FFFFFF;
    font-size: 10px !important;
}
.investor-label-CO-GP{
    width: 40px !important;
    background: linear-gradient(284.04deg, #FFC700 1.66%, #FF9900 101.66%);
    box-shadow: inset 0px -1px 0.1px rgba(255, 255, 255, 0.22);
    border-radius: 100px;
    color: #FFFFFF !important;
    font-size: 10px !important;


}