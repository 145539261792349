textarea{
    resize:none;

}
input[type="text"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input{
    font-size: 12px ;
}
pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    word-break: break-all;
}
img{
    image-rendering: -webkit-optimize-contrast;
}
.navigate-btn{
    &.MuiButton-root{
        height: 35px !important;
        width: 140px;
        padding: 6px 12px !important;
        font-size: 12px;
        svg{
            width:23px;
            height: auto;
            margin-right: 10px;
            fill: #3A4145;
        }
        &:hover{
            background-color: #F9B761 !important;
            color: white !important;
            svg{
                fill: #fff !important;
            }
    
        }
    }
}
.nv-fill-btn{
    &.MuiButton-root{
        height: 35px !important;
        padding: 6px 12px !important;
        background-color: #638FFE !important;
        color: white !important;
        svg{
            width:23px;
            height: auto;
            margin-right: 10px;
            fill: #3A4145;
        }
        &:hover{
            opacity: 0.7;

        }
    }
    &.Mui-disabled{
        background-color: #e7e7e7 !important;
    }
}

.ql-editor.ql-blank::before{
    font-style: normal !important;
    color: #ADADAD !important;
    opacity: 0.6;
}
.ant-picker-dropdown{
    z-index: 3000;
}
ol, ul, dl{
    margin-bottom: 0;
}