@import 'styles/colors';
@import 'styles/_mixin';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


.admin-main-wrap{
    min-height:100%;
    background-color: #f7f7f7;
    html {
        font-family: sans-serif;
        line-height: 1.15;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        box-sizing: border-box;
        font-size: 100%;
        &[type=button] {
            -webkit-appearance: button;
        }
    }

    body{
        margin:0;
        padding: 0;
        background: #fff;
        font-family: Helvetica Neue;
        font-weight: 400;
        line-height: 1.5;
        color: #0a0a0a;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }

    button,input,optgroup,select {
        font-family: sans-serif;
        font-size: 100%;
        line-height: 1.15;
        margin: 0
    }

    button {
        overflow: visible
    }

    button,select {
        text-transform: none
    }

    [type=reset],[type=submit],button,html [type=button] {
        -webkit-appearance: button
    }

    [type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner {
        border-style: none;
        padding: 0
    }

    [type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring {
        outline: 1px dotted ButtonText
    }
    button {
        padding: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        border-radius: 0;
        background: transparent;
        line-height: 1
    }

    [data-whatinput=mouse] button {
        outline: 0
    }
        a,a:link,a:visited,a[rel=menu],body,button,dl,input,ol,select,td,th,ul {
            color: #222
        }

    a:visited {
        text-decoration: none
    }

    a:active,a:hover,a[rel=menu]:hover {
        text-decoration: underline;
    }
    @media print {
        * {
            -webkit-print-color-adjust: exact!important
        }

        a[href] {
            text-decoration: none
        }

        a[href]:after {
            content: none!important
        }

        .print-show {
            display: block!important
        }

        .print-hide {
            display: none!important
        }
    }

    font[style] {
        padding: 0!important;
        margin: 0!important;
        font: 0!important;
        color: inherit!important
    }
    &.active{
        .admin-active-overlay{
            display: block !important;
        }
        .leftaside{
            left: 72px !important;
            .nav-item:not(:first-child){
                & > a{
                    pointer-events: none;
                }
            }
        }
    }
    .admin-nav-btns {
        float: left;
        max-width: 45px;
        height: 100%;
        margin-right: 1.125rem;
        text-align: left;
        outline-width: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            display: block;
            position: relative;
            width: 25px;
            height: 2px;
            background-color: #4d5370;
            transition: width .3s ease-in-out;
            &::before, &::after{
                content: '';
                overflow: hidden;
                position: absolute;
                z-index: 1;
                left: 0;
                height: 0;
                border-top-width: 2px;
                border-top-style: solid;
                border-top-color: #4d5370;
                transition: width .3s ease-in-out;
            }
            &::before{
                top: -8px;
                width: 90%;
            }
            &::after{
                width: 72%;
                bottom: -7px;
            }
        }
    }
    .admin-nav-title{
    }
    @media screen and(max-width:63.9375em){
        overflow-y:auto;
        max-width:100%;

    }
    @media screen and (min-width: 64em){
        .admin-nav-btns{
            display: none;
        }
    }
    @media screen and (max-width: 39.9375em){
        .admin-nav-title{
            display: none !important;
        }
        .admin-nav-btns{
            max-width: none;
            padding: 0 5px 0 20px;
            margin-right: 0;
            .icon{
                width: 20px;
            }
        }
    }
    .admin-main{
    min-height:calc(100vh - 72px);
    overflow: hidden;
    position: relative;
    margin-right: auto;
    margin-left: auto;

    &::before{
        display:table;
        content:'';
    }


    .admin-body{
        display:flex;
        position:relative;
        min-height: calc(100vh - 72px);
        width:100%;
        transition: all .25s ease;
        @media screen and (max-width: 63.9375em){
            display:block;
        }
        
    }
    .admin-body-middle{
        width: calc(100% - 492px);
        padding: 50px;
        transition: width .25s ease,padding .25s ease;
        @media screen and (max-width: 99.9375em) {
            width: calc(100% - 472px);
        }
        @media screen and (max-width:89.9375em){
            width:calc(100% - 72px);
        }
        @media screen and (max-width: 63.9375em) {
            width: auto;
            padding: 15px
        }
    }
    .admin-active-overlay{
        position: absolute;
        z-index: 1002;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: .5;
        display: none;
    }
    .leftaside{
        background-color: #fff;
        position: relative;
        z-index:1003;
        width:72px;
        min-height:calc(100vh - 72px);
        border-right:1px solid rgba(0,0,0,.08);
        transition:width .25s ease, transform .3s ease-in-out;
        display:block;
        @media screen and (max-width: 63.9375em){
            position: absolute;
            top:0;
            left:0;
            min-height:100%;
            transform: translatex(-100%);
            transition: all .3s ease-in-out
        }
        .nav{
            padding:38px 0;
            margin-bottom:0;
            width:71px;
            display:block;
            &>ul{
                margin:0;
                list-style-type: none;
                color:#222;
                list-style-position: outside;
                line-height:1.6;
                padding:0;
                &>li{
                    position: relative;
                    font-size:0;
                    display:block;
                    outline:0;
                    vertical-align: middle;
                    &:hover{
                        .submenu-box{
                            display: unset;
                        }
                    }
                    &>a{
                        color:#fff;
                        
                        &:hover{
                            svg{
                                stroke: #5684ff !important;
                            }
                            .text{color: #5684ff}
     
                        }
                    
                        font-size:0;
                        padding: 0.75rem 0.625rem;
                        text-decoration: none;
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position:relative;
                        text-align:center;
                        line-height:1;
                        &::before{
                            content:'';
                            overflow:hidden;
                            position:absolute;
                            z-index:1;
                            left:0;
                            top:0;
                            bottom:0;
                            width:0;
                            border-left:3px solid transparent;
                        }
                        svg{
                            margin-right:0;
                            font-size:1.25rem;
                            line-height:1;
                            display:inline-block;
                            vertical-align: middle;
                            width:18px;
                            height:18px;
                            stroke: #3e3e3e;
                            &::before{
                                content:'\e813';
                                display:inline-block;
                                font:14px/1 fontello;
                                font-size:inherit;
                                line-height:1;
                                text-rendering:auto;
                                -webkit-font-smoothing:antialiased;
                            }
                            
                        }
                        .text{
                            margin-top:0.375rem;
                            display:inline-block;
                            font-family:Arial, Helvetica, sans-serif;
                            max-width:100%;
                            padding:0 2px;
                            font-size:0.688rem;
                            font-weight:400;
                            line-height:1.25;
                            text-align: center;
                            transition:color .25s ease-in-out, background-color .25s ease-in-out;
                            overflow:hidden;
                            letter-spacing:-.075em;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                            color:#3e3e3e;
                        }
                    }
                    .active {
                        .text{
                            color:#5684ff !important;
                        }
                        svg{
                            stroke: #5684ff;
                        }
                        &::before{
                            border-left-color:#5684ff;
                        }
                    }
                    .submenu-box{
                        position: absolute;
                        min-width: 200px;
                        left: 70px;
                        top: 0px;
                        display: none;
                        margin-left: 0;
                        padding: 1.25rem 0;
                        background-color: #5684ff;
                        border: 0;
                        li{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            position: relative;
                            height: 30px;
                            padding: 0 1.5rem;
                            font-size: .65rem;
                            line-height: 30px;
                            color: rgba(255,255,255,.95);
                            text-decoration: none;
                            transition: color .2s ease-in-out,background-color .3s ease-in-out;
                            cursor: pointer;
                            &::before{
                                display: block;
                                content: '';
                                width: 1px;
                                height: 100%;
                                background-color: rgba(255, 255, 255, 0.2);
                                margin-right: 10px;
                            }
                            &:hover{
                                background-color: rgba(255, 255, 255, 0.08);
                            }
                            &.selected{
                                a{
                                    color: rgba(255, 255,255, 1);
                                    font-weight: 600;
                                }
                                background-color: rgba(255, 255, 255, 0.08);
                                &::before{
                                    background-color: rgba(255, 255, 255, 1);
                                }
                            }
                            a{
                                flex:1;
                                text-decoration: none;
                                color: rgba(255, 255, 255, 0.9);
                            }
                            .line{
                                width: 1px;
                                height: 100%;
                                background-color: #fff;
                            }

                        }
                    }
                }
            }
        }
    }
    .rightaside{
        width: 420px;
        padding: 50px 40px;
        background-color: #fff;
        border-left: 1px solid rgba(0,0,0,.15);
        transition: width .25s ease,padding .25s ease;
        display:block;
        @media screen and (max-width:99.9375em){
            width:400px;
        }
        @media screen and (max-width:89.9375em){
            display:none;
            width:300px;
            padding:25px;
        }
        .today-date{
            display: block;
            padding-bottom: 1rem;
            margin-bottom: 1.438rem;
            font-size: 0;
            border-bottom: 1px solid #eee;
            text-align: center;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 300;
            letter-spacing: -.025em;
            color: #838383;
            line-height: 1.125;
            @media screen and (max-width:63.9375em){
                padding-bottom:0;
                margin-bottom:1.375rem;
                border-bottom:0
            }
            .strong{
                font-weight:700;
                letter-spacing:-.05em;
                color:#212121;
            }
            .small{
                font-size:.625em;
                line-height:inherit;
            }
        }
        .current-num{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-justify-content: space-around;
            -ms-justify-content: space-around;
            justify-content: space-around;
            margin-bottom: 2.875rem;
            @media screen and (max-width:63.9375em){
                margin-bottom:1.25rem;
                padding:1.25rem 0;
                background-color:#fff;
                border-radius:5px;
            }
            .content{
                display: flex;
                align-items: center;
                flex-direction: column;
                cursor:pointer;
                .svg-wrap{
                    width:38px;
                    height:38px;
                    display:flex;
                    align-items: center;
                    border-radius: 50%;
                .svg{
                    display: block;
                    overflow: hidden;
                    width: 20px;
                    height: 20px;
                    margin: 0 auto;
                    font-size: 1rem;
                    line-height: 38px;
                    color: #fff;
                    border-radius: 50%;
                }}
                .apply{
                    background-color:#ffca00;
                }
                .re{
                    background-color:#f66874;
                }
                .ok{
                    background-color:#5684ff
                }
                .span-title{
                    display:block;
                    margin-top:0.375rem;
                    font-size:0;
                    .cnt{
                        margin-right: 0.25rem;
                        font-size: 1.25rem;
                        font-weight: bolder;
                        font-style: normal;
                        line-height: 26px;
                        letter-spacing: -.025em;
                        color: #212121;
                        display:inline-block;
                        vertical-align:middle;
                    }
                    .txt{
                        font-size: .813rem;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: -.075em;
                        color: #838383;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
        .dash-note{
            display:block;
            font-size:0;
            text-align:left;
            margin-bottom:2.5rem;
            .dash-note-header{
                text-decoration: none;
                overflow:hidden;
                margin-bottom:1.375rem;
                display:block;
                .header-title{
                    display: inline-block;
                    font-size: 1.125rem;
                    vertical-align: middle;
                    font-weight: bolder;
                    line-height: 1.125;
                    letter-spacing: -.125em;
                    @media screen and (max-width:39.9375em){
                        font-size:1rem;
                    }
                    @media screen and (max-width:63.9375em){
                        font-size:1.063rem;
                    }
                }
                .header-cnt{
                    width: auto;
                    min-width: 18px;
                    margin-left: 0.375rem;
                    padding: 0;
                    font-size: .625rem;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 18px;
                    letter-spacing: -.0125em;
                    vertical-align: middle;
                    color:#fff;
                    background:#ffc600;
                    border-radius:2rem;
                    display:inline-block;
                    text-align: center;
                }
                .svg{
                    float: right;
                    font-size: .875rem;
                    line-height: 20px;
                }
            }
            .content-box{
                background-color: #fafbfc;
                border-radius: 5px;
                overflow: hidden;
                padding: 25px;
                display:block;
                .latest-note{
                    display:block;
                    font-size:0;
                    text-align:left;
                    .note-href{
                        display: block;
                        padding-top: 0.875rem;
                        margin-top: 0.875rem;
                        border-top: 1px solid rgba(0,0,0,.05);
                        text-decoration: none;
                        &:first-child{
                            margin-top:0;
                            padding-top:0;
                            border-top:0;
                        }
                        .note-txt{
                            margin-bottom: 0.375rem;
                            font-size: .813rem;
                            line-height: 1.375;
                            letter-spacing: -.075em;
                            color: #838383;
                            transition: color .3s ease-in-out;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            &:hover{
                                color: #212121;
                                text-decoration: underline;
                            }
                        }
                        .note-btm{
                            font-size: .75rem;
                            font-weight: 300;
                            font-style: normal;
                            line-height: 1.125;
                            letter-spacing: -.025em;
                            color: #b4b4b4;
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
        
    }
    .write-right-aside{
        background-color: #fff;
        border-left: 1px solid rgba(0,0,0,.08);
        width:420px;
        @media screen and (max-width: 99.9375em){
            width: 400px;
        }
        @media screen and (max-width: 89.9375em) {
            width: 300px;
            display:block;
            -ms-transform: translate(0);
            transform: translate(0);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
        z-index: 1000;
        right: 0;
        bottom:0;
        top:72px;
        position:fixed;
        padding: 0;
        transition:transform .3s ease-in-out,-ms-transform .3s ease-in-out,width .25s ease,padding .25s ease;
        .aside-btn{
            display: none;
            position: absolute;
            z-index: 9;
            right: 100%;
            top: 50px;
            width: 28px;
            height: 28px;
            font-size: .875rem;
            color: #fff;
            background-color: #596689;
            border-radius: 3px 0 0 3px;
            text-align: center;
            cursor: pointer;
            opacity: .6;
            transition: opacity .25s ease;
            @media screen and (max-width: 89.9375em){
                display: block;
            }
            .svg1{
                margin-left:6px;
                margin-top:3px;
                width:15px;
                height:15px;
            }
            .svg2{
                margin-left:2px;
                margin-top:3px;
                width:15px;
                height:15px;
            }
        }
        .aside-btn-originver{
            display:block;
        }
        .aside-contents-container{
            height: calc(100% - 45px);
            @include desktop{
                height: 100%;
            }
            padding: 25px 22px 25px 40px;
            touch-action:pinch-zoom;
            position: relative;
            overflow-y:scroll;
            @media screen and (max-width: 89.9375em) {
                padding: 25px 7px 25px 25px;
            }
            &::-webkit-scrollbar{
                width:16px;
                background-color:#fff;
            }
            &::-webkit-scrollbar-thumb{
                background-color:rgba(0,0,0,.2);
                height:134px;
                border-radius: 16px;
                background-clip: padding-box;
                border: 6px solid transparent;
            }
            .contents-wrap{
                position: relative;
                overflow: visible;
                height: 100%;
                max-width: 100%;
                outline: 0;
                direction: ltr;
                max-height: none;
                .contents{
                    width: auto;
                    overflow: hidden;
                    height: auto;
                    position: relative;
                    top: 0px;
                    left: 0px;
                }
            }
        }
        .drag-container{
            display:block;
            margin-top:1.625rem;

                @media screen and (min-width:100em){
                    margin-top:0;
                }
            .drag-contents{
                margin-top:0.625rem;
            }
            .textbox-title{
                overflow: hidden;
                font-size: .9375rem;
                font-weight: bolder;
                line-height: 1.375;
                letter-spacing: -.075em;
                text-overflow: ellipsis;
                white-space: nowrap;
                display:block;
                margin-bottom:0.625rem;
            }
            .textbox-txt{
                overflow: hidden;
                margin-top:0.625rem;
                padding-bottom:1rem;
                border-bottom:1px solid rgba(0,0,0,.08);
                font-size: 1.125rem;
                line-height: 1.375;
                letter-spacing: -.1em;
                color: #222;
                text-overflow: ellipsis;
                white-space: nowrap;
                display:block;
            }
            .textbox{
                display:flex;
                width: 100%;
                min-height: 65px;
                align-items: center;
                justify-content: center;
                font-size: 1.125rem;
                margin-bottom: 1rem;
                line-height: 1.25;
                letter-spacing: -.05em;
                color: #222;
                border: 1px dashed rgba(0,0,0,.75);
                border-radius: 3px;
                opacity: .4;
                cursor: pointer;
                transition: opacity .3s ease-in-out,border .3s ease-in-out;
                &:hover{
                    opacity:1
                }
            }
            .제목{
                font-size:1.688rem
            }
            .부제목{
                font-size:1.25rem
            }
        }
    }
    .origin{
        width:420px;
        -ms-transform: translate(420px);
        transform: translate(420px);

        @media screen and (max-width: 99.9375em){
            width: 400px;
            -ms-transform: translate(400px);
            transform: translate(400px);
        }
        @media screen and (max-width: 89.9375em) {
            width: 300px;
            display:block;
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
        @media screen and (max-width: 63.9375em){
            -ms-transform: translate(300px);
            transform: translate(300px);
        }
    }
    .bar-active-origin{

        -ms-transform: translate(0);
        transform: translate(0);
            @media screen and (max-width:99.9375em){
                -ms-transform: translate(0);
                transform: translate(0);
            }
            @media screen and (max-width: 89.9375em){
                -ms-transform: translate(0);
                transform: translate(0);
            }
            @media screen and (max-width: 63.9375em){
                -ms-transform: translate(0);
                transform: translate(0);
            }
    }
    .bar-active{
        -ms-transform: translate(420px);
        transform: translate(420px);
            @media screen and (max-width:99.9375em){
                -ms-transform: translate(400px);
                transform: translate(400px);
            }
            @media screen and (max-width: 89.9375em){
                -ms-transform: translate(300px);
                transform: translate(300px);
            }
            @media screen and (max-width: 63.9375em){
                -ms-transform: translate(0);
                transform: translate(0);
            }
    }

    .bar-in{
        padding: 1.25rem;
        background-color: #fff;
        border: 1px solid rgba(0,0,0, .12);
        border-bottom: 1px solid rgba(0,0,0, .25);
        border-radius:5px;
        .tools{
            display:block;
            margin-top: 1.375rem;
            padding-top: 1.375rem;
            border-top: 1px solid rgba(0,0,0,.08);
            &:first-child{
                margin-top:0;
                padding-top:0;
                border-top:0;
            }
            .str{
                display: block;
                overflow: hidden;
                margin-bottom: 1rem;
                font-size:1.125rem;
                font-weight: bolder;
                line-height: 1.375;
                letter-spacing: -.075em;
                text-overflow: ellipsis;
                white-space: nowrap;
                @media screen and (min-width:100em){
                    font-size: .9375rem;
                }
            }
            .column-align{
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .kit{
                @media screen and (max-width: 63.9375em){
                    margin-bottom: 1,875rem
                }
                &::after{
                    content:'';
                    display:table;
                    clear:both
                }
                .icon-btn{
                    float: left;
                    margin-left: 2%;
                    margin-top: 2%;
                    padding: 0.375rem 0;
                    color: #222;
                    background-color: #fff;
                    text-align: center;
                    cursor: pointer;

                    &:hover{
                        .icon{
                        fill:#13c0cb}
                        &{
                            text-decoration-color: #13c0cb;
                        }
                        .btn-txt{
                            color:#13c0cb;
                        }
                    }
                    @media screen and (min-width:100em){
                        width: 32%;
                        &:nth-child(3n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+3){
                            margin-top:0
                        }
                    }
                    @media screen and (max-width: 89.9375em){
                        width: 23.5%;
                        &:nth-child(4n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+4){
                            margin-top:0;
                        }
                    }
                    @media screen and (min-width: 90em) and (max-width: 99.9375em){
                        width:18.4%;
                        &:nth-child(5n+1){
                            margin-left:0;
                            clear:both;
                        }
                        &:nth-child(-n+5){
                            margin-top:0;
                        }
                    }
                    .icon{
                        text-align:center;
                        line-height:inherit;
                    }
                    .btn-txt{
                        display: block;
                        overflow: hidden;
                        margin-top: 0.5rem;
                        font-size: .7rem;
                        line-height: 1.25em;
                        letter-spacing: -.08em;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        @media screen and (min-width:100em){
            .disabled{
            display:none;
            }
        }
    }
    .bar-in-right{
        padding: 0;
        background: 0 0;
        border: 0;
        border-radius: 0;
    }
    .admin-dashboard {
        position: relative;
        min-height: 100vh;
        @media screen and (max-width: 63.9375em) {
            display:block;
            min-height: initial;
            .left-grid, .right-grid {
                width: auto;
                padding: 0
            }
           .right-grid {
                background: 0 0
            }
        }
    }
    .panel-container{
        margin-bottom: 1rem;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.12);
        border-bottom: 1px solid rgba(0,0,0,.25);
        border-radius: 5px;
        .panel-header{
            position: relative;
            height: 70px;
            padding: 0;
            color: #222;
            &::after{
                content: '';
                display: block;
                position: absolute;
                height: 0;
                left: 0;
                right: 0;
                border-bottom: 1px solid rgba(0,0,0,.07);                
            }
            .panel-title{
                overflow: hidden;
                font-weight: bolder;
                padding: 0 20px;
                font-size: 1.25rem;
                line-height: 70px;
                letter-spacing: -.1em;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: flex;
                justify-content: space-between;
                align-items: center;
                strong{
                    float:left;
                    font-weight:700;
                    line-height:inherit;
                }
                small{
                    color:#848484;
                    font-size:.75rem;
                    font-weight:400;
                    font-style:normal;
                    line-height:inherit;
                    letter-spacing:-.05em;
                    float:right;
                }
            }
        }
        .panel-block{
            padding:1.25rem;
            display:block;
            .panel-filter{
                background-color:#edf2ff;
                margin-bottom:1rem;
                font-size:0;
                text-align:center;
                display:block;
                padding:1.25rem;
                border-radius:5px;
                &>div{
                    margin-left:0;
                    .item{
                        display:inline-block;
                        margin-left:2px;
                        margin-bottom:5px;
                        vertical-align:middle;
                        @media screen and (max-width:39.9375em){
                            display:block;
                            margin-left:0;
                        }
                    }
                    .searchitem{
                        .MuiPaper-root{
                            margin-bottom: 0;
                            display: flex;
                            width: 100%;
                            box-shadow: unset;
                            height: 100%;
                        }
                        width:220px;
                        @media screen and (max-width:63.9375em){
                            width:100%;
                            margin-top:5px;
                        }
                        @media screen and (max-width:39.9375em){
                            width:auto;
                        }
                        .MuiInputBase-root{
                            width: 100%;
                            padding: 0 0 0 0.5rem;
                            font-size: 0.813rem;
                            min-height: 40px;
                            border-radius: 5px 0 0 5px;
                            color: #0a0a0a;
                            font-weight: 400;
                            box-shadow: unset;
                            border: 1px solid #ced2db;

                        }
                        .btns{
                            min-height: 40px;
                            width: 50px;
                            margin: 0;
                            border-radius: 0 5px 5px 0;
                            background-color: #747a8c;
                            white-space: nowrap;
                            vertical-align: middle;
                            padding-top: 0;
                            margin-bottom: 0;
                            text-align: center;
                            height: 100%;
                            display: flex;
                            svg{
                                color: #fff
                            }
                        }
                    }        
                }            
                
            }
            .integration{
                .nv-select-container{
                    border-radius: 0px;
                }
                &>div{
                    .searchitem{
                        display: block;
                        overflow: hidden;
                        position: relative;
                        width: 100%;
                        max-width: 579px;
                        margin: 0 auto;
                        padding: 0;
                        background-color: #fff;
                        border: 2px solid #5684ff;
                        margin-top: .8r;
                        .MuiInputBase-root{
                            border-radius: 0px;

                        }
                        .btns{
                            float: right;
                            width: 52px;
                            height: 48px;
                            font-size: 1.75rem;
                            color: #fff;
                            background-color: #5684ff;
                            cursor: pointer;
                            border-radius: 0px;
                        }
                    }

                }
            }
            .panel-table{
                border-collapse:collapse;
                margin-bottom:1.25rem;
                width:100%;
                border-radius:0;
                @media screen and (max-width:39.9375em){
                    // table-layout:fixed;
                    .thead{
                        display: none;
                    }
                    colgroup{
                        display: table-caption;
                    }
                }
                .thead{
                    border:1px solid #f1f1f1;
                    color:#0a0a0a;
                    background:#fff;
                    border-left-width:0;
                    border-right-width:0;
                    border-top-width:2px;
                    border-color:#838383;
                    th{
                        font-weight:700;
                        padding:0.875rem 0.625rem;
                        line-height:1.375;
                        letter-spacing: -.025em;
                        padding-top:20px;
                        padding-bottom:20px;
                        font-size:.9375em;
                        color:#222;
                    }
                    .hideTh{
                        @media screen and (max-width:39.9375em){
                            display:none;
                        }
                    }
                }
                .tbody{
                    border:1px solid #f1f1f1;
                    background-color:#fff;
                    border-width:1px 0;
                    border-left-width:0;
                    border-right-width:0;
                    @media screen and (max-width:63.9375em){
                        border-top:1px solid #f1f1f1;
                    }
                    
                    tr{
                        &.recog{
                            background-color: #ecf1ff!important;
                        }
                        &:hover{
                            background-color:#f9f9f9;
                        }
                        // &:nth-child(odd){
                        // }
                        &:nth-child(even) {
                                background-color: #f9f9f9;
                            &:hover{
                                background-color:#f1f1f1;
                            }
                        }
                        @media screen and (max-width:39.9375em){
                            text-align: center;
                            &>:first-child, &>:nth-child(2), &>:nth-child(3), &>:nth-child(4), &>:nth-child(5){
                                display: block;
                                text-align: center;
                            }
                            &>:nth-child(6),  &>:nth-child(7), &>:last-child{
                                display: inline-block
                            }
                            
                        }
                    }
                    th{
                        font-weight:700;
                        padding:0.875rem 0.625rem;
                        line-height:1.375;
                        letter-spacing: -.025em;
                        padding-top:20px;
                        padding-bottom:20px;
                        font-size:.9375em;
                        color:#222;
                        a{
                            display: block;
                            font-size: inherit;
                            color: #222;
                        }
                        div{
                            margin-bottom:0;
                            display:flex;
                            justify-content: center;
                            .btn{
                                padding:0.5rem 0.75rem;
                                display:flex;
                                align-items: center;
                                font-size:.688rem;
                                border-radius: 5px;
                                color:#686868;
                                background-color:#fff;
                                border:1px solid #ced2db;
                                transition:color .3s, border-color .3s;
                            }
                        }
                    }
                    td{
                        color: rgba(0,0,0,.5)!important;
                        padding:0.875rem 0.625rem;
                        font-size:.713rem;
                        line-height:1.375;
                        letter-spacing:-.025em;

                    }
                    .level-group{
                        display: block;
                        font-size: 0;
                        text-align: center;
                        .level{
                            width: 24px;
                            height: 24px;
                            margin-right: 1px;
                            font-size: .688rem;
                            font-weight: 400;
                            line-height: 1;
                            color: silver;
                            background-color: #fff;
                            border: 1px solid rgba(0,0,0,.1);
                            border-radius: 3px;
                            text-transform: uppercase;
                            vertical-align: middle;
                            cursor: pointer;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .type-b{
                            color: #fff;
                            background-color: $secondary;
                        }
                        .type-i{
                            color: #fff;
                            background-color: $warning;
                        }
                        .type-h{
                            color: #fff;
                            background-color: $alert;
                        }
                    }
                    .reserve-mark{
                        display: inline-block;
                        margin-right: 1px;
                        margin-bottom: 1px;
                        font-size: .588rem;
                        padding: 0.25rem 0.375rem;
                        letter-spacing: -.05rem;
                        color: #fff;
                        background: #9b59b6;
                        border-radius: 2rem;
                        line-height: 1;
                        white-space: nowrap;
                        cursor: default;
                        margin-left: 5px;
                    }
                    .portal-link{
                        overflow: hidden;
                        font-size: .888rem;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -.1em;
                        border: 1px solid #e73f72;
                        margin: 0 auto;
                        color: #e73f72;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        svg{
                            font-size: inherit
                        }
                        &:hover{
                            opacity: .7;
                        }
                    }
                    .approve-btn, .approve-cancel-btn{
                        margin-bottom: 0;
                        padding: 0.375rem 0.875rem;
                        font-size: .65rem;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        border: 1px solid transparent;
                        cursor:pointer;
                        svg{
                            font-size: inherit;
                            margin-right: 3px;
                        }
                        &:hover{
                            opacity: .7;
                        }
                    }
                    .approve-btn{
                        color: #fff!important;
                        background-color: #5684ff;
                    }
                    .approve-cancel-btn{
                        color: #686868!important;
                        background-color: #fff;
                        border-color: #ced2db;
                        transition: color .3s,border-color .3s;
                    }
                    .manage-btn{
                        background-color: #fff;
                        padding: 0.3rem 0.55rem;
                        font-size: .488rem;
                        color: #686868;
                        border: 1px solid #ced2db;
                        cursor:pointer;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 3px;
                        svg{
                            font-size: 0.7rem;
                            margin-right: 3px;  

                        }
                    }
                    .approve-btn{
                        background-color: $blue_btn;
                        color: #fff;
                    }

                }
                .panel-checkbox{
                    display: block;
                    position: relative;
                    margin: 0 0 1rem;
                    padding: 0;
                    font-size: 0;
                    font-weight: 400;
                    line-height: 1.8;
                    color: #0a0a0a;
                    margin-bottom: 0;
                    text-align: center;
                    &>span{
                        margin: 0;
                        padding: 0;
                    }
                    svg{
                        color: #cacfdb;
                        font-size: 18px;
                    }
                    .Mui-checked{
                        svg{
                            color: #747a8c;
                        }
                    }
                }
                .thead{
                    .panel-checkbox{
                        svg{
                            color: #ccdaff
                        }
                        .Mui-checked{
                            svg{
                                color: #5684ff
                            }
                        }
                    }
                }
                .text-left{
                    text-align: left;
                }
                .text-center{
                    text-align: center;
                }
            }
            .panel-footer{
                padding:1.25rem 0;
                border-top:1px solid rgba(0,0,0,.05);
                border-radius: 0 0 5px 5px;
                background-color: #fafbfc;
                .pagin-group{
                    display:block;
                    font-size:0;
                    text-align:left;
                    .pagin{
                        display:block;
                        @media screen and (min-width:40em){
                            display:inline-block;
                            width:50%;
                            vertical-align:middle;
                            &:last-child{
                                text-align: right;
                            }
                        }
                        .btn-group{
                            margin-bottom:0;
                            font-size:0;
                            @media screen and (min-width:40em){
                                text-align:right;
                            }
                            &::before{
                                display:table;
                                content:'';
                            }

                            .btn{
                                margin:0;
                                font-size:.9rem;
                                color:#686868;
                                background-color:#fff;
                                border: 1px solid #ced2db;
                                transition: color .3s, border-color .3s;
                                padding: 0.75rem 1.063rem;
                                letter-spacing:-.075em;
                                border-radius:5px;
                                vertical-align: middle;
                                text-align:center;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                color:#fff;
                                background-color:#5684ff;   
                                svg{
                                    margin-right: 2px;
                                    font-size: inherit;
                                }
                                @media screen and (max-width:39.9375em){
                                    width:100%;
                                    margin-left:0;
                                    margin-right:0;
                                }
                            }
                            .approve-btn, .remove-btn{
                                text-decoration: none;
                                vertical-align: middle;
                                transition:background-color .25s ease-out;
                                cursor: pointer;
                                
                            }
                            .approve-btn{
                                color:#fff;
                                background-color:#5684ff;   
                                &:hover, &:focus{
                                    color: #fff!important;
                                    background-color: #445990;
                                }
                                
                            }
                            .remove-btn{
                                background-color: $alert;
                                color: #fff;
                                &:hover, &:focus{
                                    background-color: #c4414c;
                                    color: #fff;
                                }
                            }
                        }
                        .ul{
                            margin-left:0;
                            margin-bottom:0;
                            @media screen and (max-width:39.9375em){
                                margin-bottom:1rem;
                                text-align:center
                            }
                            &::before{
                                display:table;
                                content:''
                            }
                            .li{
                                margin-right: 0.25rem;
                                border-radius:0;
                                font-size:.75rem;
                                line-height:1;
                                vertical-align: middle;
                                &:first-child{
                                    display :inline-block
                                }
                                &:last-child{
                                    display:inline-block;
                                    margin-right: 0;
                                }
                                .a{
                                    font-size:inherit;
                                    color:#838383;
                                    padding: 0.438rem 0.625rem;
                                    line-height:1;
                                    letter-spacing: -.025em;
                                    background:#fff;
                                    border:1px solid rgba(0,0,0,.08);
                                    border-radius: 3px;
                                    text-align: center;
                                    cursor:pointer;
                                    &:hover{
                                        // background-color: #f8f8fa;
                                        text-decoration:none;
                                    }
                                    .i{
                                        width:10px;
                                    }
                                }
                            .current{
                                background-color:#747A8c;
                                color:#fff;
                            }
    
                            }
                            .onenext{
                                @media screen and (max-width:39.9375em){
                                display:inline-block;}
                                @media screen and (min-width:40em){
                                    display:none;
                                }
                            }
                            .onebefore{
                                @media screen and (max-width:39.9375em){
                                    display:none;
                                }
                                @media screen and (min-width:40em){
                                    display:inline-block ;
                                }
                            }
                        }
                    }
                }
            }
        }
        .label-group{
            display: inline-flex;
            font-size: 0;
            text-align: left;
            margin-left: 5px;
            span{
                font-size: .55rem;
                font-weight: 400;
                font-style: normal;
                line-height: inherit;
                letter-spacing: -.05em;
                line-height: 1!important;
                color: #fff!important;
                display: inline-block;
                margin-right: 1px;
                background-color: #5684ff;
                border-radius: 2rem;
                padding: 0.33333rem 0.5rem;
            }
        }
        .header-level-group{
            display: flex;
            span{
                font-size: .35rem;
                font-weight: 400;
                font-style: normal;
                line-height: inherit;
                letter-spacing: -.05em;
                line-height: 1!important;
                color: #fff!important;
                display: inline-block;
                margin-right: 1px;
                background-color: #5684ff;
                border-radius: 2rem;
                padding: 0.25rem 0.375rem;
                &:not(:last-child){
                    margin-right: 3px;
                }
                &.secondary{
                    background: $secondary;
                    color: #fff;
                }
                &.warning{
                    background: $warning;
                    color: #fff;
                }
                &.alert{
                    background: $alert;
                    color: #fff;
                }
            }
        }
        
    }

    .dash-panel{
         &.blocks.import::after, &.blocks.import::before,.myhome-panel .blocks.graph::after,.myhome-panel .blocks.graph::before {
            content: '';
            display: block;
            overflow: hidden;
            position: absolute;
            border-radius: 50%
        }
        display: flex;
        position: relative;
        margin-bottom: 50px;
        font-size: 0;
        text-align: left;

        &>.item{
            position: relative;
            width: 18%;
            padding-left: 20px;
            :first-child{
                margin-top: 0px;
            }

            &.din-2{
                width: 36%;
                .blocks{
                    float: left;
                    width: calc(50% - 10px)
                }
                &>:nth-child(-n + 2){
                    margin-top: 0
                }
                &>:nth-child(2n){
                    margin-left: 10px
                }
                &>:nth-child(2n+1){
                    margin-right: 10px;
                    clear: both
                }

            }
        }
        &>:first-child{
            width: 28%;
            padding-left: 0;
        }
        .blocks{
            display: block;
            overflow: hidden;
            position: relative;
            height: 105px;
            margin-top: 20px;
            padding: 22px 28px;
            background-color: #fff;
            border-radius: 5px;
            text-align: left;
            text-decoration: none
        }
        .stat, .subject{
            display: block;
            overflow: hidden;
            position: relative;
            z-index: 5;
            text-overflow: ellipsis;
            white-space: nowrap
        }
        .stat{
            margin-bottom: .75rem;
            font-size: 2rem;
            font-weight: bolder;
            font-style: normal;
            line-height: 1;
            letter-spacing: -.05em;
            color: #212121
        }
        .subject{
            font-size: .875rem;
            font-weight: 400;
            line-height: 1.125;
            letter-spacing: -.05em;
            color: rgba(0,0,0,.6)
        }

        .blocks.all-articles {
            min-height: 100%;
            background: url(/assets/images/admin_allArticle.png) right bottom no-repeat #ffca00
        }

        .blocks.all-articles .stat,.blocks.all-articles .subject {
            color: #fff
        }

        & .blocks.all-articles .stat {
            font-size: 3.75rem;
            letter-spacing: -.075em
        }

        .blocks.all-articles .subject {
            font-size: 1.125rem;
            letter-spacing: -.1em
        }
        @media screen and (max-width: 79.9375em) {
            &{
                align-content: flex-start;
                flex-wrap: wrap
            }
        
            &>.item, &>.item.din-2 {
                width: 50%
            }
        
            &>.item, &>.item.din-2 .blocks~.blocks {
                margin-top: 20px
            }
        
            &>:nth-child(-n+2) {
                margin-top: 0
            }
        
            &>:nth-child(2n+1) {
                padding-left: 0
            }
        
            &>.item.din-2 .blocks {
                float: none;
                width: auto
            }
        
            &>.item.din-2>:nth-child(2n) {
                margin-left: 0
            }
        
            &>.item.din-2>:nth-child(2n+1) {
                margin-right: 0
            }
            .blocks.import {
                height:auto
            }
        }
        
        @media screen and (max-width: 39.9375em){
            &{
                flex-flow: column nowrap
            }
            .item, .item.din-2{
                width: auto;
                display: block;
                padding: 0;
                margin-top: 5px
            }
            .item:first-child{
                margin-top: 0
            }
            .blocks, .item.din-2 .blocks~.blocks {
                margin-top: 5px
            }
            .blocks {
                display: block;
                height: auto;
                padding: 15px 22px
            }
        
            .stat {
                font-size: 1.375rem
            }
        
            .subject {
                font-size: .75rem
            }
            .blocks.all-articles {
                display:block;
                width: auto;
                min-height: initial;
                margin: 0 0 10px;
                padding: 25px 30px;
                background-size: 125px auto
            }
        
            .blocks.all-articles .stat {
                font-size: 3.5rem
            }
        
            .blocks.all-articles .subject {
                font-size: 1rem
            }
            .blocks.import {
                display:block;
                width: auto!important
            }
        
            .blocks.import::before {
                content: normal;
                display: none
            }
        
            .blocks.import::after {
                width: 75%;
                padding-bottom: 75%
            }
        
            .blocks.rw {
                background-size: 60px auto
            }
        
            .blocks.aw {
                background-size: 70px auto
            }
        
            .blocks.import .stat {
                font-size: 1.875rem
            }
        
            .blocks.import .subject {
                font-size: .875rem
            }
        }
        .blocks.import {
            height: 230px;
            background-repeat: no-repeat;
            background-position: right bottom
        }
        
        .blocks.import::before {
            z-index: 1;
            left: -28%;
            bottom: -20%;
            width: 80%;
            height: 0;
            padding-bottom: 80%;
            background-color: rgba(0,0,0,.05)
        }
        
       .blocks.import::after {
            z-index: 2;
            left: -25%;
            top: -40%;
            width: 110%;
            height: 0;
            padding-bottom: 110%;
            background-color: rgba(0,0,0,.1)
        }
        
        .blocks.rw {
            background-image: url(/assets/images/admin-rw.svg);
            background-color: #223dbd;
            background-size: 72px auto
        }
        
        .blocks.rw::before {
            background-color: #2139ae
        }
        
        .blocks.rw::after {
            background-color: #2038aa
        }
        
        .blocks.aw {
            background-image: url(/assets/images/admin-aw.svg);
            background-color: #5684ff;
            background-size: 95px auto
        }
        
        .blocks.aw::before {
            background-color: #4e7efe
        }
        
        .blocks.aw::after {
            background-color: #4d7dfd
        }
        .blocks.import .stat,.blocks.import .subject {
            color: #fff
        }
        
        .blocks.import .stat {
            font-size: 3rem
        }
        
        .blocks.import .subject {
            font-size: 1.125rem;
            letter-spacing: -.1em
        }        
       .blocks.member {
            height: 167.5px;
            background-image: url(/assets/images/admin-mb.svg);
            background-color: #7a93c3;
            background-repeat: no-repeat;
            background-position: right bottom;
            background-size: 78px auto
        }
        
       .blocks.sleep {
            background-image: url(/assets/images/admin-mb-sleep.svg)
        }
        
       .blocks.member::after, & .blocks.member::before {
            content: '';
            display: block;
            overflow: hidden;
            position: absolute;
            border-radius: 50%
        }
        
       .blocks.member::before {
            z-index: 1;
            right: -12%;
            top: -30%;
            width: 50%;
            height: 0;
            padding-bottom: 50%;
            background-color: #708cc0
        }
        
       .blocks.member::after {
            z-index: 2;
            left: -20%;
            top: -45%;
            width: 92%;
            height: 0;
            padding-bottom: 92%;
            background-color: #6e8ac0
        }
        
       .blocks.member .stat, & .blocks.member .subject {
            color: #fff
        }
        
        @media screen and (max-width: 39.9375em) {
            .blocks.member {
                display:inline-block;
                width: calc(50% - 2.5px);
                height: auto;
                background-size: 50px auto
            }
        
            .blocks.sleep {
                margin-left: 5px
            }
        
            .blocks.member::before {
                content: normal;
                display: none
            }
        
            .blocks.member::after {
                width: 85%;
                padding-bottom: 85%
            }
        }
        
       .blocks.icon::before {
            content: '';
            position: absolute;
            z-index: 1;
            right: 28px;
            top: 50%;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%)
        }
        
       .blocks.trash::before {
            width: 32px;
            height: 42px;
            background-image: url(/assets/images/panel-trash.svg)
        }
        
       .blocks.restore::before {
            width: 42px;
            height: 37px;
            background-image: url(/assets/images/panel-restore.svg)
        }
        
       .blocks.reply::before {
            width: 44px;
            height: 35px;
            background-image: url(/assets/images/panel-reply.svg)
        }
        
       .blocks.bbs-list::before {
            width: 38px;
            height: 44px;
            background-image: url(/assets/images/panel-bbslist.svg)
        }
        
       .blocks.bbs-reply::before {
            width: 39px;
            height: 44px;
            background-image: url(/assets/images/panel-bbsreply.svg)
        }
        
        @media screen and (max-width: 39.9375em) {
            .blocks.icon::before {
                width:36px;
                height: 28px;
                background-position: 50%;
                background-size: auto 100%
            }
        }
    }
    .current-data{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 50px;
        &>.current-list{
            width:36%;
            padding-left:20px;
        }
        &>:first-child{
            width:100%;
            padding-left:0;
            padding-right:10px;
        }
        @media screen and (max-width:99.9375em){
                flex-flow: column wrap;
            
            &>.current-list{
                width:auto;
                padding:0;
            }
        }
        @media screen and (max-width: 79.9375em){
                display:block;
            &>.current-list{
                width:auto;
                padding-left:0;
                padding-right:0
            }
            &>.current-list~.current-list{
                margin-top:50px
            }
        }
        .current-list{
            @media screen and (max-width:63.9375em){
                .reply>.header>.text{
                    font-size:1.063rem;
                }
            }
            @media screen and (max-width:39.9375em){
                .reply>.header>.text{
                    font-size:1rem;
                }
            }
            .table{
                margin: 0;
                table-layout:fixed;
                border-collapse:collapse;
                width:100%;
                border-radius:0;
                .text-center{
                    text-align: center;
                }
                @media screen and (max-width:39.9375em){
                    margin:-0.75rem;
                    font-size:0;
                    display:block;
                    width:auto;
                    tbody{
                        display:block;
                        width:auto;
                        &>:first-child{
                            border-top:0;
                        }
                        tr{
                            padding:1rem 0.75rem;
                            border-top:1px solid(rgba(0,0,0,.05))
                        }
                        th+td{
                            padding-left:0
                        }
                        td{
                            display: inline-block;
                            padding-top: 0;
                            padding-bottom: 0;
                            font-size: .75rem;
                            line-height: 1.375; 
                        }
                    }
                    tr{
                        display:block;
                        width:auto;
                    }
                    td{
                        text-align:left;
                        display:block;
                        width:auto;
                    }
                }
                @media screen and (max-width:63.9375em){
                    tbody{
                        border-top:1px solid #f1f1f1;
                    }
                }
                .caption{
                    padding : 0.5rem 0.625rem 0.625rem;
                    font-weight: 700;
                    position:absolute !important;
                    width:1px;
                    height:1px;
                    overflow:hidden;
                    clip:rect(0,0,0,0);
                    padding-left:0;
                    padding-right:0;
                }
                thead{
                    background: 0 0;
                    color: #0a0a0a;
                    border-width:0;
                    border-bottom: 1px solid #838383;
                    border-collapse: collapse;
                    @media screen and (max-width: 39.9375em){
                        display:none;
                        tr{
                            display:block;
                            width:auto;
                            th{
                                text-align: left;
                                display:block;
                                width:auto;
                                
                            }
                        }
                    }
                    tr{
                        background: #fff;
                        th{
                            font-size:.9375rem;
                            letter-spacing: -.075em;
                            color: #838383;
                            padding: 1rem 0.625rem;
                            line-height: 1.375;
                            font-weight: 700;
                            text-align: left;
                        }
                        .tc{
                            text-align: center;
                        }
                    }
                }
                tbody{
                    border-width:0;
                    a{
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        letter-spacing: -.075em;
                        color: #3e3e3e;
                    }
                    tr{
                        border-width:0;
                        border-bottom:1px solid #f1f1f1;
                        background-color: #fff;
                        &:hover{
                            background-color: #f9f9f9;
                        }
                    }
                    th{
                        letter-spacing:-.075em;
                        text-align: left;
                        color:#3e3e3e;
                        font-size:.813rem;
                        font-weight:400;
                        padding: 0.875rem 0.625rem;
                        font-size:.813rem;
                        @media screen and (max-width:39.9375em){
                            padding:0;
                            margin-bottom:0.375rem;
                            font-size:.875rem;
                            line-height:1.375;
                            text-align:left;
                            display:block;
                            width:auto;
                        }
                       
                    }
                    a{
                        .span{
                            margin-right:0.3125rem;
                            display: inline-block;
                            font-size: 0;
                            text-align: left;
                                &::after{
                                    content:'';
                                    display:table;
                                    clear:both;
                                }
                            .span-label{
                                display: inline-block;
                                margin-right: 1px;
                                margin-bottom: 1px;
                                font-size: .688rem;
                                padding: 0.25rem 0.375rem;
                                letter-spacing: -.05rem;
                                color:#fff;
                                background:#9b59b6;
                                border-radius:2rem;
                                line-height:1;
                                white-space:nowrap;
                                cursor:default;
                                &.level{
                                    background-color: #5684ff;
                                }
                            }
                        }
                        i{
                            span{
                                position: absolute;
                                width: 1px;
                                height: 1px;
                                overflow: hidden;
                                clip: rect(0,0,0,0);
                            }
                        }
                    }
                    td{
                        letter-spacing: -.025em;
                        color:#868686;
                        text-align:center;
                        font-size:.813rem;
                        font-weight:400;
                        // padding-right:0.625rem;
                        .span-td{
                            @media screen and (max-width:39.9375em){
                                display:none;
                            }
                        }
                        .span{
                            margin:0;
                            .span-label{
                                background-color: #13c0cb;
                            }

                        }
                    }
                }
            }
            .list{
                position:relative;
                margin-bottom:3.125rem;
                display:block;
                ul{
                    margin-bottom:1.375rem;
                    background: 0 0;
                    border: 0;
                    margin-left:0;
                    list-style-type:none;
                    list-style-position: outside;
                    line-height: 1.6;
                    &>:first-child>a{
                        padding-left:0
                    }
                    &::before{
                        display:table;
                        content: '';
                    }
                    &::after{
                        clear:both;
                        display:table;
                        content:''
                    }
                    @media screen and (max-width:39.9375em){
                        font-size:0
                    }
                    .li{
                        float: left;
                        cursor:pointer;
                        font-size: inherit;
                        &>a{
                            display:block;
                            line-height:1;
                            padding: 0 1rem;
                            font-size:0;
                            color:#838383;
                        }
                        &>a[aria-selected=true] {
                            background: 0 0;
                            .text{
                                font-weight: bolder;
                                color: #212121;
                            }
                            .circlenum{
                                background-color: #f66874;
                            }
                        }
                        .text{
                            font-size:1.375rem;
                            font-weight:400;
                            line-height:1.125;
                            letter-spacing: -.125em;
                            display:inline-block;
                            vertical-align:middle;
                        }
                        .circlenum{
                            min-width:21px;
                            padding: 0;
                            margin-left: 0.5rem;
                            font-size: .688rem;
                            font-style: normal;
                            line-height: 21px;
                            color: #fff;
                            background: #bababa;
                            border-radius: 2rem;
                            display:inline-block;
                            vertical-align: middle;
                            text-align:center;
                        }
                        @media screen and (max-width:63.9375em){
                            .text{
                                font-size:1.125rem;
                            }
                        }
                        @media screen and (max-width:39.9375em){
                            float:none;
                            text-align:center;
                            &:first-child:nth-last-child(4){
                            display:inline-block;
                            width:25%;
                            }
                            &:first-child:nth-last-child(4):first-child:nth-last-child(4)~.li{
                                display:inline-block;
                                width:25%;
                            }
                            &>a{
                                padding: 0 0.625rem
                            }
                            .text{
                                display: block;
                                overflow: hidden;
                                margin-bottom: 0.5rem;
                                font-size: 1rem;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .circlenum{
                                min-width: 18px;
                                font-size: .625rem;
                                line-height: 18px;
                            }
                        }
                        a{
                            }
                        }
                    }
                    .myarticle-list-wrap{
                        background: #fff;
                        border: 0;
                        border-radius:5px;
                        color:#0a0a0a;
                        transition: all .5s ease;
                        .myarticle-list{
                            height:778.234px;
                            display:none;
                            padding:1rem;
                            &[aria-hidden=false]{
                                display:block;
                            }
                            @media screen and (max-width: 39.9375em){
                                height:1123.78px;
                                padding: 0.75rem;
                            }
                            

                        }
                    }
                }
                .reply{
                    display:block;
                    text-align:left;
                    margin-bottom:2.5rem;
                    .section{
                        background-color: #fff;
                        border-radius: 5px;
                        overflow: hidden;
                        padding:25px;
                        display:block;
                    @media screen and (max-width:39.9375em){
                            padding:12px;
                    }
                    }
                    .section{
                        .news-reply{
                            display:block;
                            font-size:0;
                            text-align:left;
                            &::after{
                                content:'';
                                display:table;
                                clear:both;
                            }
                            .a{
                                &:first-child{
                                    margin-top:0;
                                    padding-top: 0;
                                    border-top: 0;
                                }
                                &:hover{
                                        .reply-span{color:#212121;
                                        text-decoration:underline;}
                                }
                            }
                            &>.a{
                                display: block;
                                padding-top: 0.875rem;
                                margin-top: 0.875rem;
                                border-top: 1px solid rgba(0,0,0,.05);
                                text-decoration: none;
                            }
                            .reply-span{
                                margin-bottom: 0.375rem;
                                font-size: .813rem;
                                line-height: 1.375;
                                letter-spacing: -.075em;
                                color: #838383;
                                transition: color .3s ease-in-out;
                                display:block;
                                overflow:hidden;
                                text-overflow:ellipsis;
                                white-space:nowrap;
                                .em{
                                    font-size: .75rem;
                                    font-weight: 300;
                                    font-style: normal;
                                    line-height: 1.125;
                                    letter-spacing: -.025em;
                                    color: #b4b4b4;
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    .date{
                                        @media screen and (max-width:39.9375em){
                                            display:none
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .header{
                        text-decoration:none;
                        overflow:hidden;
                        margin-bottom:1.375rem;
                        display:block;
                        &::after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                        .i{
                            float:right;
                            font-size:.875rem;
                            line-height:20px;
                        }
                        .em{
                            width: auto;
                            min-width: 18px;
                            margin-left: 0.375rem;
                            padding: 0;
                            font-size: .625rem;
                            font-weight: 400;
                            font-style: normal;
                            line-height: 18px;
                            letter-spacing: -.0125em;
                            vertical-align: middle;
                            color:#fff;
                            background:#ffc600;
                            display:inline-block;
                            text-align:center;
                            border-radius: 2rem;
                        }
                        .text{
                            display:inline-block;
                            vertical-align: middle;
                            font-weight:bolder;
                            line-height:1.125;
                            letter-spacing: -.125em;
                            font-size: 1.125rem;
                        }
                    }
                      
                }
        }
    }

    

    .write-form .accordion {
        margin: 0;
        text-align: left;
        text-decoration: none;
        .accordion-item{
            list-style-type: none;
        }
    }
    .write-form .accordion-item{
        .accordion-content{
            display: block;
            visibility: hidden;
            max-height: 0;
            transition: all .5s;
            overflow: hidden;
            padding: 0 !important;
        }
        &.active{
            .accordion-content{
                visibility: visible;
                max-height: 70px;
            }
        }
    }
    
    .write-form .accordion.gray {
        margin: 1.25rem 0;
        background-color: #fafbfc;
        border-top: 1px solid rgba(0,0,0,.08)
    }
    
    .write-form .accordion .accordion-title {
        position: relative;
        display: block;
        padding: .625rem;
        font-size: .75rem;
        letter-spacing: -.05em;
        border: 0;
        text-align: center;
        text-decoration: none;
        transition: color .25s;
        cursor: pointer;
    }
    
    .write-form .accordion .accordion-title:focus {
        background: 0 0
    }
    
    .write-form .accordion .accordion-title:hover,.write-form .accordion .accordion-title[aria-expanded=true] {
        color: #e6336d;
        background: 0 0
    }
    
    #admin-aside .option-popup-item input,#edit-cropper .controls .cropRatioRadio input[type=radio],.write-form .accordion .accordion-title::before {
        display: none
    }
    
    .write-form .accordion .accordion-title::after {
        content: '+';
        display: inline-block;
        margin-left: .25rem
    }
    
    .write-form .accordion .active>.accordion-title::after {
        content: '-'
    }
    
    #admin-wrap.newsroom .write-form .accordion .accordion-title:hover,#admin-wrap.newsroom .write-form .accordion .accordion-title[aria-expanded=true] {
        color: #5684ff
    }
    
    .write-form .accordion .accordion-content {
        padding: .625rem .9375rem;
        background: 0 0;
        border: 0
    }
    
    .write-form .accordion.gray .accordion-content {
        border-bottom: 1px solid rgba(0,0,0,.08)
    }

    .write-form .accordion.gray .accordion-content {
        border-bottom: 1px solid rgba(0,0,0,.08)
    }

    @media screen and (min-width: 80em) {
        .write-form .accordion.gray .accordion-item {
            position:relative
        }

        .write-form .accordion.gray .accordion-title {
            position: absolute;
            z-index: 10;
            right: -35px;
            top: -16px;
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 0;
            font: 0/0 a;
            color: transparent;
            background-color: #fff;
            border: 1px solid #000;
            border-radius: 3px;
            text-align: center
        }

        .write-form .accordion.gray .accordion-title::after {
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            margin: 0;
            padding: 0;
            font-family: "fontello";
            font-style: normal;
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1;
            color: #999;
            transform: translate(-50%,-50%)
        }

        .write-form .accordion.gray .accordion-item.is-active .accordion-title::after,.write-form .accordion.gray .accordion-title[aria-selected=true]::after {
            content: '+'
        }
    }

    @media screen and (max-width: 63.9375em) {
        .write-form .accordion .accordion-title {
            padding-top:1rem;
            padding-bottom: 1rem
        }
    }
    .wt-forms{
        margin: 0.625rem 0;
        font-size: 0;
    }
    .wt-forms-label{
        width: 130px;
        font-size: .9375rem;
        font-weight: bolder;
        line-height: 1em;
        letter-spacing: -.075em;
        display: inline-block;
        padding: 0 0.75rem;
        vertical-align: middle;
        @media screen and (max-width: 39.9375em){
            padding-left: 0;
            padding-right: 0;
        }
        @media screen and (max-width: 63.9375em){
            padding-bottom: 0.625rem;
            vertical-align: inherit;
            display: block;
            width: auto;
        }
    }
    .wt-forms-content, .new-writer .wt-forms-lable{
        display: inline-flex;
        padding: 0 0.75rem;
        vertical-align: middle;
    }
    .wt-forms-lable~.wt-forms-content{
        width: calc(100% - 130px);
    }
    .wt-forms-content{
        width: calc(100% - 130px);
        &>:not(:last-child){
            margin-right: 1%;
        }
    }
    .wt-date-box{
        width: 49%;
        display: flex;
        .ant-picker{
            border-radius: 0 5px 5px 0;
        }
        .ant-picker-input > input{
            text-align: left;
        }
        &>:first-child{
            border-radius: 5px 0 0 5px;                        
            border-right: 0px;
        }
        &>:last-child{
            border-radius: 0 5px 5px 0;
            display: flex;
            flex: 1;
        }
    }
    .wt-date-icon{
        padding: 0 1rem;
        border: 1px solid #cacaca;
        background: #e6e6e6;
        color: #0a0a0a;
        text-align: center;
        white-space: nowrap;
        width: 1%;
        border-radius: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            font-size: 13px;
            color: #999;
        }
    }
    @media screen and (max-width: 63.9375em) {
        .wt-forms:not(.lines) {
            margin:1.25rem 0
        }
    
       .wt-forms-content, .wt-forms-lable {
            display: block;
            width: auto
        }
    
        .wt-forms-lable {
            padding-bottom: .625rem;
            vertical-align: inherit
        }
    
        .wt-forms-lable~.wt-forms-content {
            width: auto
        }
    
        .wt-forms.save .wt-forms-content {
            padding-left: 1.25rem
        }
        .wt-date-box{
            width: 100%;
            &:first-child{
                margin-bottom: 1%;
            }
        }

    }
    
    @media screen and (max-width: 39.9375em) {
       .wt-forms-content, .wt-forms-lable {
            padding-left:0;
            padding-right: 0
        }
    
        .wt-forms.save .wt-forms-content {
            padding-left: 0
        }
    }
    
}
.search-detail{
    .search-tab{
        display: inline-block;
    }
    .tabs{
        margin: 0;
        border:0;
        background: #fff;
        list-style-type: none;
        .tabs-title{
            &.active{
                font-weight: 700;
                &>a{
                    color: #fff;
                }
                background-color: #727d92;
            }
            &:first-child{
                border-right: none;
            }
            &>a{
                font-size: 1rem;
                padding-top: 0.9375rem;
                padding-bottom: 0.9375rem;
                color: #727d92;
                line-height: 1em;
                letter-spacing: -.05em;
                border: 1px solid rgba(0,0,0,.08);
                text-decoration: none;
                display: block;
                padding: 0.8rem 1.2rem;
            }
        }
        li{
            font-size: inherit;
        }
    }
    .tabs-content{
        border:0;
        border-top: 0;
        background: #fff;
        color: #0a0a0a;
        transition: all .5s ease;
    }
    .tabs-panel{
        padding: 1rem;
        padding-left: 0;
        padding-right: 0;
        display: block;
    }
    .tabs-row{
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 0.3rem;
        &:first-of-type{
            margin-bottom: 1rem;
        }
    }
    .tabs-label{
        display: inline-block;
        margin: 0;
        font-size: .875rem;
        font-weight: 600;
        color: #0a0a0a;
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
        width: 15%;
        @media screen and (max-width: 39.9375em) {
            display: block;
            width: 100%;
            margin-bottom: 0.5rem;
            padding-left: 0;
            padding-right: 0;
        }
    }
    .tabs-item{
        display: inline-block;
        flex: 1;
    }
    .keyword-group{
        width: 85%;
        display: inline-flex;
        @media screen and (max-width: 39.9375em) {
            display: inline-block;
            width: 100%;
        }
        &>div{
            width: 9%;
        }
        &>div{
            &:first-child, &:last-child{
                width: 46%;

            }
        }
        &>div{
            @media screen and (max-width: 39.9375em) {
                width: 100% !important;
            }
        }
    }
    .tabs-radio-group{
        display: block;
        z-index: 1;
        position: relative; 

        &>label{
            display: inline-block;
            position: relative;
            padding: 0 1.125rem;
            margin: 0 0 1px 5px;
            font-size: .775rem;
            color: #0a0a0a;
            font-style: normal;
            line-height: 24px;
            letter-spacing: -.05em;
            vertical-align: top;
            transition: color .3s ease-in-out,background-color .3s ease-in-out;
            text-align: left;
            cursor: pointer;
            &:first-child{
                margin-left: 0px;
            }
            @media screen and (max-width: 39.9375em) {
                &:first-child{
                    margin-left: 5px;
                }
            }
            &::before{
                content: '';
                display: block;
                overflow: hidden;
                position: absolute;
                z-index: -1;
                left: 0;
                top: 50%;
                width: 15px;
                height: 15px;
                background-color: #fff;
                border: 2px solid #ccdaff;
                border-radius: 2rem;
                vertical-align: middle;
                transform: translateY(-50%);
                transition: all .3s ease-in-out;
            }
            &.checked{
                color: #fff;
                &::before{
                    content: '';
                    display: block;
                    overflow: hidden;
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    top: 50%;
                    width: 100%;
                    height: 100%;
                    background-color: #5684ff;
                    border: transparent;
                    border-radius: 2rem;
                    vertical-align: middle;
                    transform: translateY(-50%);
                    transition: all .3s ease-in-out;
                }
            }
        }
        .sc-train{
            font-size: 0.6rem;
            border-width: 1px 0;
            border-style: solid;
            border-color: rgba(0,0,0,.1);
            margin: 0;
            color: rgba(0,0,0,.5);
            &:hover{
                color: rgba(0,0,0,.95);
            }
            &:first-of-type{
                border-left-width: 1px;
                border-radius: 2rem 0 0 2rem;
            }
            &:last-of-type{
                border-right-width: 1px;
                border-radius: 0 2rem 2rem 0;
            }
            &::before{
                border:0;
            }
            &.checked{
                &::before{
                    border-radius: 2rem;
                    background-color: #747a8c;

                }
            }
        }
    }
    .tabs-input{
        border: 1px solid #ced2db;
        border-radius: 5px;
        input{
            font-size: .875em;
            padding: 0.5rem;
            color: #0a0a0a;
        }
    }
    .tabs-submit{
        display: block;
        text-align: right;
    }
    .tabs-line{
        max-width: 100%;
        border-bottom: 1px solid rgba(0,0,0,.1);
        height: 0;
        margin: 1.25rem auto;
        border-top: 0;
        border-right: 0;
        border-left: 0;
    }
    .tabs-date{
        display: inline-flex;
        width: 210px;
        @media screen and (max-width: 39.9375em) {
            width: 100% !important;
        }
        .ant-picker{
            padding: 0px;
        }
        .ant-picker-suffix{
            padding-right: 15px;
        }
        input{
            font-size: .875em;
            padding: 0.5rem;
            color: #0a0a0a;
        }
        .nv-newdatepicker{
            width: 100%;
            
        }
        &>span{
            font-size: .75rem;
            color: rgba(0,0,0,.6);
            padding: 0 1.5rem;
            border: 1px solid #cacaca;
            background: #e6e6e6;
            text-align: center;
            white-space: nowrap;
            width: 1%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 5px 5px 0;
            border-left: 0;
        }
    }
    .tabs-sort{
        width: 210px;
        @media screen and (max-width: 39.9375em) {
            width: 100% !important;
        }
    }

    .btn{
        margin:0;
        font-size:.9rem;
        color:#686868;
        background-color:#fff;
        border: 1px solid #ced2db;
        transition: color .3s, border-color .3s;
        padding: 0.75rem 1.063rem;
        letter-spacing:-.075em;
        border-radius:5px;
        vertical-align: middle;
        text-align:center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color:#fff;
        background-color:#5684ff;   
        cursor: pointer;
        svg{
            margin-right: 2px;
            font-size: inherit;
        }
        @media screen and (max-width:39.9375em){
            width:100%;
            margin-left:0;
            margin-right:0;
        }
    }
    .filter-date-btn{
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        margin-right: 1px;
        margin-bottom: 1px;
        border: 1px solid transparent;
        border-radius: 0;
        transition: background-color .25s ease-out,color .25s ease-out;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        color: #fff!important;
        background-color: #5684ff;
        padding: 0.75rem 1.063rem;
        font-size: .75rem;
        border-radius: 3px;
        &:last-child{
            margin-right: 0px;
        }
    }
}
.news-list-type-header{
    display: flex;
    .menu{
        display: table;
        width: 100%;
        table-layout: fixed;
        li{
            text-decoration: none;
            text-align: center;
            color: #303740;
            letter-spacing: -.05em;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.1);
            border-left: none;                
            list-style-type: none;
            padding: 0.7rem 1rem;
            display: table-cell;
            cursor: pointer;
            &:not(.active){
                &:hover{
                    background-color: rgba(0,0,0,.03);
                }
            }
        }
        .active{
            font-weight: 700;
            color: #fff;
            background-color: #303740;
        }
    }

}