@import 'styles/_mixin';
.combinationlist-body{
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    position:relative;
    line-height: 1.5;
    color: #212529;
    width:100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    .nav-header-wrap{
        border-bottom: 1px solid rgba(0,0,0,.15);
        position:relative;
        justify-content: center;
        width:100%;
        .main-sidebar{
            position:fixed;
            border-right:1px solid rgba(0,0,0,.15);
            transition: all .3s ease-in-out;
            left:0;
            top:1px;
            bottom:0px;
            width:240px;
            padding-top:35px;
            background-color: #fff;
            z-index:20;

            .combination-divide-line{
                margin:23px 0 5px 25px;
                height:1px;
                width:150px;
                background-color: black;
            }
        }
        .nav-header{
            margin:0 20px;
            @include tablet{
                margin: 0 40px;
            }
            @include xl{
                margin: 0 90px;
            }
            height: 100px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            .nav-img-menu{
                display:flex;
                flex-direction: row;
                .nav-img{
                height: 100%;
                display: flex;
                align-items: center;
                .nav-brand{
                    padding-top: 0.3125rem;
                    padding-bottom: 0.3125rem;
                    margin-right: 1rem;
                    font-size: 1.25rem;
                    text-decoration: none;
                    white-space: nowrap;
                    cursor:pointer;
                    .img{
                        object-fit:contain;
                        vertical-align:middle;
                        width:140px;
                        @include desktop{
                            width:200px;
                        }
                    }
                }
            }
            
        }
            .nav-logout{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size:14px;
                .logout-style{
                    color: #507499;
                    text-decoration-line: none;
                }
                .logout-display{
                    display:none;
                    @include tablet{
                        display:flex;
                    }
                }
            }
        }
        .nav-menu{
            display: flex;
            margin-bottom:20px;
            padding: 0 25px 0 32px;
            align-items: center;
            justify-content: center;
            .navbar-items{
                display:flex;
                margin-bottom: unset;
                justify-content: space-between;
                width:100%;
                .li{
                    list-style:none;
                    border:1px solid #555555;
                    color:#212529;
                    .a{
                        font-weight:400;
                        color:unset;
                        font-size:13px;
                        display:block;
                        padding:0.2rem 1rem;
                        text-decoration: none;
                        transition:color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
                    }
                }
                    .clicked{
                        color:white;
                        background-color:#6695CE;
                        border:1px solid #6695CE;
                    }
            }
        }
    }
    .main{
        background-color: #fafafa;
        display:flex;
        position:relative;
        justify-content: center;
        
        .main-contents{
            padding: 0px 5px 40px 245px;
            margin: 0 auto;
            text-align: center;
            height: auto;
            min-height: 100vh;
            width:100%;
            display:flex;
            justify-content: center;
            // margin:0 20px;
            // @include tablet{
            //     margin: 0 40px;
            // }
            // @include xl{
            //     margin: 0 90px;
            // }
            transition: all .3s ;
            .upper-table-dashboard{
                display:none;
                width:100%;
                justify-content: space-between;
                @include tablet{
                    display:flex;
                }
                .upper-table-contents{
                    display:flex;
                    justify-content: space-between;
                    font-size:12px;
                    margin-left:8px;
                    p{
                        margin:unset;
                    }
                }
            }
            .table-dashboard{
                width: 100%;
                border-collapse: separate;
                border-spacing: 10px;
                table-layout:fixed;
                word-wrap:break-word;
                caption-side:bottom;
                margin:10px 0 20px 0;
                .tbody{
                    border-color: inherit;
                    border-style: solid;
                    border-width: 0;
                    .tr-3{
                        display:none;
                        @include desktop{
                            display:table-row;
                        }
                    }
                    .tr-1{
                        @include desktop{
                            display:none;
                        }
                    }
                    .td{
                        height: 100px;
                        filter: drop-shadow(0px 2px 10px rgba(148, 148, 148, 0.25));
                        background-color:#fff;
                        .my-item{
                            width: 100%;
                            height: 100%;
                            padding: 5px 20px 5px 20px;
                            .my-header{
                                height: 40%;
                                color: #474747;
                                font-size: 16px;
                                font-weight: 600;
                                display: flex;
                                text-align: left;
                            }
                            .my-body{
                                height: 60%;
                                background-color: white;
                                color: black;
                                font-size: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                padding-right: 10px;
                                p{
                                    margin-left: 3px;
                                    font-size: 16px;
                                    display: inline-block;
                                    margin-bottom: 0;
                                }
                                div{
                                    color: #121212;
                                    font-size: 25px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .display-td{
                        display:row-table;
                        @include tablet{
                            display:none;
                        }
                    }
                }
            }
            .tableBox{
                background-color: #ffffff;
                padding: 30px 20px;
                max-width:1480px;
                width:100%;
                height: 100%;
                border-collapse: collapse;
                box-shadow: 0 0 10px 1px rgba(148, 148, 148, 0.25);
                .title{
                    font-size: 20px;
                    color: #121212;
                    font-weight: bold;
                    text-align: left;
                    display:flex;
                    // align-items: flex-end;
                    margin-bottom: 15px;
                    .p{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 32px;
                        margin-bottom: 0;
                        margin-left: 10px;
                    }
                    .searchbox{
                        height: 30px;
                        display:flex;
                        justify-content: space-evenly;
                        font-size: 16px;
                        .select{
                            text-transform: none;
                            margin:0;
                            font-family: inherit;
                            font-size:inherit;
                            line-height:inherit;
                            overflow:visible;
                            word-wrap:normal;
                            border:1px solid #dcdcdc;
                            height:100%;
                            width:35%;
                        }
                        .input{
                            width:40%;
                            height:100%;
                            margin: 0;
                            font-family: inherit;
                            font-size: inherit;
                            line-height: inherit;
                            padding: 1px 2px;
                        }
                        .btn{
                            cursor:pointer;
                            height:100%;
                            margin: 0;
                            padding:1px 6px;
                            font-family: inherit;
                            font-size: inherit;
                            line-height: inherit;
                        }
                    }
                }
                .table-wrap{
                    width:100%;
                    overflow-x:auto;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                .table-border{
                    // border:1px solid #929292;
                }
                .table{
                    caption-side:bottom;
                    border-collapse:collapse;
                    table-layout: fixed;
                    word-wrap:break-word;
                    width:100%;
                    color:#212529;
                    vertical-align:top;
                    border-color:#dee2e6;
                    .full-width{
                        flex: 1
                    }
                    .thead-tr{
                            border-bottom: 1px solid #929292;
                            .thead-th{
                                padding:6.25px 6.25px;
                                text-align: center;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                background-color: #F2F3F4;
                                color: #474747;
                                vertical-align: middle;
                            }
                        }
                    .tbody{
                        border-color: inherit;
                        border-style: solid;
                        border-width: 0;
                        vertical-align: inherit;
                        .tr-wrap{
                            display:contents;
                            position:relative;
                        }
                        .tr-hidden{
                            position:absolute;
                            top:0;
                            left:0;
                            height:0;
                            overflow:hidden;
                            transition:height .2s;
                        }
                        .tr{
                            border-bottom: 1px solid #929292;
                            cursor:pointer;
                            background-color: #fff;
                            &:hover{
                                background-color:rgb(236,236,236)
                            }
                            // &:last-child{
                            //     border:0;
                            // }
                            .td{
                                border-color: inherit;
                                border-style: solid;
                                border-width: 0;
                                padding: 6.25px 6.25px;
                                text-align: center;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                            }
                        }
                        .hidden-tr{
                            transform:translateY(-100%);
                            transition:all .2s;
                        }
                        .tr-fold{
                            transform:translateY(0);
                        }
                        .tr-ghost{
                            position:relative;
                            height:0;
                            transition:all .2s;
                        }
                        .tr-open{
                            height:112.5px;
                        }
                    }
                }
                .combination-pagination-ul{
                    display: flex;
                    padding-left: 0;
                    list-style: none;
                    margin-top:0;
                    position:relative;
                    justify-content: center;
                    margin-bottom:0;
                    .page-left{
                        pointer-events:none;
                        background-color:#fff;
                        border-color:#dee2e6;
                        color:#858585;
                        margin: 0 5px 0 5px;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        padding: 0 ;
                        text-align: center ;
                        line-height: 32px;
                        position: relative;
                        display: block;
                        text-decoration: none;
                        background-color: #fff;
                        border: 1px solid #dee2e6;
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    }
                    .active{
                        background-color: #858585;
                        color: #ffffff ;
                        border: none
                    }
                    .btn{
                        position:absolute;
                        right:0;
                        padding: 0.2rem 0.8rem;
                        font-size:14px;
                        border:none;
                        background-color:#507499;
                        color:#fff;
                        cursor:pointer;
                        border-radius:0.25rem;
                    }
                }
            }
            .padding-info{
                padding: 30px 50px 120px 49px;
            }
        }
        .combination-list-style{
            display:flex;
            align-items: center;
            justify-content: center;
            .tableBox-container{
                display:flex;
                justify-content: center;
                padding: 0 10px;
                flex-direction: column;
                .table-container-background{
                    width:100%;
                    align-items:center;
                    height:100%;
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    .table-container{
                        display:flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                }
            }
        }
    }
    .copyright-footer{
        width: 100%;
        z-index:11;
        height: 100px;
        background-color: gray;
        position: relative;
        bottom: 0;
        .span{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            color: white;
        }
    }
    
}
.react-datepicker__input-container{
    display:flex !important;
    justify-content: center !important;
    .example-custom-input{
        font-size:14px !important;    
        padding: 0px 60px;
        font-size: 14px !important;
        background-color: rgb(224,233,245);
        border: unset;
        border-radius: 5px;
    }
}
.combination-cogp-modal{
    &__header{
        width: 100%;
        height: 70px;
        display: flex;
        border-bottom: 1px solid #dee2e6;
        justify-content: space-between;
    }
    &__title{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 20px;
        color: #212529;
    }
    &__table{
        display: flex;
        border: none !important;
        padding: 16px;
        justify-content: center;
        ul{
            margin-bottom: 0 !important;

            list-style: none;
            width: 200px;
            &:first-child{
                width: 100px;
            }
            &:hover{
                .cogp-remove-btn{
                    visibility: visible;
                }
            }
        }
        li{
            position:relative;
        }
        fieldset{
            border: none;
        }
        .cogp-remove-btn{
            position: absolute;
            top: -8px;
            right: -6px;
            font-size: 18px;
            color: rgb(214, 101, 85);
            visibility: hidden;
            cursor: pointer;
        }
    }
    &__add-box{
        border: 1px solid #dee2e6;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            cursor: pointer;
            color: rgba(0,0,0,0.5);
        }
    }
}
.combination-newcompany-modal{
    padding:0 25px;
    &__header{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
    }
    &__title{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 20px;
        color: #212529;
    }
    &__table{
        display: flex;
        border: none !important;
        padding: 16px;
        justify-content: center;
        flex-direction: column;
        ul{
            display:  flex;
            margin-bottom: 0 !important;
            list-style: none;
            width: 900px;
            &:hover{
                .cogp-remove-btn{
                    visibility: visible;
                }
            }
        }
       
        li{
            position:relative;
            display: flex;
            flex: 1;
            .overview-th, .overview-td{
                border: none !important;
                box-shadow: 1px 0 0 0 #ccc, 0 1px 0 0 #ccc, 1px 1px 0 0 #ccc, 1px 0 0 0 #ccc inset, 0 1px 0 0 #ccc inset;
            }
            .overview-th{
                width: 150px;
            }
            .overview-td{
                width: 150px;
            }
            &.full{
                .overview-td{
                    flex: 1
                }
            }
        }
        fieldset{
            border: none;
        }
        .cogp-remove-btn{
            position: absolute;
            top: -8px;
            right: -6px;
            font-size: 18px;
            color: rgb(214, 101, 85);
            visibility: hidden;
            cursor: pointer;
        }
    }
}
.combination-thead-box{
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}
.combination-section-container{
    position: relative;
    display: flex;
    flex-direction: column;
    &.read{
        margin-top: 20px
    }
    .combination-distribution-section{
        display:flex;
        width:48%;
        flex-direction: column;
        .overview-td{
            text-align: right;
        }
    }
}
.combination-file-container{
    display:flex;
    align-items: center;
    height: 100%;
    .file-clip-icon{
        transform: rotate(140deg);
        font-size: 17px;
        margin: 0;
        padding: 0;
        margin-right: 5px;
    }
}
.combination-file-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    padding: 3px 0;
    padding-left: 10px;
    &>span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        background-color: #efefef;
        height: 25px;
        border-radius: 10px;
        padding: 0 10px;
        line-height: 1px;
    }
    .file-remove-icon{
        font-size: 14px;
        margin-left: 10px;
        color: rgba(0,0,0,0.5);
        cursor: pointer;
    }
    .file-download{
        cursor: pointer;
        &:hover{
            text-decoration:underline;
        }
    }
}
.combination-file-box{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    height: 80%;
    &__text{
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 0;
        border: 1px dashed #c4c4c4;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 219px;
       
    }
}
.combination-side-list-container{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    &.open{
        .combination-side-list__section{
            max-height: 185px;
        }
        .combination-side-list__title{
            color: #0671E0;
        }
        .combination-side-list__arrow{
            color: #0671E0;
            rotate: 90deg
        }
    }
}
.combination-side-list{
    display:flex;
    cursor:pointer;
    padding:0 25px 0 32px;
    height:50px;
    font-weight:500;
    font-size:12px;
    z-index:20;
    justify-content:space-between;
    align-items: center;
    .div{
        display:flex;
        align-items: center;
        .img{
            width:13px;
            height:13px;
            margin-right:10px;
        }
    }

    &__title{

    }
    &__arrow{
        transition: all .3s !important;
        width: 12px !important;
        height: 12px !important;
    }
    &__section{
        display:flex;
        flex-direction: column;
        font-size:10px;
        transition: all .3s ;
        font-weight:500;
        background-color:rgba(102,149,206,0.2);
        max-height: 0;
        transition: all .3s;
        overflow: hidden;
        div{
            padding:6px 0;
            padding-left:32px;
            font-size: 11px;
            cursor:pointer;
            &:hover{
                background-color: cornflowerblue;
            }
        }
    }
}
.pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    width: 100%;
    position: relative;
    @media screen and (max-width: 1550px) {
      margin-top: 1rem;
    }
  }
  

  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    /* border: 1px solid #e8e8e8;  */
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    transition: all .3s;
  
  }
  
  ul.pagination li {
    border-radius: 50px;
    margin: 3px;
    padding-top: 2px;
  }
  
  ul.pagination li a {
    text-decoration: none;
    color: #222222;
    font-size: 1rem;
    align-self: center;
  }
  
  ul.pagination li.active a {
    color: #121212;
  }
  
  ul.pagination li.active {
    border: 1px solid #121212;
  }
  
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: #121212;
    font-weight: 800;
  }
  s .page-selection {
    width: 48px;
    height: 30px;
    color: #222222;
  }
  #react-paginate ul {
    list-style: none;
    padding: 0;
    display: inline-block;
    padding-left: 0;
    border: 1px solid #d0d0d0;
    border-radius: 3px;
    margin-bottom: 0;
  }
  
  
  .pagination-ul li {
    align-items: baseline;
    border-right: 1px solid #d0d0d0;
    color: #2e445f;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: all .3s;
    width: 27px;
    &:last-child{
      border-right: 0;
    }
    &:hover{
      background-color: rgba(#6695CE, 0.1);
    }
  }
  
  .pagination-ul li a {
    color: #000;
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    height: 100%;
    outline: none;
    padding: 8px 4px;
    width: 100%;
  }
  
  .currentPage {
    background: #6695CE;
    outline: none;
  }
  
  .currentPage a {
    color: white !important;
  }
  .col-checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child{
        margin-right: 30px;
    }
  }
  .cb-company-investment-empty{
    padding: 10px 0 100px 13px;
    color: rgba(0,0,0,0.5);
    font-size: 16px;
    text-align: left;
  }