.gallery {
  width: 100%;
}

.gallery-container {
  align-items: center;
  display: flex;
  height: 1000px;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.gallery-item {
  /* height: 150px; */
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 700px;
  z-index: 0;
}
.gallery-item-1,
.gallery-item-3{
  opacity: .5;
  width: 700px;
  z-index: 1;
  transform: translateX(-50%) scale(0.8);


}
.gallery-item-1{
  left: 0%;
}
.gallery-item-2{
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4), 0 0 12px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.20), 0 0 5px rgba(0, 0, 0, 0.1);
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  z-index: 2;
}
.gallery-item-3{
  width: 700px;
  z-index: 1;
  left: 100%;
}
/* .gallery-item-1 {
  left: 15%;
  opacity: .4;
  transform: translateX(-50%);
}

.gallery-item-2,
.gallery-item-4 {
  height: 200px;
  opacity: 1;
  width: 700px;
  z-index: 1;
}

.gallery-item-2 {
  left: 30%;
  transform: translateX(-50%);
}

.gallery-item-3 {
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  height: 300px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  width: 700px;
  z-index: 2;
}

.gallery-item-4 {
  left: 70%;
  transform: translateX(-50%);
}

.gallery-item-5 {
  left: 85%;
  opacity: .2;
  transform: translateX(-50%);
} */

.gallery-controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  width: 92%;
}

.gallery-controls button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  margin: 0 20px;
  width: 50px;
  height: 50px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 1200;
  background-color: white;
  border-radius: 70%;
  &:hover{
    opacity: 0.7;
  }
}

.gallery-controls button:focus {
  outline: none;
}

.gallery-controls-previous {
  position: relative;
  left: 0;
}

/* .gallery-controls-previous::before {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: '';
  display: inline-block;
  height: 4px;
  left: 500px;
  padding: 2px;
  position: absolute;
  top: 0;
  transform: rotate(135deg) translateY(-50%);
  transition: left 0.15s ease-in-out;
  width: 4px;
} */

.gallery-controls-previous:hover::before {
  left: -18px;
}

.gallery-controls-next {
  position: relative;
}

/* .gallery-controls-next::before {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  content: '';
  display: inline-block;
  height: 4px;
  padding: 2px;
  position: absolute;
  right: -10px;
  top: 50%;
  transform: rotate(-45deg) translateY(-50%);
  transition: right 0.15s ease-in-out;
  width: 4px;
} */

.gallery-controls-next:hover::before {
  right: -18px;
}

.gallery-nav {
  bottom: -15px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  position: absolute;
  width: 100%;
}

.gallery-nav li {
  background: #ccc;
  border-radius: 50%;
  height: 10px;
  margin: 0 16px;
  width: 10px;
}

.gallery-nav li.gallery-item-selected {
  background: #555;
}