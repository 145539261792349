@import 'styles/_mixin';
@import 'styles/colors';

.discussion-search-box {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  .search-input {
      cursor: pointer;
      
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 14px;
      align-items: center;
      padding-left: 40px !important; 
  
  }

  input{
      text-align: center;
  }
  img{
    width: 20px;
    height: auto;
    z-index: 1000;
    margin-left: 10px;
    position: absolute;
    margin-top: 15px;
  }
}
.discussion-content-wrap{
    width: 100%;
    .discussion-card{
        position:relative;
        width:100%;
        margin:0 20px;
        padding: 24px 0;
        border-bottom: 1px solid $border-color;
        @include desktop{
            margin:0;
            box-sizing:border-box;
            padding-top:39px;
            padding-bottom:79px;
            width:50%;
            &:nth-child(2n+1){
                width:50%;
                padding-right:20px;
                border-right: 1px solid $border-color
            }
            &:nth-child(2n){
                padding-left:20px;
            }
        }
        p{
            margin:0;
            padding:0;
            color:black;
            font-size:14px;
            // line-height :1.25em;
            word-wrap:break-word;
        }
        a{
            touch-action:manipulation;
            text-decoration:none;
            color:black;
            cursor:pointer;
        }
        .discuss-category{
            height:18px;
            font-size:12px;
            line-height:18px;
            width:100%;
            vertical-align:top;
            display:flex;
            @include desktop{
                position:absolute;
                top:15px;
                left:auto;
                padding-right:20px;
            }
            .a{
                position:relative;
                z-index:1;
                width:100%;
                display:flex;
                justify-content: space-between;
                vertical-align:top;
                text-decoration:none;
                cursor:pointer;
                touch-action:manipulation;
                .i{
                    display: inline-block;
                    min-width: 38px;
                    height: 18px;
                    margin-right: 8px;
                    padding: 0 6px;
                    color: #fff;
                    font-size: 10px;
                    font-style: normal;
                    line-height: 18px;
                    text-align: center;
                    vertical-align: top;
                    letter-spacing: 0;
                    box-sizing: border-box;
                }
            }
        }
        .discuss-tit{
            position:relative;
            min-height:80px;
            .h3{
                margin:0;
                padding:0;
                color:black;
                font-size:14px;
                line-height:1.25em;
                word-wrap:break-word;
                margin-top:2px;
                display:flex;
                justify-content: space-between;
                @include desktop{
                    margin-top:0
                }
                .h3a{
                    text-decoration: none;
                    color:#222;
                    cursor:pointer;
                    display: -webkit-box;
                    overflow: hidden;
                    line-height: 1.25em;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                    box-sizing: border-box;
                    max-height: 40px;
                    font-weight: bold;
                    line-height: 1.25em;
                    word-break: break-word;
                    @include desktop{
                        height: 52px;
                        max-height: 52px;
                        margin-top: 1px;
                        font-size: 18px;
                        line-height: 1.4em;
                    }
                }
            }
            .p{
                margin-top:8px;
                @include desktop{
                    margin-top:4px;
                }
                .pa{
                    color:#222;
                    display: -webkit-box;
                    overflow: hidden;
                    line-height: 1.33em;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    word-wrap: break-word;
                    box-sizing: border-box;
                    max-height: 40px;
                    font-size: 14px;
                    line-height: 1.43em;
                    word-break: break-word;
                    @include desktop{
                        height: 43px;
                        max-height: 43px;
                        margin-top: 4px;
                        font-size: 14px;
                        line-height: 1.5em;
                    }
                }
            }
        }
        .discuss-sub{
            @include desktop{
                position:absolute;
                bottom:24px;
                width:100%;
                padding-right:20px;
                box-sizing:border-box;
            }
            .author{
                margin-top:16px;
                font-size:12px;
                line-height:16px;
                @include desktop{
                    font-size:12px;
                }
            }
            .icon-wrap{
                margin:0;
                padding:0;
                display:flex;
                line-height:1.25em;
                word-wrap:break-word;
                position:relative;
                margin-top:8px;
                font-size:12px;
                justify-content: space-between;
                .svg{
                    color:#94969b;
                    vertical-align:top;
                }
            }
        }
    }
    .discussion-title{
        font-weight: 900;
        font-size: 24px;
        color: black;
        margin-bottom: 10px;
    }
    .discussion-sub-title{
        color: black;
        font-size: 12px !important;
    }
    .discussion-left-score{
        font-weight: 700;
        font-size: 25px;
        color: #45BC1B;

    }
    .discussion-left-score.small{
        font-size: 15px !important;
    }
    .discussion-right-score{
        font-weight: 700;
        font-size: 15px;
        color: rgb(51,51,51);
    }
    .discussion-category{
        font-weight: 700;
        font-size: 17px !important;
        color: rgb(51,51,51);
    }
    .discussion-category.small{
        font-size: 14px !important;
        display:flex;
        justify-content: flex-end;
    }
    .discussion-line{
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .discussion-content-text{
        word-wrap: break-word;
        word-break: break-word;
        line-height: 2.6em;
        font-weight: 400;
        font-size: 15px;
        color: rgb(51,51,51);
        margin-top: 24px;
        margin-bottom: 30px;
    }
    .discussion-bottom-img-box{
        width: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 30px;
    }
    .discussion-bottom-left-img{
        width: 30px;
        margin-right: 10px;
    }
    .discussion-bookmark-img{
        width: 25px;
    }


}

.discussion-search-total-text{
    color: white;
    font-size: 15px;
    margin-top: 30px;
    font-weight: 500;
    text-align: left;
}
