@import 'styles/colors';
@import 'styles/_mixin';
.investee_register_root {
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 0;
    &:focus{
        outline:none
    }
    main {
        display: block
    }
    
    h1 {
        font-size: 2em;
        margin: .67em 0
    }
    
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        clear: both;
        width: 100%;
        margin: 40px 0;
        border: 0;
        border-bottom: solid 1px $border-color;
    @include tablet {
            margin:60px 0
    }
    }
    
    pre {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        text-decoration: underline dotted
    }
    
    b,strong {
        font-weight: bolder
    }
    
    code,kbd,samp {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    small {
        font-size: 80%
    }
    
    sub,sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline
    }
    
    sub {
        bottom: -0.25em
    }
    
    sup {
        top: -0.5em
    }
    
    img {
        border-style: none
    }
    
    button,input,optgroup,select,textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0
    }
    
    button,input {
        overflow: visible
    }
    
    button,select {
        text-transform: none
    }
    
    button,[type=button],[type=reset],[type=submit] {
        -webkit-appearance: button
    }
    
    button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner {
        border-style: none;
        padding: 0
    }
    
    button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring {
        outline: 1px dotted ButtonText
    }
    
    fieldset {
        padding: .35em .75em .625em
    }
    
    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal
    }
    
    progress {
        vertical-align: baseline
    }
    
    textarea {
        overflow: auto
    }
    
    [type=checkbox],[type=radio] {
        box-sizing: border-box;
        padding: 0
    }
    
    [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
        height: auto
    }
    
    [type=search] {
        -webkit-appearance: textfield;
        outline-offset: -2px
    }
    
    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }
    
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit
    }
    
    details {
        display: block
    }
    
    summary {
        display: list-item
    }
    
    template {
        display: none
    }
    
    [hidden] {
        display: none
    }
    
    a {
        color: $accent-color;
        text-decoration: none;
        background-color: rgba(0,0,0,0);
    @media print {
            color: inherit
    }
    @media(hover: hover) {
            transition:opacity .3s;
        &:hover {
            opacity:.7
        }
    }
    }
    input[type=text],input[type=email],input[type=password] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none
    }
    fieldset{
        border: 0px !important;
        border-radius: 0px !important;
    }
    .investee-content-wrap{
        background: $main-color;
        position: relative;
        z-index: 400;
        min-height: calc( 100vh - 45px * 2 );
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include desktop {
                min-height:calc(100vh - 55px)
        }
        .nav{
            white-space: nowrap;
            position: relative;
            width: 100%;
            text-align: center;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                width: 15%;
                height: 100%;
                background: linear-gradient(to right, $transparent-color, $main-color);
                pointer-events: none
            }
            .ul-wrapper{
                overflow: auto;
                ul{
                    font-size: inherit;
                    font-family: inherit;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    line-height: inherit;
                    list-style: none;
                    display: inline-flex;
                    li{
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1.05;
                        text-transform: uppercase;
                        -webkit-hyphens: auto;
                        -ms-hyphens: auto;
                        hyphens: auto;
                        cursor:pointer;
                        margin: 0 12px;
                        padding: 16px 0 14px;
                        color: $font-color-type1;
                        @include tablet {
                            font-size:16px;
                            &:first-child{
                                margin-left: 40px;
                            }
                        }
                        @include xl{
                            &:first-child{
                                margin-left: 90px;
                            }
                        }
                        &:first-child{
                            margin-left:20px
                        }
                        &[aria-current=true] {
                            border-bottom: 2px solid $accent-color;
                            color:$accent-color;
                            a{
                                color:$accent-color
                            }
                        }
                        a{
                            color:inherit
                        }
                    }
                }
            }
        }
        
        
    }
    .form-container{
        margin-right: 20px;
        margin-left: 20px;
        max-width: 1600px;
        margin: 30px 20px;
        @include tablet {
                margin-right:40px;
                margin-left: 40px
        }
        @include xl {
                margin-right:90px;
                margin-left: 90px
        }
        @media(min-width: 1780px) {
                width:100%;
                margin-right: auto;
                margin-left: auto
        }
        
        @include tablet {
                margin:60px auto;
                max-width: 500px;
        }
        .security{
            width: 100%;
            font-size: 12px;
            margin: 40px 0;
            border-bottom: solid 1px $border-color;
            &:last-child{
                border-bottom: 0;
            }
            h2{
                font-size: 11px;
                font-weight: 700;
                line-height: 1.05;
                text-transform: uppercase;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
                text-transform: uppercase;
                margin: 0 0 10px;
                color: $font-color-basic;
                margin-bottom: 0;
            @include tablet {
                    font-size:13px
            }
            }
            p{
                font-size: 11px;
                font-weight: 500;
                line-height: 1.3;
                color: $font-color-type1;
                margin: 5px 0 0;
            @include tablet {
                    font-size:13px
            }
            }
            ul {
                font-size: inherit;
                font-family: inherit;
                margin: 0;
                padding: 0;
                border: 0;
                line-height: inherit;
                list-style: none;
                li{
                    display: flex;
                    justify-content: space-between;
                    padding: 20px 0;
                    font-size: 16px;
                    border-bottom: solid 1px $border-color;
                    &:last-child{
                        border-bottom: 0;
                    }
                    .div{
                        position: relative;
                        padding-left: 34px;
                        .svg {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            --color: #000;
                            left: 0;
                            height: 20px;
                            width: auto
                        }
                    }
                    .btn{
                        font-size: inherit;
                        font-family: inherit;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        line-height: inherit;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-color: rgba(0,0,0,0);
                        background-image: none;
                        text-align: center;
                        color: inherit;
                        cursor: pointer;
                        position: relative;
                    @media(hover: hover) {
                            transition:opacity .3s;
                        &:hover {
                            opacity:.7
                        }
                    }
                    &:disabled{
                        opacity: .5;
                        cursor: default;
                    }
                    @media(hover: hover) {
                            &:disabled{transition:opacity .3s}
                        &:disabled:hover,.lDNd5.pHwZX:hover {
                            opacity:.7
                        }
                    }
                    &:active {
                        outline: none
                    }
                    .span{
                        font-size: 16px;
                        opacity: inherit;
                        white-space: nowrap;
                        line-height: 1;
                        color:$accent-color
                        
                    }
                    .span-btn{
                        font-size: 16px;
                        opacity: inherit;
                        white-space: nowrap;
                        line-height: 1;
                        font-weight: 800;
                        display: block;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius: 8px;
                        padding: 14px;
                        color: #fff;
                        background-color: $accent-color;
                    }
                    }
                }
            }
            
            
        }
        .membership{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.3;
            max-width: 620px;
            margin: auto;
            color: $font-color-type1;
            @include tablet {
                    font-size:20px
            }
            p{
                margin:16px 0;
                @include tablet{
                    margin: 20px 0;
                }    
            }
            .membership-btn{
                font-size: 16px;
                opacity: inherit;
                white-space: nowrap;
                line-height: 1;
                font-weight: 800;
                display: block;
                box-sizing: border-box;
                text-align: center;
                border-radius: 8px;
                padding: 14px;
                color: #fff;
                background-color: $accent-color;
            }
        }
        
        .delete-account{
            font-size: inherit;
            font-family: inherit;
            margin: 0;
            padding: 0;
            border: 0;
            line-height: inherit;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-color: rgba(0,0,0,0);
            background-image: none;
            text-align: center;
            color: inherit;
            cursor: pointer;
            position: relative;
        @media(hover: hover) {
                transition:opacity .3s;
            &:hover {
                opacity:.7
            }
        }
        &:disabled{
            opacity: .5;
            cursor: default;
        }
        @media(hover: hover) {
                &:disabled{transition:opacity .3s}
            &:disabled:hover,.lDNd5.pHwZX:hover {
                opacity:.7
            }
        }
        &:active {
            outline: none
        }
        .span{
            font-size: 16px;
            opacity: inherit;
            white-space: nowrap;
            line-height: 1;
            color:#c25250;
            
        }
        }
        .delete-description{
            font-size: 11px;
            font-weight: 500;
            line-height: 1.3;
            color: $font-color-type1;
        @include tablet {
                font-size:13px
        }
        }
        
        .form{
            margin: auto;
            max-width: 620px;
            width: 100%;
            .label-title{
                font-size: 11px;
                font-weight: 700;
                line-height: 1.05;
                text-transform: uppercase;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;
            @include tablet {
                    font-size:13px
            }
            }
            .label-desc{
                font-size: 11px;
                font-weight: 500;
                line-height: 1.3;
                color: $font-color-type1;
                margin: 5px 0 0;
            @include tablet {
                    font-size:13px
            }
            }
            
            .input {
                font-size: inherit;
                font-family: inherit;
                margin: 0;
                padding: 0;
                border: 0;
                line-height: inherit;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                line-height: 2;
                font-family: inherit;
                font-weight: inherit;
                color: inherit;
                background: rgba(0,0,0,0);
                border: 0;
                box-shadow: none;
                background-color: #ffffff;
                border: solid 1px $border-color-type1;
                color: inherit;
                font-size: 16px;
                transition: border-color .25s;
                width: 100%;
                border-radius: 8px;
                display: inline-block;
                height: 44px;
                text-indent: 12px;
                &:focus{
                    outline:none;
                    border-color:$accent-color
                }
                &::placeholder{
                    color:$font-color-type1
                }
                &:hover{
                    border-color:$accent-color
                }
                &[aria-invalid=true]{
                    border-color:#c25250
                }
            }
            .btn{
                display:block;
                width:100%;
                font-size: inherit;
                font-family: inherit;
                margin: 0;
                padding: 0;
                border: 0;
                line-height: inherit;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                background-color: rgba(0,0,0,0);
                background-image: none;
                text-align: center;
                color: inherit;
                cursor: pointer;
                position: relative;
            @media(hover: hover) {
                    transition:opacity .3s;
                &:hover {
                    opacity:.7
                }
            }
            &:disabled{
                opacity: .5;
                cursor: default;
            }
            @media(hover: hover) {
                    &:disabled{transition:opacity .3s}
                &:disabled:hover,.lDNd5.pHwZX:hover {
                    opacity:.7
                }
            }
            &:active {
                outline: none
            }
            .span{
                font-size: 16px;
                opacity: inherit;
                white-space: nowrap;
                line-height: 1;
                color:$accent-color
                
            }
            .span-btn{
                font-size: 16px;
                opacity: inherit;
                white-space: nowrap;
                line-height: 1;
                font-weight: 800;
                display: block;
                box-sizing: border-box;
                text-align: center;
                border-radius: 8px;
                padding: 14px;
                color: #fff;
                background-color: $accent-color;
            }
            }
            
        }
    }
    .top-row-container{
        background-color: white;
        padding-bottom: 10px;
    }
    .top-row-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 8px;
        margin-top: 10px;
        div{
            width: 48%;
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            background-color: white;
            padding: 0 10px;
        }
        p{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #444444;
            margin-right: 25px;
            display: flex;
            align-items: center;
        }
        .name{
            width: 48%;
            height: 30px;
            overflow: auto;
            background-color: #fff;
            color: #444444;
            padding: 0 10px;
            font-size: 12px;
        }
        span{
            display: flex;
            align-items: center;
        }
        .business-stage{
            flex:1;
            height: 30px;
            overflow: auto;
            background-color: #fff;
            font-size: 12px;
            color: #444444

        }
    }
    .top_description_container{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .top_description_box {
        width: 800px !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }
    .top_description_box p{
        color: #7E7E7E;
        font-size: 16px;
    }
    h2 {
        color: #7E7E7E;
        font-size: 26px;
        font-weight: 800;
        margin-bottom: 40px;
        margin-top: 50px;
    }
    .form_box {
        width: 800px;
        background-color: #F1F1F1;
        .input_form {
            width: 100%;
            margin: 35px 0;
            .label {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                font-size: 16px;
                width: 100%;
                font-weight: 600;
                color: #121212;
                margin-bottom: 10px;
                .required {
                    color: orangered;
                    margin-left: 4px;
                }
                div {
                    display: flex;
                }

                .modify_btn {
                    cursor: pointer;
                    width: 80px;
                    height: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    border: 1px solid #E7E7E7;
                    border-radius: 3px;
                    font-size: 10px;
                    font-weight: 700;
                }
            }
            .description {
                color: #7E7E7E;
                width: 100%;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 7px;
            }
            .text_input {
                width: 100%;
                height: 40px;
                // border: 1px solid #E7E7E7;
                border-radius: 0px !important;
                background-color: white;
                padding-left: 10px;
                font-size: 12px;
                color: #444444;
            }
            
            .check_boxes div {
                display: flex;
                flex-direction: row;
                text-align: left;
                align-items: center;
                margin-top: 7px;

                input {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
                label {
                    font-size: 15px;
                }
            }
            .check_boxes div:first-child {
                margin-top: 13px;   
            }

            .table-scrollbar {
                input {
                    width: 100%;
                    padding: 3px 10px;
                    border: none !important;
                    font-size: 12px;
                }
            }
            .info_tbl{

            }
            .info_tbl_invest tr {
                border-bottom: 0.5px solid #d0d0d7;
                background-color: white;
                height: 35px;
                font-size:12px;
                font-weight: 300px;

            }
            .info_tbl_invest_header{
                background-color: rgba(243, 242, 240, 0.55) !important;
                border-top-left-radius: 1px;
                border-bottom: 1px solid #adadb8 !important;
                position: sticky;
                top: 0;
            }
            .info_tbl_invest_header p {
                font-size: 12px;
                color: #4f4f4f;
            }
            .service_btn {
                width: 100%;
                height: 40px;
                margin-bottom: 10px;
                padding-left: 10px;
                background-color: #B6B6B6;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                color: #121212;
                cursor: pointer;
            }
        }
        .submit_btn {
            cursor: pointer;
            background-color: #3C72FF;
            color:white;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 30px;
            margin-top: 100px;
        }
        .bottom_description {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            color: #B6B6B6;
            margin-top: 3px;
            margin-bottom: 100px;
        }

    }


.register_modal_root {
    background-color: #535353;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    fieldset{
        border: 0px !important;
        border-radius: 0px !important;
    }
    .icon-box{
        width: 7.5%;
        display: flex;
        justify-content: space-between;
    }
    .row-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
    }
    .input-box-wrap{
        width: 100%;
        height: 32px;
        .nv-autocomplate{
            background-color: white !important;
        }
    }
    .input-box{
        background-color: white !important;
        border-radius: 0px;
        height: 40px;
        input{
            border-radius: 0px !important;
        }
        fieldset{
            border-radius: 0px;
        }

    }
    .modal_header {
        width: 100%;
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0px 10px 10px;
        h2{
            font-size: 18px;
        }
        margin-bottom: 10px;
    }
    .description {
        height: 50%;
        padding: 40px 0;
    }

    .description2 {
        height: 70%;
        padding: 40px 0;
    }
    .input_box_stage {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        .text_input {
            width: 70%;
            height: 40px !important;
            border: 1px solid #E7E7E7;
            text-align: left;
            padding-left: 10px;
        }

        p {
            font-weight: 700;
            font-size: 12px;
            
        }
    }
    .input_box_product{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 25px;
        .text_input {
            width: 100%;
            height: 40px;
            border: 1px solid #E7E7E7;
            padding-left: 10px;
        }
        p {
            font-weight: 700;
            font-size: 12px;
            margin: 10px 0;
            
        }
        textarea{
            resize: none;
            height: 80px !important;
            padding: 10px 0;
            color: #444444;
        }
    }
    .btn_box {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        div {
            background-color: #696969;
            width: 45%;
            padding: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .btn_box_center {
        justify-content: center;
    }
}
.register-modal-btn-box{
    display: flex;
    align-items: center;
    
}
.register-modal-btn{
    color: white;
    width: 20px !important;
}
.register-modal-btn.add{
}
.register-modal-btn.remove{
    width: 18px !important;
    color: #0E51FF
}
.market_title {
    width: 100%;
    font-size: 13px;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    // margin-top: 20px;
    // margin-bottom: 8px;
  }
  
  .market_small_box .market_title {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-top: 15px;
    // margin-bottom: 8px;
  }
  
  .market_title p:nth-child(1) {
    font-weight: 600;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
  }
  
  .market_title p:nth-child(2) {
    font-weight: 350;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
  
    color: #4f4f4f;
  }
  
  .market_content {
    width: 100%;
    background: #ffffff;
    border: 0.5px solid #e7e7e7;
    box-sizing: border-box;
    // box-shadow: 0px 1px 0px #e7e7e7;
    border-radius: 5px;
    padding: 8px;
  }
  
  .market_content p {
    font-size: 13px;
    color: #5e5e5e;
  }
  
  .market_big_box {
    width: 100%;
    // margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 10px
  }
  
  .market_big_box::-webkit-scrollbar-button {
    display:none;
  }
  .market_small_box {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .register-investment-datepicker{
    width: 75% !important;
  }
  .input-add-box-wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1
  }
  
  .pre_text_input{
    width: 70%;
    border: 1px solid #E7E7E7;
    text-align: left;
    padding-left: 10px;
    font-size: 12px !important;
}
.register-modal-scrollbar{
    display: block;
}

.register-modal-scrollbar::-webkit-scrollbar {
    width: 5px;
    background-color: #535353;
}
.register-modal-scrollbar::-webkit-scrollbar-thumb {
    background-color: white !important;
    border-radius: 10px;
    background-clip: padding-box;
}
  .register-modal-scrollbar::-webkit-scrollbar-track {
    background-color: #535353!important;
    border-radius: 10px;
}
.register-preview-box{

}
.profile-badge-listbox{
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
}
.profile-badge-popper{
}
.profile-badge-option{
    margin: 3px;
    font-size: 14px;
    border-radius: 0px !important;
    &.MuiAutocomplete-option.Mui-focused{
        background-color: #808080 !important;
        color: white !important;
        opacity: 0.7;
    }
    border:0px !important;
}
.profile-badge-option-focus{
    background-color: inherit !important;
}
}