.investment-find-banner{
    height: 32vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 36px;
    .title{
        font-weight: 600;
        font-size: 35px;
        color: #fff;
        margin-bottom: 15px;
    }
    .find-btn{
        width: 500px;
        height: 40px;
        background-color: white;
        border-radius: 9px;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
        margin: 30px 0 50px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        color: #000000;
        
    }
    .desc{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #fff;
    }
}
.investment-find-investor-wrap{
    width: 100%;
    display: flex;
    align-items: center;
    background: #f9f9f9;
    // padding: 30px 50px;
    justify-content: center;
    .first{
        animation: fadein 3s;
        -moz-animation: fadein 3s; /* Firefox */
        -webkit-animation: fadein 3s; /* Safari and Chrome */
        -o-animation: fadein 3s; /* Opera */
    }
    .second{
        animation: fadein 3s;
        -moz-animation: fadein 3s; /* Firefox */
        -webkit-animation: fadein 3s; /* Safari and Chrome */
        -o-animation: fadein 3s; /* Opera */
    }
    .third{
        animation-delay: 3s;
        animation: fadein 3s;
        -moz-animation: fadein 3s; /* Firefox */
        -webkit-animation: fadein 3s; /* Safari and Chrome */
        -o-animation: fadein 3s; /* Opera */
    }
    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @-moz-keyframes fadein { /* Firefox */
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @-webkit-keyframes fadein { /* Safari and Chrome */
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @-o-keyframes fadein { /* Opera */
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.investor-match-card{
    width: 700px;
    padding: 30px 50px;
    background-color: #FFF9E5;
    .investment-img{
        width: 100%;
        height: 100px;
    }
    .header-box{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        margin-bottom: 30px;
    }
    .header-title{
        font-weight: 600;
        font-size: 25px;
        color: #695D45;
        margin-bottom: 20px;
        margin-top: 50px;
    }
    .header-sub-title-box{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .header-icon{
        width: 17px;
        height: 17px;
        background-color: #C4C4C4;
        padding: 3px;
        border-radius: 7px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
        }
    }
    .header-desc{
        font-weight: 400;
        font-size: 14px;
        color: rgb(51,51,51);
        margin-bottom: 15px;
        border-bottom: 1px solid rgb(51,51,51);
        padding-bottom: 15px;
    }
    .header-sub-title{
        font-weight: 400;
        font-size: 14px;
        color: rgb(51,51,51);
        .nv-label{
            margin-left: 0px;
        }
    }
    .header-left{
        margin-right: 25px;
    }
    .header-right{

    }
    .check-text{
        font-size: 14px;
        color: rgb(51,51,51);
    }
    .check-img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .check-margin-bottom{
        margin-bottom: 12px;
    }
    .major-list-box{
        margin-top: 15px;
    }
    .major-list-title-box{
        display: flex;
        margin-bottom: 7px;
        align-items: center;

    }
    .major-list-title-icon{
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-top: 2px;
    }
    .major-list-title{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #695D45;
    }
    .major-list-item-box{
        display: flex;
        flex-wrap: wrap;
        margin-left: 25px;
    }
    .major-list-item{
        padding: 4px 10px;
        background: #C4C4C4;
        font-weight: 600;
        font-size: 12px;
        color: #444;
        margin: 8px;
    }
    .major-list-item-rest{
        border-radius: 70%;
        font-size: 14px;
        font-weight: 600;
        color: rgb(51,51,51);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
    }
}