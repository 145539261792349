@import 'styles/colors';
@import 'styles/_mixin';
.login-main-wrap{
    box-sizing: border-box;
    margin: 0 auto;
    min-height: 100%;
    padding-bottom: 0;
    &:focus{outline: none}
    main {
        display: block
    }
    
    h1 {
        font-size: 2em;
        margin: .67em 0
    }
    
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
        clear: both;
        width: 100%;
        margin: 40px 0;
        border: 0;
        border-bottom: solid 1px $border-color;
    @include tablet {
            margin:60px 0
    }
    }
    
    pre {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    abbr[title] {
        border-bottom: none;
        text-decoration: underline;
        text-decoration: underline dotted
    }
    
    b,strong {
        font-weight: bolder
    }
    
    code,kbd,samp {
        font-family: monospace,monospace;
        font-size: 1em
    }
    
    small {
        font-size: 80%
    }
    
    sub,sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline
    }
    
    sub {
        bottom: -0.25em
    }
    
    sup {
        top: -0.5em
    }
    
    img {
        border-style: none
    }
    
    button,input,optgroup,select,textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0
    }
    
    button,input {
        overflow: visible
    }
    
    button,select {
        text-transform: none
    }
    
    button,[type=button],[type=reset],[type=submit] {
        -webkit-appearance: button
    }
    
    button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner {
        border-style: none;
        padding: 0
    }
    
    button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring {
        outline: 1px dotted ButtonText
    }
    
    fieldset {
        padding: .35em .75em .625em
    }
    
    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal
    }
    
    progress {
        vertical-align: baseline
    }
    
    textarea {
        overflow: auto
    }
    
    [type=checkbox],[type=radio] {
        box-sizing: border-box;
        padding: 0
    }
    
    [type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
        height: auto
    }
    
    [type=search] {
        -webkit-appearance: textfield;
        outline-offset: -2px
    }
    
    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }
    
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit
    }
    
    details {
        display: block
    }
    
    summary {
        display: list-item
    }
    
    template {
        display: none
    }
    
    [hidden] {
        display: none
    }
    
    a {
        color: $accent-color;
        text-decoration: none;
        background-color: rgba(0,0,0,0);
    @media print {
            color: inherit
    }
    @media(hover: hover) {
            transition:opacity .3s;
        &:hover {
            opacity:.7
        }
    }
    }
    input[type=text],input[type=email],input[type=password] {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none
    }
    .login-main{
        background: $main-color;
        position: relative;
        z-index: 400;
        min-height: calc( 100vh - 45px * 2 );
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include desktop {
                min-height:calc(100vh - 55px)
        }
        .login{
            margin-right: 20px;
            margin-left: 20px;
            max-width: 1600px;
            margin-top: 60px;
            margin-bottom: 60px;
            margin-top: 20px;
            @include tablet {
                    margin-right:40px;
                    margin-left: 40px
            }
            @include xl {
                    margin-right:90px;
                    margin-left: 90px
            }
            @media(min-width: 1780px) {
                    width:100%;
                    margin-right: auto;
                    margin-left: auto
            }
            @include desktop {
                    margin-top:60px
            }
            .contents-container{
                margin:auto auto 120px auto;
                .header{
                    width: 100%;
                    text-align: left;
                    margin: 0 auto 40px;
                    color: $font-color-basic;
                    max-width: 620px;
                    h1{
                        margin: 0 0 12px;
                        max-width: 620px;
                        text-align: left;
                        font-size: 24px;
                        font-weight: 800;
                        line-height: 1.05;
                        @include tablet {
                            font-size:40px
                        }
                    }
                    p{
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.3;
                        color: $font-color-basic;
                        margin: 0 auto;
                        max-width: 620px;
                        line-height: 1.3;
                        @include tablet {
                                font-size:20px
                        }
                    }
                    
                }
                .form{
                    margin:auto;
                    max-width:620px;
                    width:100%;
                    .label{
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 1.05;
                        text-transform: uppercase;
                        -webkit-hyphens: auto;
                        -ms-hyphens: auto;
                        hyphens: auto;
                        text-transform: uppercase;
                        margin: 0 0 10px;
                        color: $font-color-basic;
                        display: block;
                        margin-bottom: 10px;
                        @include tablet {
                            font-size:13px
                        }
                    }
                    .input-wrap{
                        display:flex;
                        width:100%;
                        .input{
                            font-size: inherit;
                            font-family: inherit;
                            margin: 0;
                            padding: 0;
                            border: 0;
                            line-height: inherit;
                            appearance: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            line-height: 2;
                            font-family: inherit;
                            font-weight: inherit;
                            color: inherit;
                            background: rgba(0,0,0,0);
                            border: 0;
                            border-radius: 0;
                            box-shadow: none;
                            position: relative;
                            display: inline-block;
                            height: 45px;
                            width: 100%;
                            border-radius: 8px;
                            border: solid 1px $border-color-type1;
                            box-sizing: border-box;
                            font-size: 16px;
                            font-weight: 300;
                            color: $font-color-basic;
                            text-indent: 15px;
                            background-color: #fff;
                            padding: 0;
                            transition: border-color,.25s;
                            &[type=search] {
                                appearance: none;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                border-radius: 0;
                                box-shadow: none
                                }
                            &:focus {
                                outline: none
                                }
                            &::placeholder {
                                color: $font-color-type1
                                }
                            &:hover,&:focus {
                                border-color: $accent-color
                                }
                            &[aria-invalid=true] {
                                border-color: #c25250
                            }
                        }
                    }
                    .show{
                        position: absolute;
                        right: 15px;
                        bottom: 13px;
                    }
                    .btn{
                        font-size: inherit;
                        font-family: inherit;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        line-height: inherit;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-color: rgba(0,0,0,0);
                        background-image: none;
                        text-align: center;
                        color: inherit;
                        cursor: pointer;
                        position: relative;
                    @media(hover: hover) {
                            transition:opacity .3s;
                        &:hover {
                            opacity:.7
                        }
                    }
                    &:disabled{
                        opacity: .5;
                        cursor: default;
                    }
                    @media(hover: hover) {
                            &:disabled{transition:opacity .3s}
                        &:disabled:hover,.lDNd5.pHwZX:hover {
                            opacity:.7
                        }
                    }
                    &:active {
                        outline: none
                    }
                    .span{
                        font-size: 16px;
                        opacity: inherit;
                        white-space: nowrap;
                        line-height: 1;
                        color:$accent-color
                        
                    }
                    .span-btn{
                        font-size: 16px;
                        opacity: inherit;
                        white-space: nowrap;
                        line-height: 1;
                        font-weight: 800;
                        display: block;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius: 8px;
                        padding: 14px;
                        color: #fff;
                        background-color: $accent-color;
                    }
                    }
                    .btnstyle{
                        display:block;
                        width:100%
                    }
                    .guide-text{
                        font-size: 11px;
                        font-weight: 500;
                        line-height: 1.3;
                        color: $font-color-type1;
                        margin-top: 5px;
                    @include tablet {
                            font-size:13px
                    }
                    &:empty {
                        display: none
                    }
                    }
                    .active{
                        color:#c25250
                    }
                }
                .social-login{
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 1.3;
                    width: 100%;
                    max-width: 620px;
                    margin: auto;
                    padding-bottom: 1em;
                    @include tablet {
                            font-size:13px
                    }
                    .p{
                        font-size: 11px;
                        font-weight: 700;
                        line-height: 1.05;
                        text-transform: uppercase;
                        -webkit-hyphens: auto;
                        -ms-hyphens: auto;
                        hyphens: auto;
                        text-transform: uppercase;
                        margin: 0 0 10px;
                        color: $font-color-basic;
                        display: block;
                        margin: 20px 0 10px;
                        @include tablet {
                                font-size:13px
                        }
                    }
                    .icon-btn{
                        font-size: inherit;
                        font-family: inherit;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        line-height: inherit;
                        appearance: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-color: rgba(0,0,0,0);
                        background-image: none;
                        text-align: center;
                        color: inherit;
                        transition: opacity .3s;
                        cursor: pointer;
                        margin-right: 10px;
                        background-color: $border-color;
                        border-radius: 8px;
                        text-align: center;
                        padding: 15px 0;
                        display: block;
                        width: 100%;
                        line-height: 0;
                        .svg{
                            fill:#000;
                            height:20px;
                            width:20px;
                        }
                    }
                    
                }
                .help-login{
                    font-size: 11px;
                    font-weight: 500;
                    line-height: 1.3;
                    max-width: 620px;
                    margin: auto;
                    @include tablet {
                        font-size:13px
                    }
                    p{
                        margin: 11px 0;
                        @include tablet{
                            margin:13px 0;
                        }
                    }
                }
                
            }
        }
    }
        
}
    
    

.intro_root {
    background-color: #121212;
    width: 100%;
    height: 100vh;
    padding: 5% 30%;

    .top_description_box {
        display: flex;
        flex-direction: column;
        margin-bottom: 120px;
    }
    .top_description_box p{
        color: white;
        font-size: 16px;
    }
    h2 {
        color: white;
        font-size: 26px;
        font-weight: 800;
        margin-bottom: 40px;
        margin-top: 50px;
    }

    .btn_box {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
        
        div {
            cursor: pointer;
            background-color: #fff;
            width: 200px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            color: #121212;
            font-weight: 700;
            font-size: 15px;
            align-items: center;
            margin-right: 20px;

        }
    }
    .col_btn_box {
        display: flex;
        flex-direction: column;
        width: 100%;

        div {
            width: 100%;
            height: 50px;
            background-color: #7E7E7E;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            color: #000000;
        }
        input {
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            padding-left: 10px;
        }
    }
}
