.my-discussion-type-btn{
    height: 40px !important;
    color: #444 !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    background-color: white !important; 
    &.active{
        background-color: #D6C4AB !important;
        color: white !important;
    }
    cursor: pointer;
    &:first-child{
        margin-right: 15px;
    }
}
.my-dicussion-empty-wrap{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
    font-size: 20px;
    img{
        margin-bottom: 15px;
    }
}