// #191A22//짙은 남색
// #242634//옅은남색
// #444550//옅은남색보더색
// #8AC9FA//밝은하늘색
$accent-color: #168dd9;
$main-color: #f9f9f9;
$serve-color: rgba(0,0,0,0.07);
$border-color: rgba(0,0,0,0.15);
$border-color-type1: rgba(0,0,0,0.3);
$font-color-basic: #000000;
$font-color-type1: rgba(0,0,0,0.7);
$icon-background-color: rgba(0,0,0,0.15);
$transparent-color: rgba(249,249,249,0.0001);

$color-background-2: #ffffff;
$color-background-3: rgba(0,0,0,0.15);
$color-background-navigation: #f9f9f9;
$color-background-navigation-faint: #ffffff;
$color-background-modal: rgba(249,249,249,0.98);
$color-highlight: rgba(22,141,217,0.2);
$secondary: #747a8c;
$warning: #ffc600;
$alert:#f44e5c;
$blue_btn: #5684ff;

$nSight-main_logo-color: #4084FC;
$nSight-shallow_gray-color:#EDF0F5;