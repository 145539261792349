.combination-user-list-table{
    .tbody > .tr{
        &:hover{
            background-color: unset !important;
        }
    }
    .remove-td{
        button{
            color: #d66555;
            border: none;
            background-color: unset;
            cursor: pointer;
        }
    }
    .content-tr{
        display: flex;
        width: 100%;
        &:hover{
            background-color: #ececec;
        }
    }
    margin-bottom: 1rem;
}
.user-list-info{
    margin:0 2rem;
    &__header{
        width: 100%;
        height: 70px;
        display: flex;
        border-bottom: 1px solid #dee2e6;
        justify-content: space-between;
    }
    &__title{
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 20px;
        color: #212529;
    }
    &__table{
        display: flex;
        flex-direction: column;
        border: none !important;
        justify-content: center;
        align-items: center;
        margin: 16px 0 !important;
        ul{
            margin-bottom: 0 !important;
            list-style: none;
            width: 900px;
            display:flex;
        }
        li{
            display: flex;
            flex:1;
            .overview-th, .overview-td{
                border: none;
                box-shadow: 1px 0 0 0 #ccc, 0 1px 0 0 #ccc, 1px 1px 0 0 #ccc, 1px 0 0 0 #ccc inset, 0 1px 0 0 #ccc inset;
            }
            .overview-th{
                width: 150px;
            }
            .overview-td{
                width: 300px;
            }
            &.full{
                .overview-td{
                    flex: 1
                }
            }
        }
    }
}
.combination-my-info{
    .user-list-info__table{
        align-items: flex-start;
    }
    .moreinfo-box{
        width: 900px;
    }
}