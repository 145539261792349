pre{
    font-family: unset;
}
// .discussion-search-box {
//   display: flex;
//   flex-direction: row;
//   margin-bottom: 30px;
//   .search-input {
//     cursor: pointer;
//     background-color: #535353;
//     color: white;
//     width: 100%;
//     height: 50px;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     font-weight: 700;
//     font-size: 15px;
//     align-items: center;
//     padding-left: 40px !important;
//   }
//   input {
//     text-align: center;
//   }
//   img {
//     width: 20px;
//     height: auto;
//     z-index: 1000;
//     margin-left: 10px;
//     position: absolute;
//     margin-top: 15px;
//   }
// }
// .{
//     width: 100%;
//     margin-top: 30px;
//     .discussion-card{
//         background-color: #535353;
//         padding: 40px 30px 30px 30px;
//         color: white;
//         border-bottom: 1px solid white;
//     }
//     .discussion-title{
//         font-weight: 900;
//         font-size: 25px;
//         color: #FFFFFF;
//         margin-bottom: 10px;
//     }
//     .discussion-sub-title{
//         color: white;
//         font-size: 12px !important;
//     }
//     .discussion-left-score{
//         font-weight: 700;
//         font-size: 25px;
//         color: #45BC1B;

//     }
//     .discussion-left-score.small{
//         font-size: 15px !important;
//     }
//     .discussion-right-score{
//         font-weight: 700;
//         font-size: 15px;
//         color: white;
//     }
//     .discussion-category{
//         font-weight: 700;
//         font-size: 25px;
//         color: white;
//     }
//     .discussion-category.small{
//         font-size: 15px !important;
//     }
//     .discussion-line{
//         margin-top: 10px;
//         margin-bottom: 30px;
//     }
//     .discussion-content-text{
//         font-weight: 600;
//         font-size: 17px;
//         color: #CECDCD;
//         margin-top: 15px;
//         margin-bottom: 30px;
//     }
//     .discussion-bottom-img-box{
//         width: 100px;
//         display: flex;
//         justify-content: flex-start;
//         align-items: center;
//         margin-right: 30px;
//     }
//     .discussion-bottom-left-img{
//         width: 30px;
//         margin-right: 10px;
//     }
//     .discussion-bookmark-img{
//         width: 25px;
//     }

    .discussion-writer{
        font-weight: 500;
        font-size: 12px;
        color: #da3238;
        margin-left: 5px;
    }

