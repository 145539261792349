.rep-table{
    border-collapse: collapse;
    margin-bottom:1.25rem;
    width:100%;
    border-radius:0;
    margin-left:72px;
    @media screen and (max-width: 63.9375em){
        margin: 0 auto;
    }
    @media screen and (max-width:39.9375em){
        table-layout:fixed;
    }
    &::after{
        content:'';
        display:table;
        clear:both;
    }
    .rep-cap{
        position:absolute;
        width:1px;
        height:1px;
        overflow:hidden;
        clip:rect(0,0,0,0);
        padding-left:0;
        padding-right:0;
        padding: 0.5rem 0.625rem 0.625rem;
        font-weight: 700;
    }
    .rep-thead{
        border-top: 2px solid #838383;
        border-bottom:1px solid #838383;
        background:#fff;
        color:#0a0a0a;
        .tr{
            background:#fff;
            .width-150{
                width:9.375rem;
                @media screen and (max-width:39.9375em){
                    width: auto;
                }
            }
            .width-180{
                width:11.25rem;
                @media screen and (max-width:39.9375em){
                    width:6rem;
                }
            }
            .th{
                padding-top: 20px;
                padding-bottom: 20px;
                font-size: .9375rem;
                color: #222;
                line-height: 1.375;
                letter-spacing: -.025em;
                font-weight:700;
                text-align:center;
            }
        }
    }
    .rep-tbody{
        border-bottom: 1px solid #f1f1f1;
        background-color: #fff;
        @media screen and(max-width:63.9375em){
            border-top: 1px soild #f1f1f1
        }
        .tr{
            &:nth-child(even){
                background-color: #f9f9f9;
            }
            &:nth-of-type(even):hover{
                background-color:#f1f1f1
            }
            &:hover{
                background-color: #faf8f8;
            }
        }
        .td{
            color:#555;
            padding: 0.875rem 0.625rem;
            font-size: .813rem;
            line-height:1.375;
            letter-spacing: -.025em;
            .str{
                font-weight:700;
            }
            .sm{
                font-size:80%;
                line-height: inherit;
            }
            .reply-date{
                margin-bottom: 0.25rem;
                color: rgba(0,0,0,.45);
                line-height: 1em;
                letter-spacing: -.025em;
            }
            .p{
                letter-spacing: -.05em;
                word-break: break-all;
                margin-bottom: 1rem;
                font-size: inherit;
                line-height: 1.6;
                text-rendering: optimizeLegibility;
            }
            .btn-news-href{
                margin-bottom: 0;
                font-size:0;
                @media screen and (max-width:39.9375em){
                    margin-right:-1px;
                }
                &::before{
                    display:table;
                    content:''
                }
                &::after{
                    clear:both;
                    display:table;
                    content:''
                }
                .btn-news{
                    color: #686868;
                    background-color: #fff;
                    transition: color .3s,border-color .3s;
                    padding: 0.5rem 0.8rem;
                    font-size: .688rem;
                    margin:0;
                    outline:0;
                    letter-spacing:-0.075em;
                    border-radius:3px;
                    display:inline-block;
                    vertical-align:middle;
                    -webkit-appearance:none;
                    border:1px solid #ced2db;
                    line-height:1;
                    text-align:center;
                    cursor:pointer;
                    &:hover{
                        color:#4d4d4d;
                        border-color:#9da0a7;
                    }
                }
            }
            
        }
        .txt-center{
            text-align: center;
        }
    }
}