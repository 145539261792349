.overview-tablewrap{
    overflow-x:auto;
    margin-bottom:5px;
    display:flex;
}
.overview-table{
    // height: 24px;
    table-layout: fixed;
    margin-bottom:1px;
    border: 1px solid #dcdcdc;
    color: #222;
    font-size: 12px;
    font-family: "Malgun Gothic";
    caption-side:bottom;
    border-collapse:collapse;
    ul, li{
        list-style: none;
    }

    tbody, td, tfoot, th, thead, tr{
        border-color:inherit;
        border-style:solid;
        border-width:0;
    }
    .tr{
        position: relative;
        display: flex;
    }
    .overview-th{
        border: 1px solid #ccc;
        background: #efefef;
        // height: 33px;
        line-height: 30px;
        text-align: center;
        font-weight:700;
    }
    .overview-td{
        border: 1px solid #ccc;
        background: #fff;
        line-height: 30px;
        // padding: 0 10px 0 10px;
        text-align: left;
        word-break: break-all;
        input{
            text-align: left;
        }
        &.read{
            padding: 0 10px 0 10px;
        }
        &.readOnly{
            padding-left: 10px;
            background-color: rgba(0,0,0,0.05);
        }
        .nv-textinput{
            height: 100%;            
        }
        .company-search{
            font-size:12px;
            font-family: "Roboto","Helvetica","Arial",sans-serif;
            font-weight:400;
            letter-spacing: 0.00938em;
            padding:2.5px 4px 2.5px 10px;
        }
        .nv-select{
            .MuiSelect-select{
                font-size: 12px;
                font-weight: 400;
                padding-right: 15px;
            }
            svg{
                right: 0;
            }
        }
        .nv-newdatepicker{
            height: 100%;

            .ant-picker{
                height: 100%;
                border: none;
                background-color: unset;
            }
        }
        .nv-autocomplate{
            .MuiAutocomplete-endAdornment{
                right: 0px;
            }
        }
        .nv-numberinput div p{
            margin-right: 2px;
        }
        input{
            font-size: 12px;
            font-weight: 400;

        }
        fieldset{
            border: none;
        }
    }
    .overview-hidden{
        width:50px;
        height:24px;
    }
    .overview-input{
        width:100%;
        border:0;
        padding:0 10px;
    }
    .row-remove-box{
        width: 100%;
        height: 33px;
        position: relative;
        // border-bottom: 1px solid #ccc;

    }
    .row-remove-btn{
        cursor: pointer;
        border: none;
        background-color: unset;
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 23px;
        svg{
            color: rgb(214, 101, 85);
            font-size: 1rem;
        }
    }
}
.overview-table-div{
    // height: 24px;
    .quill{
        .ql-toolbar{
            background-color: #f8f8f8;
        }
        .ql-container{
            min-height:200px;
        }
        .ql-editor{
            min-height:200px;
        }
    }
    margin-bottom:1px;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    color: #222;
    font-size: 12px;
    font-family: "Malgun Gothic";
    ul, li{
        list-style: none;
    }

    tbody, td, tfoot, th, thead, tr{
        border-color:inherit;
        border-style:solid;
        border-width:0;
    }
    .tr-div{
        position: relative;
        display: flex;
    }
    .overview-th{
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        background: #efefef;
        line-height: 30px;
        text-align: center;
        display:flex;
        align-items: center;
        justify-content: center;
        font-weight:700;
        &.row{
            display:flex;
            justify-content: center;
            align-items: center;
        }
    }
    .overview-td-container{
        display:flex;
        flex-direction: column;
    }
    .overview-td{
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        background: #fff;
        line-height: 30px;
        // padding: 0 10px 0 10px;
        text-align: left;
        word-break: break-all;
        input{
            text-align: left;
        }
        &.read{
            padding: 0 10px 0 10px;
        }
        &.readOnly{
            padding-left: 10px;
            background-color: rgba(0,0,0,0.05);
        }
        .nv-textinput{
            height: 100%;            
        }
        .btn{
            border-radius: 5px;
            color: #0671E0;
            padding: 0px 93px;
            border: 1px solid #0671E0;
        }    
        
        .nv-select{
            .MuiSelect-select{
                font-size: 12px;
                font-weight: 400;
                padding-right: 15px;
            }
            svg{
                right: 0;
            }
        }
        .nv-newdatepicker{
            height: 100%;

            .ant-picker{
                height: 100%;
                border: none;
                background-color: unset;
            }
        }
        .nv-autocomplate{
            .MuiAutocomplete-endAdornment{
                right: 0px;
            }
        }
        .nv-numberinput div p{
            margin-right: 2px;
        }
        input{
            font-size: 12px;
            font-weight: 400;

        }
        fieldset{
            border: none;
        }
    }
    .overview-hidden{
        width:50px;
        height:24px;
    }
    .overview-input{
        width:100%;
        border:0;
        padding:0 10px;
    }
    .row-remove-box{
        height: 33px;
        position: relative;
        // border-bottom: 1px solid #ccc;

    }
    .row-remove-btn{
        cursor: pointer;
        border: none;
        background-color: unset;
        position: absolute;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 23px;
        svg{
            color: rgb(214, 101, 85);
            font-size: 1rem;
        }
    }
}
.addbox{
    width: 100%;
    margin: 10px 0 10px 0;
}
.moreinfo-box{
    display:flex;
    justify-content: space-between;
    &.myInfo-box{
        justify-content: flex-end;
        width:100%;
        margin-top:11px
    }
    .btn-box{
        display:flex;
        .btn{
            padding:0.2rem 0.8rem;
            font-size:14px;
            cursor:pointer;
            border:none;
            color:#fff;
            margin-left:16px;
            border-radius: 5px;
            display:flex;
            align-items: center;
            height: 25px;
            background-color: rgb(151, 151, 151);
        }
        .remove-btn{
            background-color: rgb(214, 101, 85);
        }
    }
    &.single{
        justify-content: flex-end
    }
}
.combination-register-bottom{
    margin-top: 5rem;
    display: flex;
    justify-content: flex-end;
}
.combination-register-btn{
    padding: 0.2rem 0.8rem;
    color: #fff;
    background-color: #979797;
    cursor: pointer;
    border: none;
}
.collectfunds-describebox{
    display:flex;
    text-align:left;
    p{
        margin-right:20px;
        font-size:12px;
        margin-top:0;
        margin-bottom:1rem;
    }
}
.addinfo{
    position:absolute;
    width:100%;

}
.company-list-filter-top{
    display: flex;
    justify-content: space-between;
    height: 40px;
    align-items: center;
    padding: 0 15px;
    margin-top: 5px;
    .filter-reset-icon{
      cursor: pointer;
      color: #4183fc;
      svg{
        font-size: 20px;
      }
    }
    .filter-fold-icon{
      cursor: pointer;
      color: #4183fc;
      svg{
        font-size: 15px;
      }
    }
    .filter-total-cnt{
      font-weight: 600;
      font-size: 12px;
      color: #4183fc;
      &.collapsed{
        border-bottom: 3px solid #4183fc;
        border-radius: 70%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
.comb-hidden{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:0;
    overflow:hidden;
    transition:height .3s;
}
.comb-open-member{
    height:158px;
}
.comb-open-management{
    height:166px;
}
.comb-open-collect{
    height:222px;
}
.comb-open-dis{
    height:674px;
}
.comb-ghost-box{
    position:relative;
    height:0;
    transition:height .3s;
}
.comb-height-member{
    height:158px
}
.comb-height-collect{
    height:222px
}
.comb-height-management{
    height:166px;
}
.comb-height-dis{
    height:674px;
}
.comb-relative{
    position:relative;
    z-index:10;
}
.comb-transform{
    transform:translateY(-100%);
    transition:transform .3s;
}
.comb-fold{
    transform:translateY(0);
}