$dark_navy: #000019;
$main_logo: #4084FC;
$blue_emerald: #4ACAFD;
$blue_ribbon: #234FE5;
$deep_gray: #6D6E71;
$gray: #D0D0D0;
$shallow_gray: #EDF0F5;
$white_gray: #E8EFFB;
:root {
    --navbar_main-color: #3caaff;
    --dark_navy-color: #000019;
    --main_logo-color: #4084FC;
    --main_logo-color-rgb: 64, 132, 252;
    --main_logo-color01: rgba(64, 132, 252, .1);
    --blue_emerald-color: #4ACAFD;
    --blue_ribbon-color: #234FE5;
    --deep_gray-color: #6D6E71;
    --gray_view-color: #D5D5D5;
    --gray-color: #D0D0D0;
    --shallow_gray-color: #EDF0F5;
    --white_gray-color: #E8EFFB;
    --my_list_bg-color: #faf8f7;
    --my_list_even-color: #fcfbf9;
    --cv_peer-color: #337983;
    --deep_navy-color: #1B2B54;

}