.combination-list-table{
    overflow: auto;
    .th{
        background-color: #f2f3f4;
        color: #474747;
        overflow: hidden;
        padding: 6.25px;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        font-weight: 700;
        position: relative;
    }
    .td{
        border: 0 solid;
        border-color: inherit;
        overflow: hidden;
        padding: 6.25px;
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 10px;
        color: #444;
        position: relative;
    }
    .group-first-th, .group-th{
        &::before{
            background-color: #e7e7e7;
            content: "";
            display: block;
            height: 100%;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 1px;
            left: 0px;
        }
        // &::after{
        //     background-color: #e7e7e7;
        //     content: "";
        //     display: block;
        //     height: 100%;
        //     position: absolute;
        //     top: 50%;
        //     -webkit-transform: translateY(-50%);
        //     transform: translateY(-50%);
        //     width: 1px;
        // }
    }
    
    .group-th{
        right: 0;
    }
}