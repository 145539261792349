@import 'styles/colors';
@import 'styles/_mixin';

.article{
    a,a:link,a:visited,a[rel=menu],body,button,dl,input,ol,select,td,textarea,th,ul {
        color: #222
    }

    a:visited {
        text-decoration: none
    }

    a:active,a:hover,a[rel=menu]:hover {
        text-decoration: underline
    }
    @media print {
        * {
            -webkit-print-color-adjust: exact!important
        }

        a[href] {
            text-decoration: none
        }

        a[href]:after {
            content: none!important
        }

        .print-show {
            display: block!important
        }

        .print-hide {
            display: none!important
        }
    }

    font[style] {
        padding: 0!important;
        margin: 0!important;
        font: 0!important;
        color: inherit!important
    }
    @media print, screen and (min-width: 40em){
        h1{
            font-size:3rem;
        }
        h2{
            font-size:2.5rem;
        }
        h4{
            font-size:1.5625rem;
        }
    }
    width:calc(100% - 492px);
    padding:50px;
    transition:width .25s ease, padding .25s ease;
    @media screen and (max-width:99.9375em){
        width:calc(100% - 72px)
    }
    @media screen and (max-width:89.9375em){
        width:calc(100% - 72px)
    }
    @media screen and (max-width:79.9375em){
        width:calc(100% - 72px)
    }
    @media screen and (max-width: 63.9375em){
        width:auto;
        padding:15px;
    }
    @media screen and (min-width:64em){
        width:calc(100% - 72px);
    }
    &::after{
        content:'';
        display:table;
        clear:both;
    }
    .dashboard{
        position:relative;
        min-height:100vh;
        @media screen and (max-width:63.9375em){
            display:block;
            min-height:initial;
        }
        .pannels{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 50px;
            &>.pannel{
                position:relative;
                width:37.5%;
                padding-left:20px;
            }
            &>:last-child{
                width:25%;
            }
            &>:first-child{
                padding-left: 0;
            }
            @media screen and (max-width:39.9375em){
                display:block;
                &>.pannel {
                    width:auto;
                    padding-left:0;
                    margin-bottom:0.625rem;
                }
                &>:last-child{
                    margin-bottom:0
                }
            }
        
            .pannel{
                .box{
                    display:block;
                    overflow:hidden;
                    position:relative;
                    min-height:105px;
                    padding: 22px 28px;
                    border-radius: 5px;
                    text-decoration:none;
                    background-color: #7a93c3;
                    &~.box{
                        margin-top:1.25rem;
                    }
                    @media screen and (max-width:39.9375em){
                        min-height:inherit;
                        padding: 15px 22px;
                        &~.box{
                            margin-top: 0.625rem;
                        }
                    }
                    .em{
                        overflow:hidden;
                        margin-bottom:0.75rem;
                        font-size:2rem;
                        font-weight:bolder;
                        letter-spacing:-.05em;
                        display:block;
                        position:relative;
                        z-index:5;
                        font-style:normal;
                        line-height:1.125;
                        color:#fff;
                        @media screen and (max-width:39.9375em){
                            font-size:1.375rem
                        }
                    }
                    .span{
                        font-size:.875rem;
                        font-weight:400;
                        letter-spacing:-.1em;
                        display:block;
                        position:relative;
                        z-index:5;
                        font-style:normal;
                        line-height:1.125;
                        color:#fff;
                        @media screen and (max-width:39.9375em){
                            font-size:.75rem
                        }
                    }
                
            }.write{
                    background:url(/assets/images/newsroom-write.png) 100% 100% no-repeat #ffca00;
                    min-height:100%;
                    @media screen and (max-width:39.9375em){
                        background-size:220px auto;
                    }
                    .em{
                        font-size:3rem;
                    }
                    .span{
                        font-size:1.125rem;
                    }
                }
                .apply{
                    background: url(/assets/images/myhome-apply.svg) 95% 110% no-repeat #5684ff;
                    background-size: 125px auto;
                    min-height:100%;
                    @media screen and (max-width:39.9375em){
                        background-size:85px auto;
                    }
                    .em{
                        font-size:3rem;
                    }
                    .span{
                        font-size:1.125rem;
                    }
                    
                }.reject{
                        background-image: url(/assets/images/myhome-reject.svg);
                        background-position: 95% 115%;
                        background-repeat: no-repeat;
                        background-size: 65px auto;
                        @media screen and (max-width:39.9375em){
                            background-size:50px auto;
                        }
                    }
                    .approve{
                        background-image: url(/assets/images/myhome-approve.svg);
                        background-position: 95% 115%;
                        background-repeat: no-repeat;
                        background-size: 65px auto;
                        @media screen and (max-width:39.9375em){
                            background-size:50px auto;
                        }
                    }
            }
        }
        .current-data{
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            margin-top: 50px;
            &>.current-list{
                width:36%;
                padding-left:20px;
            }
            &>:first-child{
                width:64%;
                padding-left:0;
                padding-right:10px;
            }
            @media screen and (max-width:99.9375em){
                    flex-flow: column wrap;
                
                &>.current-list{
                    width:auto;
                    padding:0;
                }
            }
            @media screen and (max-width: 79.9375em){
                    display:block;
                &>.current-list{
                    width:auto;
                    padding-left:0;
                    padding-right:0
                }
                &>.current-list~.current-list{
                    margin-top:50px
                }
            }
            .current-list{
                @media screen and (max-width:63.9375em){
                    .reply>.header>.text{
                        font-size:1.063rem;
                    }
                }
                @media screen and (max-width:39.9375em){
                    .reply>.header>.text{
                        font-size:1rem;
                    }
                }
                .table{
                    margin: 0;
                    table-layout:fixed;
                    border-collapse:collapse;
                    width:100%;
                    border-radius:0;

                    @media screen and (max-width:39.9375em){
                        margin:-0.75rem;
                        font-size:0;
                        display:block;
                        width:auto;
                        tbody{
                            display:block;
                            width:auto;
                            &>:first-child{
                                border-top:0;
                            }
                            tr{
                                padding:1rem 0.75rem;
                                border-top:1px solid(rgba(0,0,0,.05))
                            }
                            th+td{
                                padding-left:0
                            }
                            td{
                                display: inline-block;
                                padding-top: 0;
                                padding-bottom: 0;
                                font-size: .75rem;
                                line-height: 1.375; 
                            }
                        }
                        tr{
                            display:block;
                            width:auto;
                        }
                        td{
                            text-align:left;
                            display:block;
                            width:auto;
                        }
                        
                    }
                    @media screen and (max-width:63.9375em){
                        tbody{
                            border-top:1px solid #f1f1f1;
                        }
                    }
                    .caption{
                        padding : 0.5rem 0.625rem 0.625rem;
                        font-weight: 700;
                        position:absolute !important;
                        width:1px;
                        height:1px;
                        overflow:hidden;
                        clip:rect(0,0,0,0);
                        padding-left:0;
                        padding-right:0;
                    }
                    thead{
                        background: 0 0;
                        color: #0a0a0a;
                        border-width:0;
                        border-bottom: 1px solid #838383;
                        border-collapse: collapse;
                        @media screen and (max-width: 39.9375em){
                            display:none;
                            tr{
                                display:block;
                                width:auto;
                                th{
                                    text-align: left;
                                    display:block;
                                    width:auto;
                                    
                                }
                            }
                        }
                        tr{
                            background: #fff;
                            th{
                                font-size:.9375rem;
                                letter-spacing: -.075em;
                                color: #838383;
                                padding: 1rem 0.625rem;
                                line-height: 1.375;
                                font-weight: 700;
                                text-align: left;
                            }
                            .tc{
                                text-align: center;
                            }
                        }
                    }
                    tbody{
                        border-width:0;
                        a{
                            display: block;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            letter-spacing: -.075em;
                            color: #3e3e3e;
                        }
                        tr{
                            border-width:0;
                            border-bottom:1px solid #f1f1f1;
                            background-color: #fff;
                            &:hover{
                                background-color: #f9f9f9;
                            }
                        }
                        th{
                            letter-spacing:-.075em;
                            text-align: left;
                            color:#3e3e3e;
                            font-size:.813rem;
                            font-weight:400;
                            padding: 0.875rem 0.625rem;
                            font-size:.813rem;
                            @media screen and (max-width:39.9375em){
                                padding:0;
                                margin-bottom:0.375rem;
                                font-size:.875rem;
                                line-height:1.375;
                                text-align:left;
                                display:block;
                                width:auto;
                            }
                           
                        }
                        a{
                            .span{
                                margin-right:0.3125rem;
                                display: inline-block;
                                font-size: 0;
                                text-align: left;
                                    &::after{
                                        content:'';
                                        display:table;
                                        clear:both;
                                    }
                                .span-label{
                                    display: inline-block;
                                    margin-right: 1px;
                                    margin-bottom: 1px;
                                    font-size: .688rem;
                                    padding: 0.25rem 0.375rem;
                                    letter-spacing: -.05rem;
                                    color:#fff;
                                    background:#9b59b6;
                                    border-radius:2rem;
                                    line-height:1;
                                    white-space:nowrap;
                                    cursor:default;
                                }
                            }
                            i{
                                span{
                                    position: absolute;
                                    width: 1px;
                                    height: 1px;
                                    overflow: hidden;
                                    clip: rect(0,0,0,0);
                                }
                            }
                        }
                        td{
                            letter-spacing: -.025em;
                            color:#868686;
                            text-align:center;
                            font-size:.813rem;
                            font-weight:400;
                            // padding-right:0.625rem;
                            padding-right   :0.625rem;
                            .span-td{
                                @media screen and (max-width:39.9375em){
                                    display:none;
                                }
                            }
                            .span{
                                margin:0;
                                .span-label{
                                background-color: #13c0cb;
                            }}
                        }
                    }
                }
                .list{
                    position:relative;
                    margin-bottom:3.125rem;
                    display:block;
                    ul{
                        margin-bottom:1.375rem;
                        background: 0 0;
                        border: 0;
                        margin-left:0;
                        list-style-type:none;
                        list-style-position: outside;
                        line-height: 1.6;
                        &>:first-child>a{
                            padding-left:0
                        }
                        &::before{
                            display:table;
                            content: '';
                        }
                        &::after{
                            clear:both;
                            display:table;
                            content:''
                        }
                        @media screen and (max-width:39.9375em){
                            font-size:0
                        }
                        .li{
                            float: left;
                            cursor:pointer;
                            font-size: inherit;
                            &>a{
                                display:block;
                                line-height:1;
                                padding: 0 1rem;
                                font-size:0;
                                color:#838383;
                            }
                            &>a[aria-selected=true] {
                                background: 0 0;
                                .text{
                                    font-weight: bolder;
                                    color: #212121;
                                }
                                .circlenum{
                                    background-color: #f66874;
                                }
                            }
                            .text{
                                font-size:1.375rem;
                                font-weight:400;
                                line-height:1.125;
                                letter-spacing: -.125em;
                                display:inline-block;
                                vertical-align:middle;
                            }
                            .circlenum{
                                min-width:21px;
                                padding: 0;
                                margin-left: 0.5rem;
                                font-size: .688rem;
                                font-style: normal;
                                line-height: 21px;
                                color: #fff;
                                background: #bababa;
                                border-radius: 2rem;
                                display:inline-block;
                                vertical-align: middle;
                                text-align:center;
                            }
                            @media screen and (max-width:63.9375em){
                                .text{
                                    font-size:1.125rem;
                                }
                            }
                            @media screen and (max-width:39.9375em){
                                float:none;
                                text-align:center;
                                &:first-child:nth-last-child(4){
                                display:inline-block;
                                width:25%;
                                }
                                &:first-child:nth-last-child(4):first-child:nth-last-child(4)~.li{
                                    display:inline-block;
                                    width:25%;
                                }
                                &>a{
                                    padding: 0 0.625rem
                                }
                                .text{
                                    display: block;
                                    overflow: hidden;
                                    margin-bottom: 0.5rem;
                                    font-size: 1rem;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                                .circlenum{
                                    min-width: 18px;
                                    font-size: .625rem;
                                    line-height: 18px;
                                }
                            }
                            a{
                                }
                            }
                        }
                        .myarticle-list-wrap{
                            background: #fff;
                            border: 0;
                            border-radius:5px;
                            color:#0a0a0a;
                            transition: all .5s ease;
                            .myarticle-list{
                                height:778.234px;
                                display:none;
                                padding:1rem;
                                &[aria-hidden=false]{
                                    display:block;
                                }
                                @media screen and (max-width: 39.9375em){
                                    height:1123.78px;
                                    padding: 0.75rem;
                                }
                                

                            }
                        }
                    }
                    .reply{
                        display:block;
                        text-align:left;
                        margin-bottom:2.5rem;
                        .section{
                            background-color: #fff;
                            border-radius: 5px;
                            overflow: hidden;
                            padding:25px;
                            display:block;
                        @media screen and (max-width:39.9375em){
                                padding:12px;
                        }
                        }
                        .section{
                            .news-reply{
                                display:block;
                                font-size:0;
                                text-align:left;
                                &::after{
                                    content:'';
                                    display:table;
                                    clear:both;
                                }
                                .a{
                                    &:first-child{
                                        margin-top:0;
                                        padding-top: 0;
                                        border-top: 0;
                                    }
                                    &:hover{
                                            .reply-span{color:#212121;
                                            text-decoration:underline;}
                                    }
                                }
                                &>.a{
                                    display: block;
                                    padding-top: 0.875rem;
                                    margin-top: 0.875rem;
                                    border-top: 1px solid rgba(0,0,0,.05);
                                    text-decoration: none;
                                }
                                .reply-span{
                                    margin-bottom: 0.375rem;
                                    font-size: .813rem;
                                    line-height: 1.375;
                                    letter-spacing: -.075em;
                                    color: #838383;
                                    transition: color .3s ease-in-out;
                                    display:block;
                                    overflow:hidden;
                                    text-overflow:ellipsis;
                                    white-space:nowrap;
                                    .em{
                                        font-size: .75rem;
                                        font-weight: 300;
                                        font-style: normal;
                                        line-height: 1.125;
                                        letter-spacing: -.025em;
                                        color: #b4b4b4;
                                        display: block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        .date{
                                            @media screen and (max-width:39.9375em){
                                                display:none
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .header{
                            text-decoration:none;
                            overflow:hidden;
                            margin-bottom:1.375rem;
                            display:block;
                            &::after{
                                content:'';
                                display:table;
                                clear:both;
                            }
                            .i{
                                float:right;
                                font-size:.875rem;
                                line-height:20px;
                            }
                            .em{
                                width: auto;
                                min-width: 18px;
                                margin-left: 0.375rem;
                                padding: 0;
                                font-size: .625rem;
                                font-weight: 400;
                                font-style: normal;
                                line-height: 18px;
                                letter-spacing: -.0125em;
                                vertical-align: middle;
                                color:#fff;
                                background:#ffc600;
                                display:inline-block;
                                text-align:center;
                                border-radius: 2rem;
                            }
                            .text{
                                display:inline-block;
                                vertical-align: middle;
                                font-weight:bolder;
                                line-height:1.125;
                                letter-spacing: -.125em;
                                font-size: 1.125rem;
                            }
                        }
                          
                        }
                }
        }
    }
}
