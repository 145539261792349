@import 'styles/colors';
@import 'styles/_mixin';

.mynews-list{
    a,a:link,a:visited,a[rel=menu],body,button,dl,input,ol,select,td,textarea,th,ul {
        color: #222
    }

    a:visited {
        text-decoration: none
    }

    a:active,a:hover,a[rel=menu]:hover {
        text-decoration: underline
    }
    @media print {
        * {
            -webkit-print-color-adjust: exact!important
        }

        a[href] {
            text-decoration: none
        }

        a[href]:after {
            content: none!important
        }

        .print-show {
            display: block!important
        }

        .print-hide {
            display: none!important
        }
    }

    font[style] {
        padding: 0!important;
        margin: 0!important;
        font: 0!important;
        color: inherit!important
    }
    @media print, screen and (min-width: 40em){
        h1{
            font-size:3rem;
        }
        h2{
            font-size:2.5rem;
        }
        h4{
            font-size:1.5625rem;
        }
    }
    width: calc(100% - 492px);
    padding:50px;
    margin-left:72px;
    transition: width .25s ease, padding .25s ease;
    @media screen and (max-width:99.9375em){
        width:calc(100% - 472px)
    }
    @media screen and (max-width:89.9375em){
        width:calc(100% - 72px);
        margin: 0 auto;
    }
    @media screen and (max-width:79.9375em){
        width:calc(100% - 72px)
    }
    @media screen and (max-width:63.9375em){
        width:auto;
        padding: 15px;
    }
    &::after{
        content:'';
        display:table;
        clear:both;
    }
 
}