@import 'styles/_mixin';
@import 'styles/colors';
.news-write-imgBox{
    border-radius: 5px;
    display: flex;
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    .imgBox-upload-box{
        color: #999;
        svg{
            color: #999;
            margin-bottom: 15px;
            font-size: 30px;
        }
        .or-text{
            margin: 15px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
                padding: 0 5px;
                font-size: 12px;
            }
            &::before, &::after{
                background: #ccc;
                content: "";
                height: 1px;
                width: 16px;
                display: block;
            }
        }
        .upload-btn{
            background-color: #13c0cb !important;
            color: #fff;
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 3px;
            display: inline-flex;
            cursor: pointer;
            &:hover{
                opacity: 0.7;
            }
        }
    }
    .upload-other-box{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        svg{
            color: #999;
        }
        &:hover{
            opacity: 0.7;
        }
    }
}
.news-write-img-desc{
    font-size: 12px;
    font-weight: 600;
    color: #444 !important;
    fieldset{
        border: none !important
    }
}
.news-write-main{
    width: calc(100% - 420px);
    @media screen and (max-width: 99.9375em){
        width: calc(100% - 400px);
    }
    @media screen and (max-width: 89.9375em){
        width: calc(100%);
    }
    @media screen and (max-width:63.9375em){
        width:auto;
        padding: 15px;
    }
    &.admin{
        width: calc(100% - 420px - 72px);
        height: 100%;
        @media screen and (max-width: 99.9375em){
            width: calc(100% - 400px - 72px);
        }
        @media screen and (max-width: 89.9375em){
            width: calc(100%);
        }
        @media screen and (max-width:63.9375em){
            width:auto;
            padding: 15px;
        }
  
    }
    padding: 50px;
    transition: width .25s ease, padding .25s ease;
    .drag-input-box, .drag-line{
        margin-bottom: 10px;
        border: 2px solid transparent !important;
        &.visible{
            border:2px dashed rgba(0,0,0,.12) !important;  
            .news-write-item-btn-box{
                visibility: visible;
            }
        }
        &:focus-within, &:hover{
            border:2px dashed rgba(0,0,0,.12) !important;  
            .news-write-item-btn-box{
                visibility: visible;
            }
        }
    }
    .drag-input-box{
        position:relative;
        display:flex;
        padding-bottom:4px;
        justify-content: flex-end;
        flex-direction: column;
        padding: 31px 5px;
        ::placeholder{
            color: #ADADAD !important;
        }
    }
    .drag-line{
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        padding: 25px 0;
        .line{
            width:100%;
            overflow:auto;
            height:60px;
            display:flex;
            justify-content: flex-end;
            align-items: center;
            resize: none;
        }
    }
    .nv-textarea{
        outline: none !important;
    }
    .nv-textarea, .imgBox{
        border: none  !important;
    }

    &::after{
        content:'';
        display:table;
        clear: both;
    }
    .news-writer{
        display:block;
        margin: 0 auto;
        position:relative;
        width: fit-content;
        height: 100%;
        @media screen and (max-width: 99.9375em){
            width: auto;
            max-width: 995px;
        }
        @media screen and (max-width: 63.9375em){
            max-width: none;
        }
        .write-form-wrap{
            float: left;
            width: 725px;
            @media screen and (max-width: 99.9375em){
                float: none;
                width: auto;
                margin: 0 auto;
            }
            @media screen and (max-width: 79.9375em){
                width: auto;
                max-width: 725px;
            }
            @media screen and (max-width: 63.9375em){
                max-width: none;
            }
            .write-form{
                margin-bottom: 1rem;
                background-color: #fff;
                border: 1px solid rgba(0,0,0,.12);
                border-bottom: 1px solid rgba(0,0,0,.25);
                border-radius: 5px;
                .topline{
                    position: relative;
                    height: 70px;
                    padding: 0;
                    color: #222;
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        height: 0;
                        left: 0;
                        right: 0;
                        border-bottom: 1px solid rgba(0,0,0,.07);
                        clear:both;
                    }
                    .title{
                        overflow: hidden;
                        font-weight: bolder;
                        padding: 0 20px;
                        font-size: 1.25rem;
                        line-height: 70px;
                        letter-spacing: -.1em;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        &::after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                        .strong{
                            float:left;
                            font-weight:700;
                        }
                        .small{
                            color:#848484;
                            font-size: .75rem;
                            font-weight: 400;
                            font-style: normal;
                            line-height: inherit;
                            letter-spacing: -.05em;
                            float:right;
                            @media screen and (max-width: 39.9375em){
                                display:none
                            }
                            &::before{
                                content: '*';
                                display: inline-block;
                                margin-right: 5px;
                                font-style: normal;
                                color: #e32f66;
                                vertical-align: middle;
                            }
                        }
                    }
                }
                .write-body{
                    padding: 1.25rem;
                    display:block;
                    #main-paragraph{
                        padding: 1.25rem 19px 0px 11px;
                    }
                    .write-type-wrap{
                        margin:0.625rem 0;
                        font-size:0;
                        display:flex;
                        @media screen and (max-width: 63.9375em){
                            margin: 1.25rem 0;
                            flex-direction: column;
                        }
                        .write-label{
                            display:flex;
                            align-items: center;
                            padding: 0 0.75rem;
                            vertical-align:middle;
                            width: 130px;
                            font-size: .9375rem;
                            font-weight:border;
                            line-height: 1em;
                            letter-spacing: -.075em;
                            @media screen and (max-width: 63.9375em) {
                                display: flex;
                                width: auto;
                                padding-bottom: 0.625rem;
                                vertical-align:inherit;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                            .svg{
                                color: rgba(0,0,0,.35);
                                transition: color .35s ease-in-out;
                                cursor:help;
                                width:15px;
                                height:15px;
                                display:flex;
                                margin-bottom:1px;
                            }
                        }
                        .write-type{
                            display: inline-block;
                            padding: 0 0.75rem;
                            vertical-align: middle;
                            width: calc(100% - 130px);
                            @media screen and (max-width: 63.9375em){
                                display: block;
                                width: auto;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                            .three-type{
                                margin-bottom:0;
                                display: block;
                                position: relative;
                                z-index: 1;
                                font-size: 0;
                                text-align: left;
                                .type-btn{
                                    display: inline-block;
                                    position: relative;
                                    padding: 0 1.125rem;
                                    margin: 0 0 1px 5px;
                                    font-size: .875rem;
                                    font-style: normal;
                                    line-height: 24px;
                                    letter-spacing: -.05em;
                                    vertical-align: top;
                                    transition: color .3s ease-in-out,background-color .3s ease-in-out;
                                    text-align: left;
                                    cursor:pointer;
                                    &:first-child{
                                        margin-left:0;
                                    }
                                    &::before{
                                        content: '';
                                        display: block;
                                        overflow: hidden;
                                        position: absolute;
                                        z-index: -1;
                                        left: 0;
                                        top: 50%;
                                        width: 11px;
                                        height: 11px;
                                        background-color: #fff;
                                        border: 2px solid #a9b2bb;
                                        border-radius: 2rem;
                                        vertical-align: middle;
                                        -ms-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        transition: all .3s ease-in-out;
                                    }
                                }
                                .type-btn-checked{
                                    display: inline-block;
                                    position: relative;
                                    padding: 0 1.125rem;
                                    margin: 0 0 1px 5px;
                                    font-size: .875rem;
                                    color:#fff;
                                    font-style: normal;
                                    line-height: 24px;
                                    letter-spacing: -.05em;
                                    vertical-align: top;
                                    transition: color .3s ease-in-out,background-color .3s ease-in-out;
                                    text-align: left;
                                    cursor:pointer;
                                    &:first-child{
                                        margin-left:0;
                                    }
                                    &::before{
                                        content: '';
                                        display: block;
                                        overflow: hidden;
                                        position: absolute;
                                        z-index: -1;
                                        left: 0;
                                        top: 50%;
                                        width: 100%;
                                        height: 100%;
                                        background-color:#34495e ;
                                        border: transparent;
                                        border-radius: 2rem;
                                        vertical-align: middle;
                                        -ms-transform: translateY(-50%);
                                        transform: translateY(-50%);
                                        transition: all .3s ease-in-out;
                                    }
                                    &.grade{
                                        &::before{
                                            background-color: #5684ff;
                                        }
                                    }
                                }
                            }
                        }
                        &::after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                    }
                        .ul-line{
                            margin: 1.25rem 0;
                            background-color: #fafbfc;
                            border-top: 1px solid rgba(0,0,0,.08);
                            text-align: left;
                        }
                    .write-section{
                        margin:0.625rem 0;
                        font-size:0;
                        @media screen and (max-width:63.9375em){
                            margin:1.25rem 0;
                        }
                        &::after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                        .section-title{
                            display: inline-block;
                            padding: 0 0.75rem;
                            vertical-align: middle;
                            width: 130px;
                            font-size: .9375rem;
                            font-weight: bolder;
                            line-height: 1em;
                            letter-spacing: -.075em;
                            @media screen and (max-width: 63.9375em){
                                display: block;
                                width: auto;
                                padding-bottom: 0.625rem;
                                vertical-align: inherit;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                        .section-contents{
                            display: inline-block;
                            padding: 0 0.75rem;
                            vertical-align: middle;
                            width: calc(100% - 130px);
                            @media screen and (max-width: 63.9375em){
                                display: block;
                                width: auto;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                            .autocomplete-wrap{
                                max-width:none;
                                margin-right:auto;
                                margin-left:auto;
                                &::before{
                                    display:table;
                                    content:'';
                                }
                                &::after{
                                    clear:both;
                                    display:table;
                                    content:'';
                                }
                                .autocom{
                                    &:nth-of-type(1n+1){
                                        clear:both;
                                    }
                                    &:nth-of-type(1n){
                                        clear:none;
                                    }
                                    padding-left: 0;
                                    padding-right:0;
                                    float:left;
                                    width:100%;
                                    .label{
                                        position: absolute;
                                        width: 1px;
                                        height: 1px;
                                        overflow: hidden;
                                        clip: rect(0,0,0,0);
                                        display: block;
                                        margin: 0;
                                        font-size: .875rem;
                                        font-weight: 400;
                                        line-height: 1.8;
                                        color: #0a0a0a;
                                        
                                    }
                                    .select{
                                        margin: 0 0 1rem;
                                        padding: 0.5rem;
                                        height:39px;
                                        margin-bottom: 0.0625rem;
                                        font-size: .8rem;
                                        border-color: rgba(0,0,0,.12);
                                        border-radius: 5px;
                                        box-shadow: none;
                                        width: calc(100% - 5px);
                                        letter-spacing:-.05em;
                                        text-transform: none;
                                        box-sizing: border-box;
                                        transition: box-shadow .5s,border-color .25s ease-in-out;
                                        line-height: normal;
                                        background-color: #fff;
                                        appearance: none;
                                        border: 1px solid #cacaca;
                                        @media print, screen and (max-width: 39.9375em){
                                            width:100%;
                                        }
                                        &:focus {
                                            outline: none;
                                            border: 1px solid #8a8a8a;
                                            background-color: #fff;
                                            box-shadow: 0 0 5px #cacaca;
                                            transition: box-shadow .5s,border-color .25s ease-in-out
                                        }
                                        option{
                                            font-weight: normal;
                                            display: block;
                                            white-space: nowrap;
                                            min-height: 1.2em;
                                            padding: 0px 2px 1px;
                                        }
                                        .MuiAutocomplete-inputRoot{
                                            font-size: 14px;
                                            input{
                                                padding: 0px !important;
                                            }
                                        }
                                        fieldset{
                                            border: none !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .my-info-container{
                        padding-bottom: 1.25rem;
                        border-bottom: 1px solid rgba(0,0,0,.08);
                        &::after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                        .my-info-title{
                            display: inline-block;
                            padding: 0 0.75rem;
                            vertical-align: middle;
                            width: 130px;
                            font-size: .9375rem;
                            font-weight: bolder;
                            line-height: 1em;
                            letter-spacing: -.075em;
                            @media screen and (max-width: 63.9375em){
                                display: block;
                                width: auto;
                                padding-bottom: 0.625rem;
                                vertical-align: inherit;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                        .my-info-contents{
                            position: relative;
                            display: inline-block;
                            padding: 0 0.75rem;
                            vertical-align: middle;
                            width: calc(100% - 130px);
                            @media screen and (max-width: 63.9375em){
                                display: block;
                                width: auto;
                            }
                            @media screen and (max-width: 39.9375em){
                                padding-left: 0;
                                padding-right: 0;
                            }
                            .my-info-wrap{
                                max-width:none;
                                margin-right:auto;
                                margin-left:auto;
                                &::before{
                                    display:table;
                                    content:'';
                                }
                                &::after{
                                    clear:both;
                                    display:table;
                                    content:'';
                                }
                                .my-info{
                                    padding-right: 0;
                                    padding-left:0;
                                    width:100%;
                                    float:left;
                                    &:last-child{
                                        float:right;
                                    }
                                    .labeling{
                                        position: absolute;
                                        width: 1px;
                                        height: 1px;
                                        overflow: hidden;
                                        clip: rect(0,0,0,0);
                                        display: block;
                                        margin: 0;
                                        font-size: .875rem;
                                        font-weight: 400;
                                        line-height: 1.8;
                                        color: #0a0a0a;
                                    }
                                    .input-box{
                                        overflow: visible;
                                        display: block;
                                        box-sizing: border-box;
                                        margin: 0 0 1rem;
                                        padding: 0.5rem;
                                        border: 1px solid rgba(0,0,0,.12);
                                        background-color: #fff;
                                        font-weight: 400;
                                        color: #0a0a0a;
                                        transition: box-shadow .5s,border-color .25s ease-in-out;
                                        appearance: none;
                                        width: calc(100% - 5px);
                                        @media print, screen and (max-width: 39.9375em){
                                            width: 100%;
                                        }
                                        letter-spacing: -.025em;
                                        margin-bottom: 0.0625rem;
                                        font-size: .8rem;
                                        border-radius: 5px;
                                        box-shadow: none;
                                        height:39px;
                                        &:focus {
                                            outline: none;
                                            border: 1px solid #8a8a8a;
                                            background-color: #fff;
                                            box-shadow: 0 0 5px #cacaca;
                                            transition: box-shadow .5s,border-color .25s ease-in-out
                                        }
                                        ::placeholder{
                                            opacity: 0.4;
                                        }
                                    }
                                    fieldset{
                                        border: none !important
                                    }
                                    input{
                                        padding-left: 0px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.preview{
        width: calc(100%);
        &.admin{
            width: calc(100% - 72px);
            @media screen and (max-width: 39.9375em) {
                width: 100%;
            }
        }
        position:relative;
        transition: width .25s ease, padding .25s ease;
        padding:0;
        background-color:$main-color;
        #txt, #middletitle{
            width:620px;
            margin: 0 auto;
        }
        #img{
                width:620px;
                margin: 0 auto;
            .imgBox{
                margin: 0;
            }
            @include desktop{
                .imgBox{
                margin: 0 ;
    
                }
            }
        }
        .write-form-wrap{
            width: 100%;
        }
        .news-writer{
            display:flex;
            margin: 0 auto;
            max-width: 1150px;
            position:relative;
            margin: 0 20px;
            padding-top:50px;
            padding-bottom:300px;
            width: unset;
            height: unset;
            @media screen and (min-width: 48em){
                margin: 0 40px;
            }
            @media screen and (min-width: 75em){
                margin: 0 90px;
            }
            @media screen and (min-width: 83em){
                margin: 0 auto;
            }
        }
        .news-writer .write-form{
            background-color: #f9f9f9;
            border-radius: 5px;
            margin-bottom: 1rem;
            border: none;
        }
    }
}
.active-bar{
    @media screen and (max-width: 89.9375em){
        width: calc(100% - 300px);
    }

    @media screen and (max-width:63.9375em){
        width:auto;
        padding: 15px;
    }
}

.news-write-subtitle{
    input{
        font-size:11px !important;
        color:$accent-color !important;
        font-weight:700 !important;
        &::placeholder{
            color:$accent-color
        }
        @include tablet{
            font-size:13px !important;
        }
    }
    fieldset{
        border: none!important;
    }

}

.news-write-title{
    input{
        font-size:24px !important;
        color:#444 !important;
        font-weight: 600 !important;

        @include tablet{
            font-size:48px !important;
        }
        @include desktop{
            font-size:64px !important;
        }
    }
    fieldset{
        border: none!important;
    }

}

.news-write-middle-title{
    input{
        font-size:20px;
        font-weight:600;
        color:#444 !important;
        @include tablet{
            font-size:32px;
        }
    }
    fieldset{
        border: none !important
    }
}

.news-write-txt{
    .ql-container{
        font-size:16px;
        font-weight:400;
        &::placeholder{
            color:#444;
        }
        @include tablet{
            font-size:20px
        }
    }
}


.news-isdragdrop-box{
    height:60px;
    border: 1px dashed rgba(0,0,0,.25);
    color: rgba(0,0,0,.3);
    align-items:center;
    justify-content:center;
    margin-bottom:4px;
}
// .news-write-main-origin{
//     width: calc(100% - 72px);
//     position:relative;
//     transition: width .25s ease, padding .25s ease;
//     padding:0;
//     background-color:$main-color;
//     &::after{
//         content:'';
//         display:table;
//         clear: both;
//     }
//     #txt, #middletitle{
//         width:620px;
//         margin: 0 auto;
//     }
//     #img{
//             width:620px;
//             margin: 0 auto;
//         .imgBox{
//             margin: 0;
//         }
//         @include desktop{
//             .imgBox{
//             margin: 0 ;

//             }
//         }
//     }
//     .nv-textarea{
//         border:0;
//         padding: 0;
//         background-color: $main-color;
//         border-radius:0;
//     }
//     .nv-textinput{
//         fieldset{
//             border: none !important
//         }

//     }
//     .drag-input-box{
//         position:relative;
//         display:flex;
//         justify-content: flex-end;
//     }
//     .drag-line{
//         position:relative;
//         display:flex;
//         justify-content: center;
//         align-items: center;
//         margin: 10px 0;
//         .line{
//             width:100%;
//             overflow:auto;
//             height:60px;
//             display:flex;
//             justify-content: center;
//             align-items: center;
//             resize: none;
//         }
//     }
//     .news-writer{
//         display:flex;
//         margin: 0 auto;
//         max-width: 1150px;
//         position:relative;
//         margin: 0 20px;
//         padding-top:50px;
//         padding-bottom:300px;
//         @media screen and (min-width: 48em){
//             margin: 0 40px;
//         }
//         @media screen and (min-width: 75em){
//             margin: 0 90px;
//         }
//         @media screen and (min-width: 83em){
//             margin: 0 auto;
//         }
//         .write-sidebar{
//             display:block;
//             position:absolute;
//             background-color: #fff;
//             width:72px;
//             left:-140px;
//             border: 1px solid $border-color;
//             border-radius:5px;
//             transition: all .35s ease, -ms-transform .25s ease;
//             @media screen and (max-width: 99.9375em){
//                 display:none;
//                 float:none;
//                 width:auto;
//                 margin: 0 auto;
//             }
//             .stickybar-wrap{
//                 height: 391.938px;
//                 padding:38px 0;
//                 position: relative;
//                 .stickybar{
//                     margin-top:0;
//                     bottom:auto;
//                     top:0;
//                     max-width:250px;
//                     width:100%;
//                     position: relative;
//                     right:auto;
//                     left:auto;
//                     z-index:0;
//                     transform: translateZ(0);
//                     .bar-in{
//                         background-color:unset;
//                         border:unset;
//                         padding:unset;
//                         .icon-btn{
//                             background-color: $main-color;
//                             color:#444;
//                         }
//                         .str{
//                             text-align:center;
//                             white-space:unset;
//                             text-overflow:unset;
//                             overflow:unset;
//                             padding:10px;
//                             margin:0;
//                         }
//                     }
//                 }
//             }
//         }
//         .write-form-wrap{
//             width:100%;
//             .write-form{
//                 margin-bottom: 1rem;
//                 background-color: #f9f9f9;
//                 border-radius: 5px;
//                 .topline{
//                     position: relative;
//                     height: 70px;
//                     padding: 0;
//                     color: #222;
//                     &::after{
//                         content: '';
//                         display: block;
//                         position: absolute;
//                         height: 0;
//                         left: 0;
//                         right: 0;
//                         border-bottom: 1px solid rgba(0,0,0,.07);
//                         clear:both;
//                     }
//                     .title{
//                         overflow: hidden;
//                         font-weight: bolder;
//                         padding: 0 20px;
//                         font-size: 1.25rem;
//                         line-height: 70px;
//                         letter-spacing: -.1em;
//                         white-space: nowrap;
//                         text-overflow: ellipsis;
//                         &::after{
//                             content:'';
//                             display:table;
//                             clear:both;
//                         }
//                         .strong{
//                             float:left;
//                             font-weight:700;
//                         }
//                         .small{
//                             color:#848484;
//                             font-size: .75rem;
//                             font-weight: 400;
//                             font-style: normal;
//                             line-height: inherit;
//                             letter-spacing: -.05em;
//                             float:right;
//                             @media screen and (max-width: 39.9375em){
//                                 display:none
//                             }
//                             &::before{
//                                 content: '*';
//                                 display: inline-block;
//                                 margin-right: 5px;
//                                 font-style: normal;
//                                 color: #e32f66;
//                                 vertical-align: middle;
//                             }
//                         }
//                     }
//                 }
//                 .write-body{
//                     padding: 1.25rem;
//                     display:block;
//                     #main-paragraph{
//                         padding:1.25rem 0;
//                         margin:0 -1.25rem;
//                         #servetitle{
//                             .nv-textarea{
//                             padding: 4px 0 4px 6px;
//                             }
//                         }
//                         #middletitle{
//                             margin-top:60px;
//                             margin-bottom:16px;
//                         }
//                         #title{
//                             .nv-textarea{
//                                 padding: 0 0 0 3px;
//                                 margin-top:10px;
//                             }
//                         }
//                         #txt{
//                             margin:20px auto;
//                             @include desktop {
//                                 margin: 28px auto;
//                             }
//                         }
//                         .main-body-header{
//                             margin-bottom: 16px;
//                             margin-top: 20px;
//                             @include desktop {
//                                     margin-bottom: 24px
//                             }
//                             .text-box{
//                                 font-size: 14px;
//                                 font-weight: 500;
//                                 line-height: 1.3;
//                                 @include tablet{
//                                         font-size:16px
//                                 }
//                                 .title{
//                                     display:flex;
//                                     align-items: center;
//                                     .by{
//                                         font-size: 14px;
//                                         font-weight: 500;
//                                         line-height: 1.3;
//                                         overflow: hidden;
//                                         @include tablet {
//                                                 font-size:16px
//                                         }
//                                         .span {
//                                             font-size: 14px;
//                                             font-weight: 800;
//                                             line-height: 1.05;
//                                             @include tablet {
//                                                     font-size:16px
//                                             }
//                                         }
                                        
//                                     }
                                    
//                                 }
//                                 .date{
//                                     font-size: 11px;
//                                     font-weight: 500;
//                                     line-height: 1.3;
//                                     color: $font-color-type1;
//                                     margin-top: 4px;
//                                     @include tablet {
//                                             font-size:13px
//                                     }
//                                     @include desktop {
//                                         margin-top:8px
//                                 }
//                                 }
                                
                                
                                
//                             }
                            
//                         }
//                     }
//                     .write-type-wrap{
//                         margin:0.625rem 0;
//                         font-size:0;
//                         display:flex;
//                         @media screen and (max-width: 63.9375em){
//                             margin: 1.25rem 0;
//                             flex-direction: column;
//                         }
//                         .write-label{
//                             display:flex;
//                             align-items: center;
//                             padding: 0 0.75rem;
//                             vertical-align:middle;
//                             width: 130px;
//                             font-size: .9375rem;
//                             font-weight:border;
//                             line-height: 1em;
//                             letter-spacing: -.075em;
//                             @media screen and (max-width: 63.9375em) {
//                                 display: flex;
//                                 width: auto;
//                                 padding-bottom: 0.625rem;
//                                 vertical-align:inherit;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                             .svg{
//                                 color: rgba(0,0,0,.35);
//                                 transition: color .35s ease-in-out;
//                                 cursor:help;
//                                 width:15px;
//                                 height:15px;
//                                 display:flex;
//                                 margin-bottom:1px;
//                             }
//                         }
//                         .write-type{
//                             display: inline-block;
//                             padding: 0 0.75rem;
//                             vertical-align: middle;
//                             width: calc(100% - 130px);
//                             @media screen and (max-width: 63.9375em){
//                                 display: block;
//                                 width: auto;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                             .three-type{
//                                 margin-bottom:0;
//                                 display: block;
//                                 position: relative;
//                                 z-index: 1;
//                                 font-size: 0;
//                                 text-align: left;
//                                 .type-btn{
//                                     display: inline-block;
//                                     position: relative;
//                                     padding: 0 1.125rem;
//                                     margin: 0 0 1px 5px;
//                                     font-size: .875rem;
//                                     font-style: normal;
//                                     line-height: 24px;
//                                     letter-spacing: -.05em;
//                                     vertical-align: top;
//                                     transition: color .3s ease-in-out,background-color .3s ease-in-out;
//                                     text-align: left;
//                                     cursor:pointer;
//                                     &:first-child{
//                                         margin-left:0;
//                                     }
//                                     &::before{
//                                         content: '';
//                                         display: block;
//                                         overflow: hidden;
//                                         position: absolute;
//                                         z-index: -1;
//                                         left: 0;
//                                         top: 50%;
//                                         width: 11px;
//                                         height: 11px;
//                                         background-color: #fff;
//                                         border: 2px solid #a9b2bb;
//                                         border-radius: 2rem;
//                                         vertical-align: middle;
//                                         -ms-transform: translateY(-50%);
//                                         transform: translateY(-50%);
//                                         transition: all .3s ease-in-out;
//                                     }
//                                 }
//                                 .type-btn-checked{
//                                     display: inline-block;
//                                     position: relative;
//                                     padding: 0 1.125rem;
//                                     margin: 0 0 1px 5px;
//                                     font-size: .875rem;
//                                     color:#fff;
//                                     font-style: normal;
//                                     line-height: 24px;
//                                     letter-spacing: -.05em;
//                                     vertical-align: top;
//                                     transition: color .3s ease-in-out,background-color .3s ease-in-out;
//                                     text-align: left;
//                                     cursor:pointer;
//                                     &:first-child{
//                                         margin-left:0;
//                                     }
//                                     &::before{
//                                         content: '';
//                                         display: block;
//                                         overflow: hidden;
//                                         position: absolute;
//                                         z-index: -1;
//                                         left: 0;
//                                         top: 50%;
//                                         width: 100%;
//                                         height: 100%;
//                                         background-color:#34495e ;
//                                         border: transparent;
//                                         border-radius: 2rem;
//                                         vertical-align: middle;
//                                         -ms-transform: translateY(-50%);
//                                         transform: translateY(-50%);
//                                         transition: all .3s ease-in-out;
//                                     }
//                                 }
//                             }
//                         }
//                         &::after{
//                             content:'';
//                             display:table;
//                             clear:both;
//                         }
//                     }
//                         .ul-line{
//                             margin: 1.25rem 0;
//                             background-color: #fafbfc;
//                             border-top: 1px solid rgba(0,0,0,.08);
//                             text-align: left;
//                         }
//                     .write-section{
//                         margin:0.625rem 0;
//                         font-size:0;
//                         @media screen and (max-width:63.9375em){
//                             margin:1.25rem 0;
//                         }
//                         &::after{
//                             content:'';
//                             display:table;
//                             clear:both;
//                         }
//                         .section-title{
//                             display: inline-block;
//                             padding: 0 0.75rem;
//                             vertical-align: middle;
//                             width: 130px;
//                             font-size: .9375rem;
//                             font-weight: bolder;
//                             line-height: 1em;
//                             letter-spacing: -.075em;
//                             @media screen and (max-width: 63.9375em){
//                                 display: block;
//                                 width: auto;
//                                 padding-bottom: 0.625rem;
//                                 vertical-align: inherit;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                         }
//                         .section-contents{
//                             display: inline-block;
//                             padding: 0 0.75rem;
//                             vertical-align: middle;
//                             width: calc(100% - 130px);
//                             @media screen and (max-width: 63.9375em){
//                                 display: block;
//                                 width: auto;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                             .autocomplete-wrap{
//                                 max-width:none;
//                                 margin-right:auto;
//                                 margin-left:auto;
//                                 &::before{
//                                     display:table;
//                                     content:'';
//                                 }
//                                 &::after{
//                                     clear:both;
//                                     display:table;
//                                     content:'';
//                                 }
//                                 .autocom{
//                                     &:nth-of-type(1n+1){
//                                         clear:both;
//                                     }
//                                     &:nth-of-type(1n){
//                                         clear:none;
//                                     }
//                                     padding-left: 0;
//                                     padding-right:0;
//                                     float:left;
//                                     width:100%;
//                                     .label{
//                                         position: absolute;
//                                         width: 1px;
//                                         height: 1px;
//                                         overflow: hidden;
//                                         clip: rect(0,0,0,0);
//                                         display: block;
//                                         margin: 0;
//                                         font-size: .875rem;
//                                         font-weight: 400;
//                                         line-height: 1.8;
//                                         color: #0a0a0a;
                                        
//                                     }
//                                     .select{
//                                         margin: 0 0 1rem;
//                                         padding: 0.5rem;
//                                         height:39px;
//                                         margin-bottom: 0.0625rem;
//                                         font-size: .8rem;
//                                         border-color: rgba(0,0,0,.12);
//                                         border-radius: 5px;
//                                         box-shadow: none;
//                                         width: calc(100% - 5px);
//                                         letter-spacing:-.05em;
//                                         text-transform: none;
//                                         box-sizing: border-box;
//                                         transition: box-shadow .5s,border-color .25s ease-in-out;
//                                         line-height: normal;
//                                         background-color: #fff;
//                                         appearance: none;
//                                         border: 1px solid #cacaca;
//                                         @media print, screen and (max-width: 39.9375em){
//                                             width:100%;
//                                         }
//                                         &:focus {
//                                             outline: none;
//                                             border: 1px solid #8a8a8a;
//                                             background-color: #fff;
//                                             box-shadow: 0 0 5px #cacaca;
//                                             transition: box-shadow .5s,border-color .25s ease-in-out
//                                         }
//                                         option{
//                                             font-weight: normal;
//                                             display: block;
//                                             white-space: nowrap;
//                                             min-height: 1.2em;
//                                             padding: 0px 2px 1px;
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     .my-info-container{
//                         padding-bottom: 1.25rem;
//                         border-bottom: 1px solid rgba(0,0,0,.08);
//                         &::after{
//                             content:'';
//                             display:table;
//                             clear:both;
//                         }
//                         .my-info-title{
//                             display: inline-block;
//                             padding: 0 0.75rem;
//                             vertical-align: middle;
//                             width: 130px;
//                             font-size: .9375rem;
//                             font-weight: bolder;
//                             line-height: 1em;
//                             letter-spacing: -.075em;
//                             @media screen and (max-width: 63.9375em){
//                                 display: block;
//                                 width: auto;
//                                 padding-bottom: 0.625rem;
//                                 vertical-align: inherit;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                         }
//                         .my-info-contents{
//                             position: relative;
//                             display: inline-block;
//                             padding: 0 0.75rem;
//                             vertical-align: middle;
//                             width: calc(100% - 130px);
//                             @media screen and (max-width: 63.9375em){
//                                 display: block;
//                                 width: auto;
//                             }
//                             @media screen and (max-width: 39.9375em){
//                                 padding-left: 0;
//                                 padding-right: 0;
//                             }
//                             .my-info-wrap{
//                                 max-width:none;
//                                 margin-right:auto;
//                                 margin-left:auto;
//                                 &::before{
//                                     display:table;
//                                     content:'';
//                                 }
//                                 &::after{
//                                     clear:both;
//                                     display:table;
//                                     content:'';
//                                 }
//                                 .my-info{
//                                     padding-right: 0;
//                                     padding-left:0;
//                                     width:100%;
//                                     float:left;
//                                     &:last-child{
//                                         float:right;
//                                     }
//                                     .labeling{
//                                         position: absolute;
//                                         width: 1px;
//                                         height: 1px;
//                                         overflow: hidden;
//                                         clip: rect(0,0,0,0);
//                                         display: block;
//                                         margin: 0;
//                                         font-size: .875rem;
//                                         font-weight: 400;
//                                         line-height: 1.8;
//                                         color: #0a0a0a;
//                                     }
//                                     .input-box{
//                                         overflow: visible;
//                                         display: block;
//                                         box-sizing: border-box;
//                                         margin: 0 0 1rem;
//                                         padding: 0.5rem;
//                                         border: 1px solid rgba(0,0,0,.12);
//                                         background-color: #fff;
//                                         font-weight: 400;
//                                         color: #0a0a0a;
//                                         transition: box-shadow .5s,border-color .25s ease-in-out;
//                                         appearance: none;
//                                         width: calc(100% - 5px);
//                                         @media print, screen and (max-width: 39.9375em){
//                                             width: 100%;
//                                         }
//                                         letter-spacing: -.025em;
//                                         margin-bottom: 0.0625rem;
//                                         font-size: .8rem;
//                                         border-radius: 5px;
//                                         box-shadow: none;
//                                         height:39px;
//                                         &:focus {
//                                             outline: none;
//                                             border: 1px solid #8a8a8a;
//                                             background-color: #fff;
//                                             box-shadow: 0 0 5px #cacaca;
//                                             transition: box-shadow .5s,border-color .25s ease-in-out
//                                         }

//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
        
//     }
// }

.news-write-item-btn-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    visibility: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
}

.news-write-item-remove-btn, .news-write-item-move-btn{
    // position: absolute;
    // left: 0;
    width: 25px;
    height: 25px;
    background-color: #337ab7;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
        font-size: 20px;
        width: 16px;
        color: #fff;
        fill: #fff;
    }
    &:hover{
        opacity: 0.7;
    }

}

.news-write-necessary-mark{
    position: absolute;
    top: 0;
    right: 5px;
    color: red;
}
.news-write-remove-box{
    position: absolute;
    right: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.news-write-remove-btn{
    background-color: #337ab7;
    font-size: 12px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 10px;
    border-radius: 3px;
    cursor: pointer;
    &:hover{
        opacity: 0.7;
    }
}
.write-right-aside-bottom-box{
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
.news-write-btn{
    width: 90px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    color: #fff;
    background-color: #13c0cb;
    transition: all .3s;
    font-size: 14px;
    cursor: pointer;
    svg{
        fill: #fff;
        width: 15px;
        margin-right: 7px;
    }
    &.remove-btn{
        color: #999;
        background-color: #fff;
        border: 1px solid #999;
    }
    &:hover{
        opacity: 0.7;
    }
    &:last-child{
        margin-left: 25px;
    }
}
.news-write-drag-box{
    &.drag{
        opacity: 0
    }
    .dragging{
        width: 100%;
        height: 2px;
        border: 2px dashed #34495e;
    }
}